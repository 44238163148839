import { Skeleton, Stack } from '@material-ui/core';

const skeleton = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
];

interface SkeletonProps {
  isVideo?: boolean;
  isMultiSection?: boolean;
}

export const HomeContentPlaceholder = ({
  isVideo,
  isMultiSection,
}: SkeletonProps) => (
  <>
    <Skeleton
      variant="rectangular"
      sx={{
        borderRadius: 1,
        height: 16,
        my: 6,
        ml: { xs: 4, lg: '95px' },
        width: 100,
      }}
    />
    <Stack
      alignItems="center"
      direction="row"
      gap={3}
      sx={{ pl: { xs: 4, lg: '95px' } }}
    >
      {skeleton.map((item) => (
        <Stack key={`skeleton-${item.id}`} gap={3}>
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: 1,
              height: isMultiSection ? 290 : 114,
              width: isVideo ? 224 : 197,
            }}
          />
          {isVideo && (
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: 1,
                height: 16,
                width: 197,
              }}
            />
          )}
        </Stack>
      ))}
    </Stack>
  </>
);

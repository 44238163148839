import { Divider, Skeleton, Stack } from '@material-ui/core';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';

interface BillingHistoryCardPlaceholderProps {
  isLast?: boolean;
}

const BillingHistoryCardPlaceholder = ({
  isLast,
}: BillingHistoryCardPlaceholderProps) => (
  <TimelineItem sx={{ mb: { xs: 6, lg: 3 }, '::before': { display: 'none' } }}>
    <TimelineSeparator
      sx={{
        alignItems: 'center',
        display: 'flex',
        marginRight: { xs: 4, lg: 6 },
        minWidth: { xs: 'auto', lg: 75 },
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{ height: 36, maxWidth: 72, minWidth: 36, width: '100%' }}
      />
      {!isLast && (
        <>
          <TimelineConnector sx={{ backgroundColor: 'grey.400', mt: 1 }} />
          <TimelineDot
            sx={{
              alignSelf: 'center',
              backgroundColor: 'grey.400',
              border: 0,
              height: '4px !important',
              margin: '4px 0',
              width: '4px !important',
            }}
          />
          <TimelineConnector sx={{ backgroundColor: 'grey.400' }} />
        </>
      )}
    </TimelineSeparator>
    <TimelineContent sx={{ height: '100%', p: 0 }}>
      <Divider
        sx={{
          bgcolor: 'grey.500',
          mb: { xs: 4, lg: 3 },
          mt: { xs: -4, lg: -4 },
        }}
      />
      <Stack
        alignItems={{ xs: 'flex-start', lg: 'center' }}
        direction="row"
        justifyContent="space-between"
      >
        <Skeleton variant="rectangular" sx={{ height: 54, width: '100%' }} />
      </Stack>
    </TimelineContent>
  </TimelineItem>
);

export default BillingHistoryCardPlaceholder;

import { createContext } from 'react';
import { Section } from '../../interfaces/section';
import { ContentOrNull, SectionOrNull } from './typings';

type ContextType = {
  sections: Section[];
  selectedContent: ContentOrNull;
  selectedSection: SectionOrNull;
  setSelectedSection: (section: SectionOrNull) => void;
  setSelectedContent: (content: ContentOrNull) => void;
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;

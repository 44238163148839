import { sections } from '../modules/account/components/sectionsList';

type Permission = {
  name: string;
  allowed: boolean;
}

const filterSectionLinksByPermissions = (permissions: Permission[]) => {
  const newSections = sections.filter((section) => {
    const checkPermission = permissions.find(
      (permission) => permission.name === section.name,
    );
    const hasPermission = !!checkPermission!.allowed;
    return hasPermission;
  });
  return newSections;
};

export default filterSectionLinksByPermissions;

import { createContext, useContext, useEffect, useState } from 'react';
import getUserPlans from 'modules/account/pages/Subscriptions/services/getUserPlans';
import useApplyingPlansLocalStorage from 'utils/hooks/use-applying-discount';

export const ApplyingDiscountContext = createContext(null as any);

export const useApplyingDiscount = () => {
  const { applyingDiscount, setApplyingDiscount } = useContext(
    ApplyingDiscountContext,
  );
  return [applyingDiscount, setApplyingDiscount];
};

const ApplyingDiscount = ({ children }: any) => {
  const { clear, get, set } = useApplyingPlansLocalStorage();
  const getApplyingDiscountFromStorage = () => {
    const data = get() || '[]';
    return JSON.parse(data);
  };
  const setApplyingDiscountToStorage = (deletingPlans: any) =>
    set(JSON.stringify(deletingPlans));
  const [applyingDiscount, setApplyingDiscount] = useState(
    getApplyingDiscountFromStorage(),
  );
  const setApplyingDiscountHandler = (newPlans: any) => {
    setApplyingDiscountToStorage(newPlans);
    setApplyingDiscount(newPlans);
  };
  useEffect(() => {
    const interval = setInterval(async () => {
      if (applyingDiscount.length > 0) {
        const { data } = await getUserPlans();
        data.map((plan) => {
          const thereIsPlanApplyingDiscount = applyingDiscount.find(
            (item: any) => item.id === plan.id,
          );
          if (thereIsPlanApplyingDiscount) {
            const netValueIsModified =
              thereIsPlanApplyingDiscount.netValue !== plan.netValue;
            if (netValueIsModified) {
              const newPlansWithProgress = applyingDiscount.filter(
                (item: any) => item.id !== plan.id,
              );
              return setApplyingDiscountHandler(newPlansWithProgress);
            }
          }
          return null;
        });
        return;
      }
      clear();
      clearInterval(interval);
    }, 10000);
    return () => clearInterval(interval);
  }, [applyingDiscount]);
  return (
    <ApplyingDiscountContext.Provider
      value={{
        applyingDiscount,
        setApplyingDiscount: setApplyingDiscountHandler,
      }}
    >
      {children}
    </ApplyingDiscountContext.Provider>
  );
};
export default ApplyingDiscount;

import { Stack, Typography, Box } from '@material-ui/core';
import { ScoreBoardType } from 'modules/auth/pages/Register/typings';
import { convertTimestampToDate } from 'utils/time';

export enum Flag {
  live = 'ao-vivo',
  fineshed = 'finalizado',
  future = 'agendado',
}

interface ScoreBoardProps {
  scoreBoard: ScoreBoardType;
  scoreIsVisible: boolean;
}

const ScoreBoard = ({ scoreBoard, scoreIsVisible }: ScoreBoardProps) => {
  const matchIsInFuture = scoreBoard.STATUS_JOGO === Flag.future;
  const haveNoDate =
    scoreBoard.DATA_HORA_EPOCH === 0 || !scoreBoard.DATA_HORA_EPOCH;
  const flagColor = (): string => {
    if (scoreBoard.STATUS_JOGO === Flag.live) {
      return '#FF545E';
    }
    if (scoreBoard.STATUS_JOGO === Flag.fineshed) {
      return '#2F49D1';
    }
    return '#4E4E4E';
  };
  const eventDateLabel = haveNoDate
    ? null
    : convertTimestampToDate(scoreBoard.DATA_HORA_EPOCH);
  return (
    <Stack
      sx={{
        bgcolor: 'grey.500',
        borderRadius: 2,
        display: 'flex',
        height: 114,
        px: 3,
        py: 4,
        width: 197,
      }}
    >
      <Stack sx={{ padding: 3 }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mb: 3,
          }}
        >
          <Typography
            sx={{
              color: 'grey.100',
              fontFamily: 'tertiary',
              fontSize: 14,
            }}
          >
            PAULISTÃO
          </Typography>
          {matchIsInFuture && haveNoDate ? (
            <Typography
              sx={{
                color: 'grey.100',
                fontFamily: 'primary',
                fontSize: 10,
                padding: 1,
                background: flagColor(),
                borderRadius: 1,
              }}
            >
              Hoje
            </Typography>
          ) : (
            <Typography
              sx={{
                color: 'grey.100',
                fontFamily: 'primary',
                fontSize: 10,
                padding: 1,
                background: flagColor(),
                borderRadius: 1,
              }}
            >
              {matchIsInFuture ? eventDateLabel : scoreBoard.STATUS_JOGO}
            </Typography>
          )}
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <img
              src={scoreBoard.LINK_BRASAO_MANDANTE}
              alt="Logo time da casa"
              width={22}
              height={22}
            />
            <Typography
              sx={{
                fontFamily: 'secondary',
                fontSize: 14,
                color: 'grey.200',
                width: 80,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ml: 2,
              }}
            >
              {scoreBoard.MANDANTE}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: 'grey.500',
              background: 'white',
              py: 1,
              px: 2,
              fontFamily: 'primary',
              fontSize: 14,
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              width: 26,
              height: 26,
            }}
          >
            {scoreIsVisible ? scoreBoard.QTD_GOLS_MANDANTE : '-'}
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <img
              src={scoreBoard.LINK_BRASAO_VISITANTE}
              alt="Logo time visitante"
              width={22}
              height={22}
            />
            <Typography
              sx={{
                fontFamily: 'secondary',
                fontSize: 14,
                color: 'grey.200',
                width: 80,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ml: 2,
              }}
            >
              {scoreBoard.VISITANTE}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: 'grey.500',
              background: '#FFFFFF',
              py: 1,
              px: 2,
              fontFamily: 'primary',
              fontSize: 14,
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              width: 26,
              height: 26,
            }}
          >
            {scoreIsVisible ? scoreBoard.QTD_GOLS_VISITANTE : '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ScoreBoard;

import { AxiosResponse } from 'axios';
import { Plan } from 'interfaces/plan';
import { User } from 'interfaces/User';
import qs from 'qs';
import axiosHelper from '../../helpers/axios';
import {
  CreditCard,
  NewCreditCard,
  NewSubscription,
  PartnersSubscription,
  Response,
} from './typings';

export const createUserProfileAvatar = (
  id: string,
  avatar: File,
): Promise<AxiosResponse<User>> =>
  axiosHelper.post(`/user/profile/${id}/avatar`, avatar);
export const getUserCards = (): Promise<AxiosResponse<number>> =>
  axiosHelper.get<number>('/user/card');
export const addUserPayMethod = (
  card: NewCreditCard,
): Promise<AxiosResponse<CreditCard>> => {
  const { cpf, ...input } = card;
  return axiosHelper.post('/user/card', qs.stringify(input), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};
export const getUserStatus = (): Promise<AxiosResponse<Response>> =>
  axiosHelper.get<Response>('/user/card/check');
export const changeUserMainPayMethod = (
  id: string,
): Promise<AxiosResponse<CreditCard>> => axiosHelper.put(`/user/payment/${id}`);
export const deleteUserPayMethod = (
  email: string,
): Promise<AxiosResponse<string>> => axiosHelper.delete(`/user/card/${email}`);
export const createUserSubscription = (
  data: NewSubscription,
): Promise<AxiosResponse<Response>> =>
  axiosHelper.post('/user/subscriptions/segunda-compra', qs.stringify(data), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
export const createUserSubscriptionByPartners = (
  data: PartnersSubscription,
): Promise<AxiosResponse<Response>> =>
  axiosHelper.post('/user/subscriptions', qs.stringify(data), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
export const deleteUserSubscription = (
  id: string,
): Promise<AxiosResponse<Plan>> =>
  axiosHelper.delete(`/user/subscriptions/${id}`);

export const getDiscountByCoupon = (
  coupon: string,
): Promise<AxiosResponse<number>> =>
  axiosHelper.get<number>(`/coupon/${coupon}`);
export const reEnablePreCancelledSubscription = (
  id: string,
): Promise<AxiosResponse<Response>> =>
  axiosHelper.post(`/user/subscription/reactivate`, qs.stringify({ id }), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

import { Plan } from './plan';

export interface Tag {
  id: number;
  name: string;
}

export enum DiscountType {
  Internal = 'internal',
  Collective = 'collective',
  Retention = 'retention',
  Coupon = 'coupon',
}

export enum ValueType {
  value = 'value',
  percentage = 'percentage',
}

export interface Discount {
  id: string;
  title: string;
  code: string;
  price: number;
  createdAt: Date;
  type: DiscountType;
  valueType: ValueType;
  recurrence: number;
  enabled: boolean;
  usageLimit?: number;
  usageUnlimited?: boolean;
  tag: Tag;
  plans: Plan[];
}

import { Box, Stack, Typography, Button, Chip } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import {
  ArrowBackIos as ArrowBackIcon,
  PlayArrow as PlayIcon,
  Sensors as LiveIcon,
} from '@material-ui/icons';
import { keyframes } from '@material-ui/system';
import { formataEventDate } from '../../../../utils/time';

interface ContentScheduledProps {
  content: {
    color: string;
    description: string;
    featured: boolean;
    id: string;
    eventDate: Date;
    position: number;
    thumbnail: string;
    title: string;
    isLive: boolean;
    type: 'live' | 'vod' | 'external';
  };
  section?: {
    title: string;
  };
}
const liveAnimation = keyframes`
  to {
    opacity: 0;
  }
  `;
const SliderContent = ({ content, section }: ContentScheduledProps) => {
  const navigate = useNavigate();
  const { title, type, id, eventDate, isLive, description } = content;
  const formatedEventDate = eventDate ? new Date(eventDate) : null;
  // o eventDate que vem na API não é UTC, ele já está com o fuso horário do Brasil
  // antes de alterar qualquer coisa aqui, olhar a função formataEventDate
  const dateIsInTheFuture =
    !!formatedEventDate && formatedEventDate > new Date();
  const eventDateLabel = formatedEventDate
    ? formataEventDate(formatedEventDate)
    : '';
  const contentIsLive = isLive;
  const contentIsScheduled = type === 'live' && !isLive && dateIsInTheFuture;
  const getButtonLabel = contentIsScheduled ? 'Em breve' : 'Assistir';
  const getChipLabel = contentIsLive ? 'Ao vivo' : eventDateLabel;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
      }}
    >
      {section && (
        <Stack
          alignItems="center"
          direction="row"
          sx={{
            cursor: 'pointer',
            bgcolor: { xs: 'grey.600', lg: 'transparent' },
            height: { xs: 43, lg: 'auto' },
            left: { xs: 0, lg: '95px' },
            mb: 8,
            position: 'absolute',
            px: { xs: 4, lg: 0 },
            top: { xs: 0, lg: 96 },
            width: '100%',
            zIndex: 2150,
          }}
          onClick={() => navigate('/home')}
        >
          <ArrowBackIcon sx={{ height: 13 }} />
          <Typography
            sx={{
              color: 'grey.300',
              fontFamily: 'secondary',
              fontSize: { xs: 16, lg: 20 },
            }}
          >
            {section?.title}
          </Typography>
        </Stack>
      )}
      <Stack
        alignItems={{ xs: 'center', lg: 'flex-start' }}
        sx={{
          bottom: { xs: 35 },
          height: { xs: 'auto', lg: 'unset' },
          left: { xs: 0, lg: '95px' },
          position: 'absolute',
          top: { lg: 150 },
          width: '100%',
          zIndex: 2150,
        }}
      >
        {(contentIsLive || contentIsScheduled) && (
          <Chip
            icon={
              <LiveIcon
                sx={{
                  animation: `${liveAnimation} 1s ease infinite`,
                  color: '#fff !important',
                  display: contentIsLive ? 'block' : 'none',
                  opacity: 1,
                }}
              />
            }
            label={
              <Typography
                sx={{
                  color: 'grey.200',
                  fontSize: { xs: 14, lg: 16 },
                  fontFamily: 'secondary',
                  fontWeight: 700,
                  ml: 1,
                }}
              >
                {getChipLabel}
              </Typography>
            }
            sx={{
              borderRadius: 1,
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25);',
              bgcolor: contentIsLive ? 'error.main' : 'secondary.main',
              mb: { xs: 4, lg: 2 },
              p: 1,
            }}
          />
        )}
        <Typography
          component="h2"
          sx={{
            color: 'common.white',
            fontFamily: 'primary',
            fontSize: { xs: 18, sm: 25, md: 32, lg: 32 },
            maxWidth: { xs: '85%', lg: '100%' },
            textAlign: { xs: 'center', lg: 'start' },
          }}
        >
          {title}
        </Typography>
        <Typography
          component="h2"
          sx={{
            color: 'common.white',
            fontFamily: 'secondary',
            fontSize: { xs: 14, lg: 20 },
            mb: { xs: 8 },
            mt: { xs: 3, lg: 4 },
            textAlign: { xs: 'center', lg: 'start' },
            width: { xs: 343, lg: 600 },
          }}
        >
          {description}
        </Typography>
        <Button
          color={contentIsLive ? 'error' : 'primary'}
          startIcon={<PlayIcon />}
          onClick={() => navigate(`/content/${id}`)}
          sx={{
            fontSize: 16,
            height: 44,
            px: 4,
            py: 3,
          }}
        >
          {getButtonLabel}
        </Button>
      </Stack>
    </Box>
  );
};
export default SliderContent;

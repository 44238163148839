import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import isLoggedIn from 'services/auth';

const PushWooshProvider = () => {
  const isUserLoggedIn = isLoggedIn();
  const location = useLocation();
  useEffect(() => {
    const el = document.getElementById('pushwooshBellWidget');
    if (el) {
      el.style.display = isUserLoggedIn ? 'block' : 'none';
    }
  }, [isUserLoggedIn, location]);
  return null;
};

export default PushWooshProvider;

import { CircularProgress, Stack, Typography } from '@material-ui/core';

const SearchLoading = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    gap={8}
    sx={{ height: '100vh' }}
  >
    <CircularProgress />
    <Typography sx={{ color: 'grey.200', fontSize: 16 }}>
      Buscando conteúdos...
    </Typography>
  </Stack>
);

export default SearchLoading;

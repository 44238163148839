/* eslint-disable react/require-default-props */
import { Box, Button, Stack, Typography } from '@material-ui/core';
import React from 'react';
import useDynamicImport from 'utils/hooks/use-dynamic-import';
import environment from '../../../environment.config';
import { doLogout } from '../services';

const LogoutAndResetError = (resetErrorBoundary: any) => {
  doLogout();
  resetErrorBoundary();
};

const ErrorPage = ({resetErrorBoundary}: any) => {
  const { loading: isLoadingBackground, element: background } =
    useDynamicImport(`${environment.AppSlug}/jpg/background.jpg`);
  const { loading: lookingForLogo, element: logo } = useDynamicImport(
    `${environment.AppSlug}/png/logo.png`,
  );
  const isLoading = isLoadingBackground || lookingForLogo;
  // TODO: add skeleton
  if (isLoading) return <div>Carregando...</div>;
  return (
    <Stack
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        color: 'common.white',
        height: '100vh',
        justifyContent: 'center',
        px: { xs: 5, lg: 11 },
        textAlign: { xs: 'center', lg: 'unset' },
      }}
    >
      <Box
        title="Paulistão Play"
        sx={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: { xs: 26, lg: 44 },
          mb: { xs: 4, lg: 8 },
          width: { xs: 88, lg: 148 },
        }}
      />
      <Typography
        sx={{
          color: 'grey.200',
          fontSize: { xs: 24, lg: 32 },
          mt: { xs: 3, lg: 6 },
        }}
      >
        Ocorreu um erro inesperado...
      </Typography>
      <Typography
        sx={{
          color: 'grey.300',
          fontSize: { xs: 14, lg: 20 },
          mt: { xs: 4, lg: 5 },
          maxWidth: 796,
        }}
      >
        Não estamos conseguindo acessar o {process.env.REACT_APP_WEBSITE_NAME}{' '}
        no momento, verifique sua conexão ou tente novamente.
      </Typography>
      <Button
        sx={{
          fontSize: { xs: 14, lg: 20 },
          height: '100%',
          maxHeight: { xs: 44, lg: 55 },
          maxWidth: { xs: 300, lg: 400 },
          my: { xs: 5, lg: 8 },
          width: '100%',
        }}
        onClick={() => LogoutAndResetError(resetErrorBoundary)}
      >
        Tentar novamente
      </Button>
    </Stack>
  );
};

export default ErrorPage;

/* eslint-disable react/require-default-props */
import { Button } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface PlaceholderNavigationButtonProps {
  label: string;
  destination: string;
  sx?: SxProps;
}

const PlaceholderNavigationButton = ({ label, destination, sx }: PlaceholderNavigationButtonProps) => {
  const navigate = useNavigate();
  const navigateToDestinationHandler = () => navigate(destination);
  return (
    <Button
      sx={{
        fontSize: { xs: 14, lg: 20 },
        height: '100%',
        maxHeight: { xs: 44, lg: 55 },
        maxWidth: { xs: 300, lg: 400 },
        my: { xs: 5, lg: 8 },
        width: '100%',
        ...sx,
      }}
      onClick={navigateToDestinationHandler}
    >
      {label}
    </Button>
  );
};

export default PlaceholderNavigationButton;
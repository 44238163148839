import { Stack, SvgIcon, Typography } from '@material-ui/core';
import { ReactNode } from 'react';
import useDynamicImport from 'utils/hooks/use-dynamic-import';
import environment from '../../../../../environment.config';

type Props = {
  title: string;
  description: string;
  children?: ReactNode;
  sx?: any;
};

const DeleteSubscription = ({ title, description, children, sx }: Props) => {
  const { loading, element } = useDynamicImport(
    `${environment.AppSlug}/svg/sad.svg`,
  );
  // TODO: add skeleton
  if (loading) return <div>Carregando...</div>;
  return (
    <Stack
      data-testid="cancelSubscriptionModal"
      sx={{
        alignItems: 'center',
        gap: 4,
        justifyContent: 'space-around',
        width: '100%',
        textAlign: 'center',
        ...sx,
      }}
    >
      <SvgIcon
        component={element as any}
        viewBox="0 0 85 84"
        sx={{ height: 70, width: 70 }}
      />
      <Typography sx={{ color: 'grey.500', fontSize: 20, fontWeight: 700 }}>
        {title}
      </Typography>
      <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
        {description}
      </Typography>
      {children}
    </Stack>
  );
};

export default DeleteSubscription;

import { Button, Stack } from '@material-ui/core';
import React from 'react';

interface DynamicButtonProps {
  alignment: string;
  bgcolor: string;
  color: string;
  href: string;
  label: string;
}

const DynamicButton = ({
  alignment,
  bgcolor,
  color,
  href,
  label,
}: DynamicButtonProps) => (
  <Stack
    sx={{ alignItems: alignment, position: 'relative' }}
  >
    <Button
      href={href}
      sx={{
        bgcolor,
        color,
        fontSize: { xs: 16, lg: 20 },
        height: { xs: 36, lg: 54 },
        minWidth: { xs: 215, lg: 400 },
        px: 5,
        transition: 'all .2s ease-in-out',
        '&:hover': {
          bgcolor,
          filter: 'brightness(1.2)',
        },
      }}
    >
      {label}
    </Button>
  </Stack>
);

export default DynamicButton;

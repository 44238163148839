/* eslint-disable */
import { Box } from '@material-ui/core';
import React, { useContext, useState, ReactElement, useMemo } from 'react';
import FilterableTags from '../../../components/FilterableTags';
import {
  Section,
  SortableSubSectionContent,
  SubSection,
} from '../../../interfaces/section';
import { compareTerms } from '../../../utils/string';
import Context from '../Context';

type ComponentProps = Record<string, unknown>;

export interface SectionViewProps {
  section: Section;
  renderItem: (item: SortableSubSectionContent) => ReactElement;
  renderTag: (
    tag: SubSection['title'],
    tagProps: ComponentProps,
  ) => ReactElement;
  selectedSection: boolean;
  ContentWrapper?: React.FunctionComponent;
  ContentWrapperProps?: ComponentProps;
  SelectedTagProps?: ComponentProps;
  TagsWrapper?: React.FunctionComponent;
  TagsWrapperProps?: ComponentProps;
  TitleSx?: ComponentProps;
}

const SectionView = ({
  section,
  selectedSection,
  renderItem,
  renderTag,
  ContentWrapper,
  ContentWrapperProps,
  SelectedTagProps,
  TagsWrapper,
  TagsWrapperProps,
}: SectionViewProps) => {
  const [items, setItems] = useState<SortableSubSectionContent[] | null>(null);
  const { term } = useContext(Context);
  const onSelectTagHandle = (tag: string, index: number) => {
    setItems(section.sortableSectionContents);
  };

  const filteredItems = useMemo(
    () =>
      items
        ? items.filter((content) => compareTerms(content.content.title, term))
        : section.sortableSectionContents,
    [items, selectedSection, term],
  );
  return (
    <Box data-testid={`section-view-${section.id}`}>
      <FilterableTags
        title={section.title}
        tags={[
          '',
          ...section.subSections.map((subSection) => subSection.title),
        ]}
        items={filteredItems}
        renderItem={renderItem}
        renderTag={renderTag}
        SelectedTagProps={SelectedTagProps}
        TagsWrapper={TagsWrapper}
        TagsWrapperProps={TagsWrapperProps}
        ContentWrapper={ContentWrapper}
        ContentWrapperProps={ContentWrapperProps}
        onSelectTag={(tag: string, index: number) =>
          onSelectTagHandle(tag, index)
        }
      />
    </Box>
  );
};

export default SectionView;

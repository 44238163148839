export const formatNextMonthlyPlanBilling = (day: number) => {
  const todayDate = new Date();
  const todayDay = todayDate.getDate();
  const billingDayHasPassed = todayDay >= day;
  const verifyMonthToRecurrence = billingDayHasPassed
    ? todayDate.getMonth() + 1
    : todayDate.getMonth();
  const nextBilling = new Date(
    todayDate.getFullYear(),
    verifyMonthToRecurrence,
    day,
  );
  return nextBilling;
};

export const formatNextSeasonPlanBilling = (date: string) => {
  const receivedDate = new Date(date);
  const nextBilling = new Date(
    receivedDate.setDate(receivedDate.getDate() + 1),
  );
  return nextBilling;
};

import styled from '@emotion/styled';
import { smscreen, laptopSmall } from 'utils/medias';

export const Container = styled.div`
  width: 100%;
  margin: 16px auto;
  max-width: 1900px;
  padding: 0 92px;

  @media ${smscreen} {
    padding: 0 8px;
  }
`;

export const GoBackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  @media ${laptopSmall} {
    display: none;
  }
`;

export const PlayerWrapper = styled.div`
  height: 60vh;

  @media ${laptopSmall} {
    height: 50vh;
  }

  iframe {
    border: 1px solid rgba(255, 255, 255, 0.1);

    @media ${laptopSmall} {
      border: none;
    }
  }
`;

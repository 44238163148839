import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  email: yup
    .string()
    .required('Campo obrigatório')
    .email('Formato de e-mail inválido'),
  phone: yup
    .string()
    .trim()
    .matches(/^\(\d{2}\)\s9\d{4}-\d{4}$/, 'Celular inválido')
    .required('Campo obrigatório'),
  cpf: yup.string().matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido'),
});

export default validationSchema;

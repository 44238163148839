/* eslint-disable react/require-default-props */
import { Box, Stack, Typography } from '@material-ui/core';
import { add } from 'date-fns';
import React from 'react';
import useLoggedIn from 'utils/hooks/use-logged-in';
import subscriptionCancellation from '../../../assets/jpg/subscriptionCancellation.jpg';
import { formatDate } from '../../../utils/date';
import PlaceholderNavigationButton from '../components/PlaceholderNavigationButton';
import { SubscriptionPageProps, SubscriptionPeriod } from '../typings';

const SubscriptionCancellation = ({ subscription }: SubscriptionPageProps) => {
  const { get } = useLoggedIn();
  const isLoggedIn = !!get();
  const destination = isLoggedIn ? '/account/subscriptions' : '/auth/register';
  const getEndDateByPeriod =
    subscription?.period === SubscriptionPeriod.Monthly
      ? add(new Date(subscription!.createdAt), { months: 1 })
      : add(new Date(subscription!.createdAt), { years: 1 });
  const getEndDate =
    subscription?.createdAt && subscription?.period
      ? ` ${formatDate(getEndDateByPeriod, 'dd/MM/yyyy')}`
      : ` final da vigência da sua assinatura`;
  return (
    <Stack sx={{ flexDirection: { xs: 'column', lg: 'row' }, gap: 5 }}>
      <Stack
        sx={{
          flex: 1,
          justifyContent: 'center',
          px: 3,
          textAlign: 'left',
        }}
      >
        <Typography sx={{ fontSize: { xs: 24, lg: 32 }, fontWeight: 600 }}>
          O seu plano chegou ao fim
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, lg: 20 }, mt: { xs: 3, lg: 6 } }}>
          Ficamos triste em ver você ir embora! O seu plano do Paulistão Play
          continuará ativo até o dia{getEndDate}. Após este período, os
          conteúdos estarão indisponíveis.
        </Typography>
        <PlaceholderNavigationButton
          destination={destination}
          label="Voltar para a plataforma"
        />
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <Box
          title={subscription?.title && subscription.title}
          sx={{
            backgroundImage: `url(${subscriptionCancellation})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            height: { xs: 200, lg: 350 },
            width: { xs: '100%', lg: 609 },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default SubscriptionCancellation;

export enum ContentType {
  live = 'live',
  vod = 'vod',
  video = 'video',
  external = 'external',
}

export interface Content {
  id: string;
  authorizedOnDesktop: boolean;
  authorizedOnMobile: boolean;
  spallaContentId: string;
  title: string;
  description: string;
  thumbnail: string;
  type: ContentType;
  viewers: number;
  position: number;
  scheduleDate?: Date;
  scheduleDateEnabled: boolean;
  finishDate?: Date;
  finishDateEnabled: boolean;
  sourceDuration: number;
  createdAt?: Date;
  updatedAt: Date;
  externalContent: boolean;
  externalUrl: string;
  externalEmbed: string;
  liveId: string;
  videoId: string;
  chatEmbed?: string;
  chatEnable?: boolean;
}

export interface ConsumableContent extends Content {
  relatedVideos: ConsumableContent[];
}

import { Container, Stack } from '@material-ui/core';
import Header from 'components/Header';
import React, { useMemo } from 'react';
import { Plan } from '../../interfaces/plan';
import { PageType } from './typings';
import { getPagePlaceholderByPageType } from './utils';

interface PagePlaceholderProps {
  pageType: PageType;
  subscription?: Plan;
}

const PagePlaceholder = ({ pageType, subscription }: PagePlaceholderProps) => {
  const placeholderToRender = useMemo(
    () => (
      <Stack
        sx={{
          alignItems: 'center',
          color: 'common.white',
          gap: 4,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Header isLogged />
        <Stack
          sx={{
            alignItems: 'center',
            flex: 1,
            px: 3,
            py: { xs: 0, lg: 10 },
            textAlign: 'center',
          }}
        >
          <Container maxWidth="lg">
            {getPagePlaceholderByPageType(pageType, subscription)}
          </Container>
        </Stack>
      </Stack>
    ),
    [pageType],
  );
  return placeholderToRender;
};

export default PagePlaceholder;

import { Stack } from '@material-ui/core';

interface RetentionDiscountCardBaseProps {
  header: any; // TODO: fix typings
  footer: any; // TODO: fix typings
  dataTestId?: string;
}

const RetentionDiscountCardBase = ({
  header,
  footer,
  dataTestId,
}: RetentionDiscountCardBaseProps) => (
  <Stack
    data-testid={dataTestId}
    alignItems="center"
    justifyContent="space-evenly"
    sx={{
      borderColor: 'grey.400',
      borderRadius: 2,
      borderWidth: '2px',
      borderStyle: 'solid',
      bgcolor: 'grey.600',
      height: 181,
      minWidth: 280,
      maxWidth: { lg: 327 },
      width: '100%',
    }}
  >
    <Stack
      alignItems="center"
      sx={{
        border: '0px',
        borderBottom: '2px',
        borderColor: 'grey.400',
        borderStyle: 'solid',
        height: 70,
        width: '100%',
      }}
    >
      {header}
    </Stack>
    <Stack alignItems="center" sx={{ width: '100%' }}>
      {footer}
    </Stack>
  </Stack>
);

export default RetentionDiscountCardBase;

import React from 'react';

export const preventFloatingNumbers = (
  e: React.KeyboardEvent,
  characters = ['E', 'e', '-', '+', '.'],
) => {
  const hasNoUnwantedKeys = characters.indexOf(e.key) === -1;
  if (hasNoUnwantedKeys) {
    return;
  }
  e.preventDefault();
};

export default preventFloatingNumbers;
import React from 'react';

const useIsPortrait = () => {
  const [isPortrait, setIsPortrait] = React.useState(
    window?.innerHeight > window?.innerWidth,
  );

  const screenHandler = () => {
    setIsPortrait(window?.innerHeight > window?.innerWidth);
  };

  React.useEffect(() => {
    window?.addEventListener('resize', screenHandler);
  }, []);

  return isPortrait;
};

export default useIsPortrait;

import { Button, Stack, Typography } from '@material-ui/core';
import { Plan } from 'interfaces/plan';
import { useDialog } from 'components/Dialog';
import RenewPlan from 'assets/png/renewPlan.png';

const UpdateFreePlanToPaidPlan = ({ plan }: { plan: Plan }) => {
  const dialog = useDialog();
  return (
    <Stack alignItems="center">
      <Typography
        sx={{
          color: 'grey.500',
          fontSize: 20,
          fontWeight: 700,
          textAlign: 'center',
          mb: 6,
        }}
      >
        Seu plano foi atualizado com sucesso
      </Typography>
      <img src={RenewPlan} alt="Plano renovado" />
      <Typography
        sx={{
          color: 'grey.500',
          fontSize: 20,
          fontWeight: 700,
          textAlign: 'center',
          my: 4,
        }}
      >
        Bem vindo ao time!
      </Typography>
      <Typography sx={{ color: 'grey.400', fontSize: 14, textAlign: 'center' }}>
        Seu plano gratuito foi atualizado para a sua nova assinatura do{' '}
        {plan?.title}.
      </Typography>
      <Button
        sx={{ bgcolor: 'secondary.main', height: 60, mt: 4, width: '100%' }}
        onClick={() => dialog.close()}
      >
        Continuar
      </Button>
    </Stack>
  );
};

export default UpdateFreePlanToPaidPlan;

import LocalStorageService from 'services/local-storage.service';
import { LocalStorageKeys } from 'utils/localStorageKeys';

const localStorageService = new LocalStorageService();

const useDeletingPlansLocalStorage = () => {
  const get = () => localStorageService.get(LocalStorageKeys.DELETING_PLANS);

  const set = (value: string) =>
    localStorageService.set(LocalStorageKeys.DELETING_PLANS, value);

  const clear = () =>
    localStorageService.delete(LocalStorageKeys.DELETING_PLANS);

  return {
    get,
    set,
    clear,
  };
};

export default useDeletingPlansLocalStorage;

import { Button, Typography } from '@material-ui/core';
import { Discount, ValueType } from 'interfaces/Discount';
import RetentionDiscountCardBase from './RetentionDiscountCardBase';

interface RetentionDiscountCardProps {
  rententionDiscount: Discount;
  onclick: any; // TODO: fix typings
}

const RetentionDiscountCard = ({
  rententionDiscount,
  onclick,
}: RetentionDiscountCardProps) => {
  const normalizedLabel =
    rententionDiscount.recurrence > 1
      ? `Válido para os próximos ${rententionDiscount.recurrence} pagamentos`
      : `Válido apenas no próximo pagamento`;
  const discountLabel =
    rententionDiscount.valueType === ValueType.value
      ? `R$ ${rententionDiscount.price.toFixed(2)}`.replace('.', ',')
      : `${rententionDiscount.price}%`;
  return (
    <RetentionDiscountCardBase
      dataTestId={`retetionDiscountCard-${rententionDiscount.id}`}
      header={
        <>
          <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
            Ganhe {discountLabel} de desconto
          </Typography>
          <Typography sx={{ fontSize: 12, mt: 2 }}>
            {normalizedLabel}
          </Typography>
        </>
      }
      footer={
        <Button
          data-testid="acceptButton"
          onClick={() => onclick(rententionDiscount)}
        >
          Aceitar desconto
        </Button>
      }
    />
  );
};

export default RetentionDiscountCard;

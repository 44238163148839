import { Box, Stack, Container } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import AvatarPlaceholder from './components/AvatarPlaceholder';
import SectionsLinksCard from './components/SectionLinksCard';
import UserInfoCard from './components/UserInfoCard';
import Context from '../../Context';

interface AccountProps {
  onClose?: () => void;
}

const Account = ({ onClose = () => null }: AccountProps) => {
  const { refetch, isLoadingUser } = useContext(Context);
  useEffect(() => {
    refetch();
  }, []);
  if (isLoadingUser) {
    return <div>Carregando...</div>;
  }
  return (
    <Container data-testid="accountMenu" sx={{ px: '0 !important' }}>
      <Box sx={{ color: 'common.white' }}>
        <Stack sx={{ alignItems: 'center', pt: 2, width: '100%' }}>
          {isLoadingUser ? (
            <AvatarPlaceholder />
          ) : (
            <UserInfoCard onClose={onClose} />
          )}
          <SectionsLinksCard onClose={onClose} />
        </Stack>
      </Box>
    </Container>
  );
};

export default Account;

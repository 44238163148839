import { Divider, Stack, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import { SxProps, Theme } from '@material-ui/system';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Plan, PlanStatus } from 'interfaces/plan';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { Coupon } from 'interfaces/coupon';
import { Partner } from 'interfaces/partner';
import getPaymentStatus from 'modules/auth/pages/Register/services/getPaymentStatus';
import { User } from 'interfaces/User';
import { ValueType } from 'interfaces/Discount';
import PixWithPriceZero from 'modules/auth/pages/Register/components/PixWithPriceZero';
import { useDialog } from '../../../../../components/Dialog';
import {
  getFriendlyNameByPlanType,
  PlanType,
} from '../../../../../interfaces/plan';
import CouponInput from './CouponInput';
import PaymentForm from './PaymentForm';
import { createUserSubscription } from '../../../services';
import PixModal from './PixModal';
import { defaultValidationSchema } from './form';
import { PaymentStatus } from '../../../../auth/pages/Register/typings';
import ClosePixConfirm from './ClosePixConfirm';
import UpdateFreePlanToPaidPlan from './UpdateFreePlanToPaidPlan';

const getTypePrice = (offer: Plan) => {
  const offerType = offer.type;
  switch (offerType) {
    case 'free':
      return 'Grátis';
    case 'partner':
      return '';
    default:
      return offer.price;
  }
};

interface NewSubscriptionProps {
  card: number;
  offer: Plan;
  user: User;
  clickOrigin: string;
  userPlans: Plan[];
  getPartner: Partner | null | undefined;
  setUserPlans: (plans: Plan[]) => void;
}

const NewSubscription = ({
  card,
  offer,
  user,
  clickOrigin,
  userPlans,
  setUserPlans,
  getPartner,
}: NewSubscriptionProps) => {
  const [isPaymentMethodsVisible, setIsMethodVisible] = useState(false);
  const [paymentIsLoading, setPaymentIsLoading] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState<Coupon | undefined>();
  const alert = useAlert();
  const dialog = useDialog();
  const hasCard = card > 0;
  const isPaymentByCredit = (PaymentMethod: any) => PaymentMethod === 'credit';
  const openModalAfterHiringPaidPlan = () => {
    dialog.open({
      element: <UpdateFreePlanToPaidPlan plan={offer} />,
      sx: {
        bgcolor: 'common.white',
        width: { xs: '100%', lg: 372 },
      },
    });
  };
  const closeWithPix = (subscription: any) => {
    dialog.open({
      element: (
        <ClosePixConfirm
          userPlans={userPlans}
          offer={offer}
          setUserPlans={setUserPlans}
          signUpId={subscription?.data.message}
          closePixHandler={closeWithPix}
        />
      ),
      sx: {
        bgcolor: 'common.white',
        height: 322,
        maxWidth: 375,
        width: '100%',
      },
    });
  };
  let paymentInterval: NodeJS.Timeout;
  const hasDiscount = !!offer.collectiveDiscount;
  const formattedCollectiveDiscount =
    offer.collectiveDiscount?.valueType === 'percentage'
      ? ` ${offer.collectiveDiscount?.price}%`
      : offer.collectiveDiscount?.price.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
  const isPercentageDiscount = appliedCoupon?.valueType === 'percentage';
  const couponValue = appliedCoupon ? appliedCoupon?.price : 0;
  const getCollectiveDiscountValue = () => {
    const hasCollectiveDiscount = offer!.collectiveDiscount;
    if (hasCollectiveDiscount) {
      const isPercetageDiscount =
        offer!.collectiveDiscount.valueType === ValueType.percentage;
      if (isPercetageDiscount) {
        const discountValue =
          offer.price * (offer!.collectiveDiscount?.price / 100);
        return discountValue;
      }
      return offer!.collectiveDiscount?.price;
    }
    return 0;
  };
  const priceWithCollectiveDiscount =
    offer.price - getCollectiveDiscountValue();
  const couponDiscountValue = isPercentageDiscount
    ? priceWithCollectiveDiscount * (couponValue / 100)
    : couponValue;
  const priceWithDiscounts =
    offer.price - (getCollectiveDiscountValue() + couponDiscountValue);
  const form = useFormik({
    initialValues: {
      userId: user.id,
      name: user.name,
      phone: user.phone,
      email: user.email,
      planId: offer.id,
      password: user.password,
      partners: getPartner ? getPartner.code : '',
      paymentMethod: 'gratis',
      cardBrand: '',
      coupon: '',
      cardHolderName: '',
      creditCard: '',
      expirationDate: '',
      cvv: '',
      cpf: '',
      debitCardHolderName: '',
      debitCard: '',
      debitExpirationDate: '',
      debitCVV: '',
      debitCPF: '',
      hasCoupon: false,
      usercpf: user.usercpf,
      installments: 1,
      clickOrigin,
    },
    validationSchema: defaultValidationSchema(offer, hasCard),
    onSubmit: async (values) => {
      try {
        setPaymentIsLoading(true);
        const formValues = {
          userId: values.userId,
          name: values.name,
          password: values.password,
          email: values.email,
          usercpf: values.usercpf,
          phone: values.phone?.replaceAll('+55', ''),
          planId: values.planId,
          partners: values.partners,
          paymentMethod: values.paymentMethod,
          cardBrand: values.cardBrand,
          cardHolderName: isPaymentByCredit(values.paymentMethod)
            ? values.cardHolderName
            : values.debitCardHolderName,
          creditCard: isPaymentByCredit(values.paymentMethod)
            ? values.creditCard
            : values.debitCard,
          expirationDate: isPaymentByCredit(values.paymentMethod)
            ? values.expirationDate
            : values.debitExpirationDate,
          cvv: isPaymentByCredit(values.paymentMethod)
            ? values.cvv
            : values.debitCVV,
          cpf: isPaymentByCredit(values.paymentMethod)
            ? values.cpf
            : values.debitCPF,
          coupon: values.coupon,
          installments: values.installments,
          clickOrigin: values.clickOrigin,
        };
        const planIsPartnerType = !!getPartner;
        if (planIsPartnerType) {
          const partnerForm = {
            userId: values.userId,
            planId: values.planId,
            email: values.email,
            partners: values.partners,
            paymentMethod: values.paymentMethod,
            coupon: values.coupon,
          };
          const newSubscriptionByPartners = await createUserSubscription(
            partnerForm,
          );
          window.location.href = `${newSubscriptionByPartners.data.message}`;
          return;
        }
        // envia o formulário para a API
        const newSubscription = await createUserSubscription(formValues);
        if (values.paymentMethod === 'pix') {
          // se o valor a ser pago for zero não abre modal de qrcode
          if (priceWithDiscounts <= 0) {
            await dialog.open({
              element: <PixWithPriceZero />,
              sx: { bgcolor: 'common.white', width: '100%', maxWidth: 359 },
            });
            const newUserPlans = [
              { ...offer, status: PlanStatus.active },
              ...userPlans,
            ];
            setUserPlans(newUserPlans);
          } else {
            // se for PIX abre o modal com o qrcode
            await dialog.open({
              element: (
                <PixModal
                  userPlans={userPlans}
                  offer={offer}
                  setUserPlans={setUserPlans}
                  signUpId={newSubscription.data.message}
                  openModalAfterHiringPaidPlan={openModalAfterHiringPaidPlan}
                />
              ),
              isBlocked: true,
              alternativeCloseHadler: () => closeWithPix(newSubscription),
              sx: {
                bgcolor: 'common.white',
                height: 528,
                maxWidth: 375,
                width: '100%',
              },
            });
            return;
          }
        }
        paymentInterval = setInterval(async () => {
          const response = await getPaymentStatus(newSubscription.data.message);
          if (response.data.message === PaymentStatus.Success) {
            alert.success('Plano contratado com sucesso!');
            const containsOnlyFreePlan = !userPlans?.find(
              (plan) =>
                plan.type === PlanType.monthly ||
                plan.type === PlanType.partner ||
                plan.type === PlanType.season,
            );
            if (containsOnlyFreePlan) {
              openModalAfterHiringPaidPlan();
            } else {
              dialog.close();
            }
            const newUserPlans = [
              { ...offer, status: PlanStatus.active },
              ...userPlans,
            ];
            setUserPlans(newUserPlans);
            setPaymentIsLoading(false);
            clearInterval(paymentInterval);
          } else if (response.data.message === PaymentStatus.GenericError) {
            alert.error('Falha ao contratar o plano!');
            setPaymentIsLoading(false);
            clearInterval(paymentInterval);
          }
          // se não for um dos status acima, apenas continua
        }, 5000);
      } catch (err: any) {
        alert.error('Ocorreu um erro. Tente novamente.');
        clearInterval(paymentInterval);
        setPaymentIsLoading(false);
      }
    },
  });
  const getDetailsStackProps = (styles?: SxProps<Theme>): SxProps<Theme> => ({
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mt: 2,
    ...styles,
  });
  const getDetailsTextProps = (styles?: SxProps<Theme>): SxProps<Theme> => ({
    color: 'grey.300',
    fontSize: 14,
    ...styles,
  });
  const getDividerProps = (styles?: SxProps<Theme>): SxProps<Theme> => ({
    bgcolor: 'transparent',
    ...styles,
  });
  const geySymbolPrice = getTypePrice(offer);
  const planIsFreeOrPartner = offer.type === 'free' || offer.type === 'partner';
  const planIsMonthlyOrSeasonType =
    offer.type === 'monthly' || offer.type === 'season';
  const planIsNotPartnerType = offer.type !== 'partner';
  const showPaymentMethodsHandler = () => {
    setIsMethodVisible(!isPaymentMethodsVisible);
  };
  const changePaymentMethodLabel = isPaymentMethodsVisible
    ? 'Pagar com cartão já cadastrado'
    : 'Mudar método de pagamento';
  const formattedPrice = planIsFreeOrPartner
    ? geySymbolPrice
    : priceWithDiscounts.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
  const finalPriceLabel = priceWithDiscounts <= 0 ? 'R$ 0,00' : formattedPrice;
  const hasCoupon = !!appliedCoupon;
  const couponLabel =
    appliedCoupon?.valueType === 'percentage'
      ? `- ${appliedCoupon?.price}%`
      : `- R$ ${appliedCoupon?.price.toFixed(2)}`;
  const discountRecurrenceLabel = (discount: any) => {
    if (discount?.recurrenceUnlimited) {
      return '(Válido em todos os pagamentos)';
    }
    if (discount && discount?.recurrence === 0) {
      return '(Válido apenas no pagamento atual)';
    }
    if (discount && discount?.recurrence === 1) {
      return '(Válido até o próximo pagamento)';
    }
    return `(Válido nos próximos ${discount?.recurrence} pagamentos)`;
  };
  return (
    <Stack sx={{ gap: 3, width: '100%' }}>
      <Typography
        sx={{
          color: 'secondary.main',
          fontWeight: 600,
          position: 'absolute',
          top: 26,
        }}
      >
        {offer.title}
      </Typography>
      <PerfectScrollbar
        id="container"
        style={{
          height:
            offer.type === 'free' || offer.type === 'partner' ? '20vh' : '50vh',
          paddingRight: 15,
        }}
      >
        <Typography sx={getDetailsTextProps({ color: 'grey.400' })}>
          {offer.description}
        </Typography>
        <Divider sx={getDividerProps()} />
        <Stack sx={getDetailsStackProps()}>
          <Typography sx={getDetailsTextProps()}>Tipo do plano:</Typography>
          <Typography sx={getDetailsTextProps({ color: 'grey.400', ml: 2 })}>
            {getFriendlyNameByPlanType(offer.type)}
          </Typography>
        </Stack>
        {planIsMonthlyOrSeasonType && hasCard && (
          <>
            <Stack sx={getDetailsStackProps()}>
              <Typography sx={getDetailsTextProps()}>Cartão ativo:</Typography>
              <Typography sx={getDetailsTextProps({ color: 'grey.400' })}>
                final {card}
              </Typography>
            </Stack>
            <Typography
              sx={{
                color: 'secondary.main',
                cursor: 'pointer',
                fontSize: 12,
                fontWeight: 'bold',
                textAlign: 'right',
              }}
              onClick={showPaymentMethodsHandler}
            >
              {changePaymentMethodLabel}
            </Typography>
          </>
        )}
        {!planIsFreeOrPartner && (
          <CouponInput
            form={form}
            appliedCoupon={appliedCoupon}
            setAppliedCoupon={setAppliedCoupon}
          />
        )}
        {(isPaymentMethodsVisible || !hasCard) && (
          <PaymentForm offer={offer} form={form} />
        )}
        {planIsNotPartnerType && (
          <>
            {hasDiscount && (
              <>
                <Stack sx={getDetailsStackProps()}>
                  <Typography sx={getDetailsTextProps()}>
                    Valor do plano:
                  </Typography>
                  <Typography sx={getDetailsTextProps({ color: 'grey.500' })}>
                    {offer.price.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Stack>
                <Stack sx={getDetailsStackProps()}>
                  <Stack>
                    <Typography sx={getDetailsTextProps()}>
                      Desconto: {offer.collectiveDiscount.title}
                    </Typography>
                    <Typography sx={getDetailsTextProps({ fontSize: 11 })}>
                      {discountRecurrenceLabel(offer.collectiveDiscount)}
                    </Typography>
                  </Stack>
                  <Typography sx={getDetailsTextProps({ color: 'error.main' })}>
                    - {formattedCollectiveDiscount}
                  </Typography>
                </Stack>
              </>
            )}
            {hasCoupon && (
              <Stack sx={getDetailsStackProps()}>
                <Stack>
                  <Typography sx={getDetailsTextProps()}>
                    Cupom: {appliedCoupon?.title}
                  </Typography>
                  <Typography sx={getDetailsTextProps({ fontSize: 11 })}>
                    {discountRecurrenceLabel(appliedCoupon)}
                  </Typography>
                </Stack>
                <Typography sx={getDetailsTextProps({ color: 'error.main' })}>
                  {couponLabel}
                </Typography>
              </Stack>
            )}
          </>
        )}
        <Divider sx={getDividerProps()} />
        {planIsNotPartnerType && (
          <Stack sx={getDetailsStackProps()}>
            <Typography
              sx={getDetailsTextProps({ color: 'grey.500', fontWeight: 600 })}
            >
              Valor pago:
            </Typography>
            <Typography sx={{ color: 'secondary.main', fontWeight: 600 }}>
              {finalPriceLabel}
            </Typography>
          </Stack>
        )}
      </PerfectScrollbar>
      <Divider sx={getDividerProps({ mt: 1 })} />
      <LoadingButton
        data-testid="confirmSubscriptionButton"
        loading={paymentIsLoading}
        disabled={paymentIsLoading}
        sx={{ height: 60 }}
        onClick={() => form.handleSubmit()}
      >
        Assinar Agora
      </LoadingButton>
    </Stack>
  );
};

export default NewSubscription;

import { Box, Container, Grid, Stack, Typography } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIcon } from '@material-ui/icons';
import { useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQuery } from 'react-query';
import { ReactQueryNames } from 'react-query/react-query-names';
import { useNavigate } from 'react-router-dom';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Partner } from '../../../interfaces/partner';
import Footer from '../../../components/Footer/Footer';
import Navbar from '../paulistaoplay/Navbar';
import { getPlans, getPartners } from '../services';
import { logo } from '../utils/assets';
import Context from '../utils/context';

const Partners = () => {
  const navigate = useNavigate();
  const scrollableNodeRef = useRef(null);
  const { isLoading, data: plans } = useQuery(ReactQueryNames.PLANS, getPlans);
  const { isLoading: isLoadingPartners, data: newPartners } = useQuery(
    ReactQueryNames.PARTNERS,
    getPartners,
  );
  const sendDataToGTM = useGTMDispatch();
  const navigateTo = (path: string) => {
    sendDataToGTM({ event: 'NavigateFromHome', path });
    navigate(path);
  };
  if (isLoading || isLoadingPartners) {
    return <div>loading...</div>;
  }
  const partners = newPartners!.filter((currentPartner) => {
    const partnerIsInSomePlan = plans!.data.some(
      (plan) =>
        !!plan.partners.some(
          (currentPlan) => currentPlan.code === currentPartner.code,
        ),
    );
    return partnerIsInSomePlan;
  });
  const goToPartnerRegisterHandler = (partner: Partner) => () => {
    sendDataToGTM({ event: 'PartnerRegister', partner: partner.code });
    navigateTo(`/auth/register?code=${partner.code}`);
  };
  return (
    <Context.Provider value={{ logo, navigateTo }}>
      <Stack
        sx={{
          backgroundColor: '#3B1F3E',
          overflow: 'auto',
          '.ps__rail-y': { display: 'none' },
        }}
      >
        <PerfectScrollbar id="container" style={{ height: '100vh' }}>
          <Navbar scrollableNodeRef={scrollableNodeRef} />
          <Container maxWidth="lg" sx={{ px: { xs: 3, lg: 10 } }}>
            <Stack
              alignItems="center"
              direction="row"
              gap={3}
              sx={{ cursor: 'pointer', mb: 4, mt: { xs: 10, lg: '115px' } }}
              onClick={() => navigate('/')}
            >
              <ArrowBackIcon sx={{ color: 'grey.100' }} />
              <Typography
                sx={{
                  color: 'grey.100',
                  fontFamily: 'primary',
                  fontSize: { xs: 14, lg: 16 },
                  lineHeight: { xs: '24px', lg: '32px' },
                  textAlign: 'center',
                }}
              >
                Voltar
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              gap={6}
              sx={{ mb: 8, minHeight: '65vh' }}
            >
              <Typography
                sx={{
                  color: 'grey.100',
                  fontWeight: 600,
                  fontSize: { xs: 24, lg: 32 },
                  lineHeight: { xs: '24px', lg: '32px' },
                  textAlign: 'center',
                }}
              >
                Escolha o seu parceiro
              </Typography>
              <Typography
                sx={{
                  color: 'grey.100',
                  fontFamily: 'secondary',
                  fontSize: { xs: 16, lg: 24 },
                  maxWidth: { xs: 326, lg: 1030 },
                  textAlign: 'center',
                }}
              >
                Assinar o{' '}
                <Typography
                  display="inline"
                  sx={{
                    color: 'grey.100',
                    fontFamily: 'primary',
                    fontSize: { xs: 16, lg: 24 },
                    maxWidth: { xs: 326, lg: 1030 },
                    textAlign: 'center',
                  }}
                >
                  Paulistão Play{' '}
                </Typography>
                <Typography
                  display="inline"
                  sx={{
                    color: 'grey.100',
                    fontFamily: 'secondary',
                    fontSize: { xs: 16, lg: 24 },
                    maxWidth: { xs: 326, lg: 1030 },
                    textAlign: 'center',
                  }}
                >
                  nunca foi tão fácil. Confira os parceiros disponíveis e faça a
                  contratação dos planos sem cobrança.
                </Typography>
              </Typography>
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                rowGap={4}
                sx={{ width: '100%' }}
              >
                {partners?.map((partner) => (
                  <Grid
                    key={partner.code}
                    item
                    xs={4}
                    lg={2}
                    onClick={goToPartnerRegisterHandler(partner)}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'common.white',
                        backgroundImage: `URL(${partner.logo})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        borderColor: 'grey.500',
                        borderRadius: 2,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        cursor: 'pointer',
                        height: { xs: 58, lg: 80 },
                        width: { xs: 104, lg: 143 },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Container>
          <Footer />
        </PerfectScrollbar>
      </Stack>
    </Context.Provider>
  );
};

export default Partners;

import { Avatar, Box, Stack, Typography } from '@material-ui/core';
import { ArrowBackIos as ArrowBack } from '@material-ui/icons';
import { Team } from 'interfaces/team';
import getTeams from 'modules/auth/pages/Register/services/getTeams';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../../components/Footer/Footer';
import Context from '../../../../Context';
import AvatarList from './components/AvatarList';

const Editvatar = () => {
  const [userAvatar, setUserAvatar] = useState('');
  const { profile, isLoadingUser } = useContext(Context);
  const { isLoading: lookingForTeams, data: teams } = useQuery(
    'teams',
    getTeams,
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (profile) {
      setUserAvatar(profile.avatar || '');
    }
  }, [profile]);
  if (isLoadingUser || lookingForTeams || !profile || !teams)
    return <div>Carregando...</div>;
  const favoriteTeamId = profile?.favoriteTeamId;
  const searchTeamByFavoriteTeamId: Team = teams?.data?.filter(
    (team) => team.id === favoriteTeamId,
  )[0];
  const teamName = searchTeamByFavoriteTeamId.name;
  const avatarUrls = searchTeamByFavoriteTeamId?.avatars?.map(
    (currentAvatar) => currentAvatar.url,
  );
  const user = { name: profile!.name, phone: profile!.phone };
  return (
    <Box sx={{ position: 'relative' }}>
      <Stack
        alignItems="center"
        direction="row"
        gap={4}
        sx={{
          bgcolor: { xs: 'grey.600', lg: 'transparent' },
          borderTopColor: { xs: 'grey.500', lg: 'transparent' },
          borderTopStyle: { xs: 'solid', lg: 'none' },
          borderTopWidth: { xs: '1px', lg: 0 },
          cursor: 'pointer',
          height: 43,
          top: { xs: 0, lg: 5 },
          position: { lg: 'absolute' },
          px: { xs: 5, lg: 11 },
          width: '100%',
          zIndex: 1000,
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBack sx={{ color: 'grey.200', fontSize: 12 }} />
        <Typography
          sx={{ color: 'grey.200', fontFamily: 'secondary', fontSize: 16 }}
        >
          Alterar imagem de perfil
        </Typography>
      </Stack>
      <Box
        sx={{
          maxWidth: { xs: 327, md: 596 },
          mb: { xs: '60px', lg: '96px' },
          mt: { xs: 6, lg: '90px' },
          mx: 'auto',
          px: { xs: 3, lg: 0 },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={{ xs: 4, lg: 8 }}
          sx={{
            bgcolor: 'rgba(0, 0, 0, 0.25)',
            borderColor: 'grey.400',
            borderRadius: 1,
            borderStyle: 'solid',
            borderWidth: '1px',
            height: { xs: 88, lg: 128 },
            px: { xs: 4, lg: 6 },
            width: '100%',
          }}
        >
          <Avatar
            src={userAvatar}
            sx={{ height: { xs: 56, lg: 80 }, width: { xs: 56, lg: 80 } }}
          />
          <Stack justifyContent="center" gap={3}>
            <Typography
              sx={{
                color: 'grey.200',
                fontFamily: 'secondary',
                fontSize: { xs: 16, lg: 20 },
              }}
            >
              Time do coração
            </Typography>
            <Typography
              sx={{
                color: 'grey.200',
                fontFamily: 'primary',
                fontSize: { xs: 14, lg: 16 },
              }}
            >
              {teamName}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          sx={{
            color: 'grey.200',
            fontFamily: 'secondary',
            fontSize: 16,
            my: 4,
          }}
        >
          Selecione um ícone para representar seu time do coração:
        </Typography>
        <AvatarList
          avatarUrls={avatarUrls}
          user={user}
          userAvatar={userAvatar}
          setUserAvatar={setUserAvatar}
        />
      </Box>
      <Footer sx={{ bottom: 0, position: { lg: 'fixed' }, width: '100%' }} />
    </Box>
  );
};

export default Editvatar;

import { useQuery } from 'react-query';
import { getLiveSection } from 'services/Section/SectionService';
import { Stack } from '@material-ui/core';
import { useContext, useEffect, useRef } from 'react';
import ContentsGrid from 'modules/home/refactor/components/ContentsGrid';
import { HomePlaceholder as LivePlaceholder } from 'modules/home/refactor/components/HomePlaceholder';
import Footer from 'components/Footer/Footer';
import Context from '../../Context';
import SectionContentEmptyState from './components/SectionContentEmptyState';

const LiveContents = () => {
  const { profile } = useContext(Context);
  const userTreeHash = profile!.treeHash;
  const fieldRef = useRef<HTMLInputElement>(null);
  const { isLoading, data } = useQuery(['liveSection', userTreeHash], () =>
    getLiveSection(userTreeHash),
  );
  useEffect(() => {
    fieldRef.current?.scrollIntoView();
  }, []);
  if (isLoading || !data?.data) return <LivePlaceholder isContent />;
  const contentsData = data?.data.sectionContent;
  return (
    <>
      <Stack
        ref={fieldRef}
        sx={{
          height: 'auto',
          m: '0 auto',
          marginBottom: 0,
          maxWidth: 1900,
          px: { xs: 2, lg: '92px' },
          width: '100%',
        }}
      >
        {contentsData && contentsData.length > 0 ? (
          <ContentsGrid contentList={contentsData} />
        ) : (
          <SectionContentEmptyState sectionIsLive />
        )}
      </Stack>
      <Footer />
    </>
  );
};

export default LiveContents;

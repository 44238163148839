import { KeyboardEvent } from 'react';

// TODO: add typing
// eslint-disable-next-line import/prefer-default-export
export const withEnter =
  (cb: any) => (evt: KeyboardEvent<HTMLInputElement>) => {
    const enterWasNotPressed = evt.which !== 13;
    if (enterWasNotPressed) {
      return;
    }
    cb?.();
  };

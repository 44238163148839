import { Box, Button, Card, Stack, Typography } from '@material-ui/core';
import { Plan } from 'interfaces/plan';
import { PartnerOrNull } from '../../../typings';

interface OfferedPlanCardProps {
  offer: Plan;
  partner: PartnerOrNull;
  openNewSubscription: (offer: Plan) => void;
}

const getTypePrice = (offer: Plan) => {
  const offerType = offer.type;
  switch (offerType) {
    case 'free':
      return 'Grátis';
    case 'partner':
      return '';
    default:
      return offer.price;
  }
};

const OfferedPlanCard = ({
  offer,
  partner,
  openNewSubscription,
}: OfferedPlanCardProps) => {
  const geySymbolPrice = getTypePrice(offer);
  const planIsFreeOrPartner = offer.type === 'free' || offer.type === 'partner';
  const openSubscribeNowModal = () => openNewSubscription(offer);
  const collectiveDiscountValue = offer.collectiveDiscount?.price;
  const getPriceWithDiscounts = () => {
    if (!offer.collectiveDiscount) {
      return offer.price.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    let finalValue = 0;
    if (offer.collectiveDiscount?.valueType === 'percentage') {
      const discountValue = offer.price * (collectiveDiscountValue / 100);
      finalValue = offer.price - discountValue;
    } else {
      finalValue = offer.price - offer.collectiveDiscount?.price;
    }
    const finalValueIsLessThanZero = finalValue < 0;
    if (finalValueIsLessThanZero) {
      finalValue = 0;
    }
    return finalValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };
  const fullPrice = offer.price.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const formattedPrice = planIsFreeOrPartner
    ? geySymbolPrice
    : getPriceWithDiscounts();
  const normalizedRecurrenceLabel = offer.type === 'monthly' ? '/mês' : '/ano';
  return (
    <Card
      data-testid={`offerPlanCard-${offer.id}`}
      key={offer.title}
      sx={{
        bgcolor: 'grey.600',
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'column',
        height: 158,
        px: 4,
        width: { xs: '100%', lg: 327 },
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          flexDirection: 'row',
          height: 54,
        }}
      >
        {partner && (
          <Box
            sx={{
              backgroundImage: `url(${partner.logo})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: 50,
              mr: 4,
              width: 50,
            }}
          />
        )}
        <Typography sx={{ color: 'common.white', fontFamily: 'primary' }}>
          {offer.title}
        </Typography>
      </Stack>
      <Typography
        sx={{ color: 'grey.300', fontSize: 12, fontFamily: 'secondary' }}
      >
        {offer.description}
      </Typography>
      <Stack
        sx={{
          alignItems: 'center',
          color: 'grey.300',
          flexDirection: 'row',
          height: 68,
          justifyContent: 'space-between',
          mt: 'auto',
        }}
      >
        <Button data-testid="subscribeButton" onClick={openSubscribeNowModal}>
          Assinar Agora
        </Button>
        <Stack>
          <Typography
            sx={{
              color: 'grey.400',
              fontFamily: 'secondary',
              fontSize: 17,
              ml: 'auto',
              textDecoration: 'line-through',
            }}
          >
            {offer.collectiveDiscount ? fullPrice : null}
          </Typography>
          <Typography
            sx={{
              alignItems: 'center',
              color: 'common.white',
              display: 'flex',
              fontFamily: 'primary',
              fontSize: 20,
              ml: 'auto',
            }}
          >
            {formattedPrice}
            {!planIsFreeOrPartner && (
              <Typography
                sx={{
                  color: 'common.white',
                  fontFamily: 'secondary',
                  fontSize: 12,
                  ml: 1,
                  opacity: 0.5,
                }}
              >
                {normalizedRecurrenceLabel}
              </Typography>
            )}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default OfferedPlanCard;

import {
  Box,
  Grid,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import React from 'react';

interface SectionsListSkeletonProps {
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps;
}

const SectionsListSkeleton = ({ sx }: SectionsListSkeletonProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const listsCount = 2;
  const cardsPerList = isMobile ? 2 : 4;
  const skeletonList = [...Array(listsCount)].map(() => (
    <Box data-testid="sections-list-skeleton" sx={sx}>
      <Skeleton
        variant="text"
        width={115}
        sx={{
          bgcolor: 'grey.600',
          borderRadius: 3,
          height: { lg: 30, xs: 19 },
          mb: 4,
        }}
      />
      <Grid container spacing={4}>
        {[...Array(cardsPerList)].map((value) => (
          <Grid key={value} item xs={6} lg={3}>
            <Skeleton
              variant="rectangular"
              sx={{
                bgcolor: 'grey.600',
                borderRadius: 2,
                height: {
                  xs: 118,
                  lg: 150,
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  ));
  return <>{skeletonList}</>;
};

export default SectionsListSkeleton;

import { Plan } from '../../interfaces/plan';
import { PageType } from './typings';
import CancellationHold from './placeholders/CancellationHold';
import RetentionError from './placeholders/RetentionError';
import RetentionSuccess from './placeholders/RetentionSuccess';
import SubscriptionCancellation from './placeholders/SubscriptionCancellation';
import SubscriptionError from './placeholders/SubscriptionError';
import SubscriptionRenewalError from './placeholders/SubscriptionRenewalError';
import SubscriptionRenewalSuccess from './placeholders/SubscriptionRenewalSuccess';
import SubscriptionSuccess from './placeholders/SubscriptionSuccess';

export const getPageTypeByPathname = (pathname: string) => (
  {
    'cancellation-hold': PageType.cancellationHold,
    'retention-error': PageType.retentionError,
    'retention-success': PageType.retentionSuccess,
    'subscription-cancellation': PageType.subscriptionCancellation,
    'subscription-error': PageType.subscriptionError,
    'subscription-renewal-error': PageType.subscriptionRenewalError,
    'subscription-renewal-success': PageType.subscriptionRenewalSuccess,
    'subscription-success': PageType.subscriptionSuccess,
  }[pathname]
);

export const getPagePlaceholderByPageType = (pageType: PageType, subscription?: Plan): JSX.Element => (
  {
    [PageType.cancellationHold]: <CancellationHold subscription={subscription} />,
    [PageType.retentionError]: <RetentionError subscription={subscription} />,
    [PageType.retentionSuccess]: <RetentionSuccess subscription={subscription} />,
    [PageType.subscriptionCancellation]: <SubscriptionCancellation subscription={subscription} />,
    [PageType.subscriptionError]: <SubscriptionError subscription={subscription} />,
    [PageType.subscriptionRenewalError]: <SubscriptionRenewalError subscription={subscription} />,
    [PageType.subscriptionRenewalSuccess]: <SubscriptionRenewalSuccess subscription={subscription} />,
    [PageType.subscriptionSuccess]: <SubscriptionSuccess />,
  }[pageType]
);

import qs from 'qs';
import axiosHelper from 'helpers/axios';
import { UserProfile } from '../../../typings';
import { UserProfilePayload } from '../typings';

const saveUserProfile = (input: UserProfilePayload) =>
  axiosHelper.patch<UserProfilePayload, UserProfile>(
    '/user/profile',
    qs.stringify(input),
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );

export default saveUserProfile;

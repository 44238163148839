import { Stack } from '@material-ui/core';
import { useRef, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import SectionContentEmptyState from 'components/SectionContent/components/SectionContentEmptyState';
import Footer from '../../../../components/Footer/Footer';
import Context from '../../../../Context';
import { getCotentsByTeam } from '../services';
import Hero from './Hero';
import { HomePlaceholder as FavoriteTeamPlaceHolder } from './HomePlaceholder';
import SectionList from './SectionList';

const FavoriteTeamSection = () => {
  const { profile } = useContext(Context);
  const userTreeHash = profile!.treeHash;
  const teamId = profile?.favoriteTeamId;
  const { isLoading, data } = useQuery('favouriteTeam', () =>
    getCotentsByTeam(userTreeHash, teamId),
  );
  const hasNoFeaturedContents = data?.data.featuredContent?.length === 0;
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
  useEffect(() => {
    if (!data) return;
    itemsRef.current = itemsRef.current.slice(
      0,
      data?.data.section?.length || 0,
    );
  }, [data]);
  if (isLoading || !data?.data) {
    return <FavoriteTeamPlaceHolder />;
  }
  const updateItemsRef = (element: HTMLDivElement | null, index: number) => {
    itemsRef.current[index] = element;
  };
  const hasNoFavoriteTeamContents = data?.data.section?.length === 0;
  if (hasNoFavoriteTeamContents)
    return <SectionContentEmptyState sectionIsLive={false} />;
  return (
    <>
      <Stack
        sx={{
          width: '100%',
          maxWidth: 1920,
          height: 'auto',
          mx: 'auto',
        }}
      >
        {!hasNoFeaturedContents && <Hero data={data.data} />}
        <Stack
          sx={{
            width: '100%',
            mt: {
              xs: hasNoFeaturedContents ? 0 : '-100px',
              lg: hasNoFeaturedContents ? '72px' : '-200px',
            },
            px: { xs: 2, lg: 0 },
            zIndex: 1000,
          }}
        >
          <SectionList data={data?.data} setRef={updateItemsRef} />
        </Stack>
      </Stack>
      <Footer />
    </>
  );
};

export default FavoriteTeamSection;

import { Stack, Typography } from '@material-ui/core';
import {
  ArrowForwardIos as ArrowIcon,
  Logout as LogoutIcon,
} from '@material-ui/icons';
import { SxProps, Theme } from '@material-ui/system';
import { doLogout } from 'modules/pages/services';
import { useNavigate } from 'react-router-dom';
import useRegisterLocalStorage from 'utils/hooks/use-register-local-storage';
import filterSectionLinksByPermissions from 'utils/filterSectionLinksByPermissions';

interface SectionsLinksCardProps {
  // eslint-disable-next-line react/require-default-props
  onClose?: () => void;
}

const SectionsLinksCard = ({
  onClose = () => null,
}: SectionsLinksCardProps) => {
  const { clearRegisterLocalStorage } = useRegisterLocalStorage();
  const navigate = useNavigate();
  const logout = async () => {
    try {
      await doLogout();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      clearRegisterLocalStorage();
      navigate('/');
    }
  };
  const navigateToSection = (pathname: string) => {
    navigate(pathname);
    onClose();
  };
  const getSectionLinkProps = (styles?: SxProps<Theme>): SxProps<Theme> => ({
    alignItems: 'center',
    color: 'grey.300',
    cursor: 'pointer',
    flexDirection: 'row',
    mb: 4,
    ...styles,
  });
  const permissions = JSON.parse(process.env.REACT_APP_PERMISSIONS as string);
  const newSections = filterSectionLinksByPermissions(permissions);
  return (
    <Stack sx={{ mt: 4, px: 5, width: '100%' }}>
      {newSections.map((section) => (
        <Stack
          sx={getSectionLinkProps()}
          onClick={() => navigateToSection(section.pathname)}
        >
          {section.icon}
          <Typography
            sx={{
              fontFamily: 'secondary',
              fontSize: 14,
              ml: 3,
            }}
          >
            {section.label}
          </Typography>
          <ArrowIcon sx={{ height: 13, ml: 'auto' }} />
        </Stack>
      ))}
      <Stack
        sx={getSectionLinkProps({
          color: 'error.main',
          position: 'absolute',
          bottom: { xs: '50px', lg: 0 },
        })}
        onClick={logout}
      >
        <LogoutIcon sx={{ height: 20, width: 20 }} />
        <Typography sx={{ fontFamily: 'secondary', fontSize: 14, ml: 3 }}>
          Sair da conta
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SectionsLinksCard;

import axiosHelper from '../../../helpers/axios';
import { ConsumableContent } from '../../../interfaces/Content';

const getContent = (id: ConsumableContent['id']) =>
  axiosHelper.get<ConsumableContent>(`/content/${id}`).then(({ data }) => ({
    ...data,
    scheduleDate: data.scheduleDate ? new Date(data.scheduleDate) : undefined,
  }));

export default getContent;

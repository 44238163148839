/* eslint-disable import/prefer-default-export */
import axiosHelper from 'helpers/axios';
import axiosHelperV2 from 'helpers/axiosV2';
import { ContentResponse } from './SectionService.types';

export const getSections = () => axiosHelper.get<ContentResponse>('/content');
export const getSectionsV2 = (treehash: string) =>
  axiosHelperV2.get(
    `/content/${treehash}`,
  );
export const getLiveSection = (treehash: string) =>
  axiosHelperV2.get(
    `/content/${treehash}/live`,
  );

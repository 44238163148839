import { Box, Stack, Typography } from '@material-ui/core';
import { AccessTimeFilled as ClockIcon } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ConsumableContent } from '../../../interfaces/Content';
import { formatDate } from '../../../utils/date';

interface RelatedVideosListProps {
  relatedVideos: ConsumableContent[];
}

const RelatedVideosList = ({ relatedVideos }: RelatedVideosListProps) => {
  const navigate = useNavigate();
  const navigateToContent = (id: string) => navigate(`/content/${id}`);
  if (relatedVideos === undefined) return null;
  if (relatedVideos && relatedVideos.length === 0) return null;
  const relatedVideosList = useMemo(
    () =>
      relatedVideos?.map((video) => (
        <SwiperSlide key={video.id}>
          <Stack
            data-testid="related-video"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigateToContent(video.id)}
          >
            <Box
              sx={{
                backgroundImage: `url(${video.thumbnail})`,
                backgroundSize: 'cover',
                borderRadius: 2,
                height: { xs: 124, lg: 156 },
                mb: 3,
                width: { xs: 220, lg: 277 },
              }}
            />
            <Typography
              sx={{ color: 'grey.100', fontSize: 14, fontWeight: 600, mb: 1 }}
            >
              {video.title}
            </Typography>
            <Typography
              sx={{
                alignItems: 'center',
                color: 'grey.100',
                display: 'flex',
                fontSize: 14,
                gap: 1,
              }}
            >
              <ClockIcon sx={{ color: 'grey.400', height: 15, width: 15 }} />
              {video.scheduleDate
                ? formatDate(
                    video.scheduleDate,
                    "dd 'de' MMM 'de' yyyy',' HH'h'mm",
                  )
                : null}
            </Typography>
          </Stack>
        </SwiperSlide>
      )),
    [relatedVideos],
  );
  return (
    <Stack sx={{ position: 'relative', width: '100%' }}>
      <Typography sx={{ color: 'grey.100', fontSize: 20, mb: 6 }}>
        Talvez você também goste
      </Typography>
      <Swiper
        data-testid="related-videos-list"
        navigation
        slidesPerView="auto"
        spaceBetween={24}
        style={{ width: '100%' }}
      >
        {relatedVideosList}
      </Swiper>
    </Stack>
  );
};

export default RelatedVideosList;

import { Grid, Typography, Stack } from '@material-ui/core';
import HeadItem from './HeadItem';

const TournamentsHeader = () => (
  <Grid container>
    <Grid item xs={2} sx={{ bgcolor: 'grey.600',  borderRadius: '16px 0 0 0', minWidth: 204, py: 4}}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{ alignItems: 'center' }}
      >
        <Typography
          sx={{ color: 'common.white', fontSize: 24, fontWeight: 600 }}
        >
          EQUIPES
        </Typography>
      </Stack>
    </Grid>
    <HeadItem title="PTS" />
    <HeadItem title="JOG" />
    <HeadItem title="VIT" />
    <HeadItem title="EMP" />
    <HeadItem title="DER" />
    <HeadItem title="GOL.P" />
    <HeadItem title="GOL.C" />
    <HeadItem title="SG" />
    <HeadItem title="%" />
  </Grid>
);

export default TournamentsHeader;

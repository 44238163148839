import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useLoggedIn from 'utils/hooks/use-logged-in';

interface PrivateRouteProps {
  children: ReactElement;
  redirectTo?: string;
}

const PrivateRoute = ({ children, redirectTo = '/' }: PrivateRouteProps) => {
  const { pathname } = useLocation();
  const { get } = useLoggedIn();
  const isLoggedIn = !!get();
  return isLoggedIn ? (
    children
  ) : (
    <Navigate to={`${redirectTo}?redirectTo=${pathname}`} />
  );
};

export default PrivateRoute;

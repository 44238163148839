import { OutlinedInput, Stack, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useContext } from 'react';
import Context from '../../Context';
import styles from './styles';

interface SecurityFormProps {
  isSaving: boolean;
  onSubmit: () => Promise<any>;
}

const SecurityForm = ({ isSaving, onSubmit }: SecurityFormProps) => {
  const { form } = useContext(Context);
  return (
    <Stack data-testid="passwordForm" sx={styles.form}>
      <Stack spacing={4}>
        <Typography sx={styles.header}>Trocar senha</Typography>
        <Stack>
          <Typography sx={styles.label}>
            Digite sua senha atual{' '}
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: 14 }}
            >
              *
            </Typography>
          </Typography>
          <OutlinedInput
            data-testid="currentPasswordInput"
            name="currentPassword"
            type="password"
            placeholder="Senha atual"
            error={!!form.errors.currentPassword}
            value={form.values.currentPassword}
            sx={styles.input}
            onChange={form.handleChange}
          />
          {form.errors.currentPassword && (
            <Typography
              data-testid="currentPasswordErrorAlert"
              sx={styles.inputError}
            >
              {form.errors.currentPassword}
            </Typography>
          )}
        </Stack>
        <Stack>
          <Typography sx={styles.label}>
            Digite uma nova senha{' '}
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: 14 }}
            >
              *
            </Typography>
          </Typography>
          <OutlinedInput
            data-testid="newPasswordInput"
            name="newPassword"
            type="password"
            placeholder="Senha"
            value={form.values.newPassword}
            error={!!form.errors.newPassword}
            onChange={form.handleChange}
            sx={styles.input}
          />
          {form.errors.newPassword && (
            <Typography
              data-testid="newPasswordErrorAlert"
              sx={styles.inputError}
            >
              {form.errors.newPassword}
            </Typography>
          )}
        </Stack>
        <Stack>
          <Typography sx={styles.label}>
            Repita a senha{' '}
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: 14 }}
            >
              *
            </Typography>
          </Typography>
          <OutlinedInput
            data-testid="confirmNewPasswordInput"
            name="confirmNewPassword"
            type="password"
            placeholder="Senha"
            value={form.values.confirmNewPassword}
            error={!!form.errors.confirmNewPassword}
            onChange={form.handleChange}
            sx={styles.input}
          />
          {form.errors.confirmNewPassword && (
            <Typography
              data-testid="confirmNewPasswordErrorAlert"
              sx={styles.inputError}
            >
              {form.errors.confirmNewPassword}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack>
        <LoadingButton
          variant="contained"
          color="secondary"
          data-testid="submitButton"
          loading={isSaving}
          size="large"
          sx={styles.submitButton}
          onClick={onSubmit}
        >
          Salvar alterações
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default SecurityForm;

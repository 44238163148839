import { SxProps, Theme } from '@material-ui/system';
import { ReactElement } from 'react';

export interface IDialogOptions {
  title?: string | ReactElement;
  dataTestId?: string;
  message?: string;
  element?: ReactElement;
  type?: 'dialog' | 'confirmation';
  callback?: any;
  isBlocked?: boolean;
  closable?: boolean;
  alternativeCloseHadler?: () => void;
  onOk?: () => void;
  onCancel?: () => void;
  sx?: SxProps<Theme>;
}

export const DEFAULT_DIALOG_OPTIONS: IDialogOptions = {
  closable: true,
};

import { Editor, Frame } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import React from 'react';
import {
  DoubleContainer,
  DynamicButton,
  DynamicImage,
  DynamicText,
  DynamicVideo,
  HalfContainer,
  PageFooter,
  PageHeader,
  SingleContainer,
} from './components';
import MainContainer from './components/MainContainer';

const PageContainer = ({ json }: { json: string }) => (
  <Stack sx={{ height: '100vh', overflow: 'auto' }}>
    <Editor
      resolver={{
        DoubleContainer,
        DynamicButton,
        DynamicImage,
        DynamicText,
        DynamicVideo,
        HalfContainer,
        MainContainer,
        PageFooter,
        PageHeader,
        SingleContainer,
      }}
      enabled={false}
    >
      <Frame data={json}>
        <></>
      </Frame>
    </Editor>
  </Stack>
);

export default PageContainer;

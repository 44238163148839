import { ContentType } from 'interfaces/Content';

export const getContentType = (type: string) => {
  switch (type) {
    case 'video':
      return ContentType.video;
    case 'vod':
      return ContentType.video;
    case 'live':
      return ContentType.live;
    case 'external':
      return ContentType.external;
    default:
      return ContentType.video;
  }
};

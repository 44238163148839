import qs from 'qs';
import axiosHelper from 'helpers/axios';
import { PasswordPayload } from '../typings';

const changeUserPassword = (password: PasswordPayload) =>
  axiosHelper.patch('/user/profile/change-password', qs.stringify(password), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

export default changeUserPassword;

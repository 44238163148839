import { ButtonProps, Chip, Stack, Typography } from '@material-ui/core';
import {
  Notifications as NotificationsIcon,
  PlayArrow as PlayIcon,
  RemoveRedEye as WatchIcon,
  AccessTime as ClockIcon,
} from '@material-ui/icons';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Content, ContentType } from '../../../interfaces/Content';
import { formatDate } from '../../../utils/date';

interface GetPropsByContentTypeProps {
  Subtitle: ReactElement;
  childrenButton: ReactElement;
  propsButton?: ButtonProps;
  // TODO: add typing
  onClickButton?: (params: any) => any;
}

export const getContentType = (
  scheduleDate?: Content['scheduleDate'],
  finishDate?: Content['finishDate'],
) => {
  const todayDate = new Date();
  const contentIsLive = scheduleDate && new Date(scheduleDate) <= todayDate;

  const contentIsVod = !scheduleDate && !finishDate;
  if (contentIsLive) {
    return ContentType.live;
  }
  if (contentIsVod) {
    return ContentType.vod;
  }
  return 'scheduled';
};

const getPropsByContentType = (
  content: Content,
): GetPropsByContentTypeProps => {
  const type = getContentType(content?.scheduleDate, content?.finishDate);
  const navigate = useNavigate();
  const contentIsLive = type === ContentType.live;
  const navigateToContentPlayer = () => {
    const container = document.querySelector('#container');
    navigate(`/content/${content.id}`);
    container?.scrollTo(0, 0);
  };
  if (contentIsLive) {
    return {
      Subtitle: (
        <Stack direction="row" spacing={2}>
          <Chip label="Ao vivo" color="error" sx={{ borderRadius: 1 }} />
          <Chip
            icon={<WatchIcon />}
            label={content.viewers}
            sx={{ bgcolor: 'grey.600', borderRadius: 1 }}
          />
        </Stack>
      ),
      childrenButton: (
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          Assistir agora <PlayIcon sx={{ ml: 2 }} />
        </Typography>
      ),
      propsButton: {
        color: 'error',
        onClick: () => navigateToContentPlayer(),
      },
    };
  }
  if (type === ContentType.vod) {
    return {
      Subtitle: (
        <Typography>
          Publicado {formatDate(content!.scheduleDate!, "EEEE, dd 'de' MMMM")}
        </Typography>
      ),
      childrenButton: (
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          Assistir agora <PlayIcon sx={{ ml: 2 }} />
        </Typography>
      ),
      propsButton: {
        color: 'primary',
        onClick: navigateToContentPlayer,
      },
    };
  }
  return {
    Subtitle: (
      <Stack direction="row" spacing={2}>
        <ClockIcon />
        <Typography>
          {formatDate(content!.scheduleDate!, "EEEE, dd 'de' MMMM, HH'h'mm")}
        </Typography>
      </Stack>
    ),
    childrenButton: (
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        Avise-me quando iniciar <NotificationsIcon sx={{ ml: 2 }} />
      </Typography>
    ),
    propsButton: {
      color: 'primary',
    },
  };
};

export default getPropsByContentType;

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Chip,
  ChipProps,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import React from 'react';

interface ChipCardProps {
  label: string;
  ChipCardProps?: ChipProps;
  TypographyElementProps?: TypographyProps;
}

const ChipCard = ({
  label,
  ChipCardProps,
  TypographyElementProps,
}: ChipCardProps) => (
  <Chip
    sx={{ bgcolor: 'grey.500' }}
    label={
      <Typography
        sx={{
          color: 'grey.200',
          fontSize: { xs: 14, lg: 16 },
          fontWeight: 'normal',
          px: 4,
          py: 2,
        }}
        {...TypographyElementProps}
      >
        {label}
      </Typography>
    }
    {...ChipCardProps}
  />
);

export default ChipCard;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Radio,
  Stack,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import React, { useContext, useState } from 'react';
import { Plan } from '../../../../../interfaces/plan';
import Context from '../Context';
import PlanPriceComponent from './PlanPriceComponent';

interface PlanAccordionProps {
  plan: Plan;
  selected: boolean;
}

const PlanAccordion = ({ plan, selected }: PlanAccordionProps) => {
  if (selected === false || selected === undefined || selected === null) {
    // exibe apenas o plano selecionado
    return null;
  }
  const [expandAccordion, setExpandAccordion] = useState(true);
  const { form } = useContext(Context);
  const sendDataToGTM = useGTMDispatch();
  const selectPlanHandler = () => {
    sendDataToGTM({
      event: 'SelectPlan',
      plan: {
        id: plan.id,
        title: plan.title,
        type: plan.type,
        updatedAt: plan.updatedAt,
        createdAt: plan.createdAt,
      },
    });
    form.setFieldValue('plan', plan);
  };
  const accordionHandler = () => setExpandAccordion((prevState) => !prevState);
  return (
    <Box sx={{ position: 'relative' }}>
      <Accordion
        disableGutters
        sx={{ bgcolor: 'rgba(243, 243, 243, .2)', borderRadius: 1 }}
        expanded={expandAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'grey.300' }} />}
          onClick={accordionHandler}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                color: 'grey.200',
                fontFamily: 'primary',
                fontSize: 14,
                ml: 6,
              }}
            >
              {plan.title}
            </Typography>
            <PlanPriceComponent plan={plan} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              color: 'primary.light',
              fontFamily: 'secondary',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {plan.description}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Radio
        checked={selected}
        value={plan.id}
        onChange={selectPlanHandler}
        sx={{
          position: 'absolute',
          left: 0,
          top: 3,
          '.MuiSvgIcon-root': { color: 'rgba(182, 182, 182, 0.25)' },
          '&.Mui-checked .MuiSvgIcon-root': { color: 'tertiary.main' },
        }}
      />
    </Box>
  );
};

export default PlanAccordion;

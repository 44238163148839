import { Stack, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Slide from 'components/Slide';
import { SwiperSlide } from 'swiper/react';

const sortedSections = [
  {
    id: '1',
    title: 'Tournament 1',
    position: 1,
  },
  {
    id: '2',
    title: 'Tournament 1',
    position: 1,
  },
  {
    id: '3',
    title: 'Tournament 1',
    position: 1,
  },
  {
    id: '4',
    title: 'Tournament 1',
    position: 1,
  },
  {
    id: '5',
    title: 'Tournament 1',
    position: 1,
  },
];

const TournamentsFilter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Stack
      direction="row"
      sx={{
        maxWidth: '90%',
        mb: 4,
        width: '100%',
      }}
    >
      <Slide
        data-testid="sections-menu"
        sliderProps={{
          slidesPerGroup: isMobile ? 1 : 3,
          slidesPerView: 'auto',
          spaceBetween: 32,
        }}
      >
        {sortedSections.map((eachSection) => (
          <SwiperSlide key={eachSection.id}>
            <Typography
              component="li"
              sx={{
                color: 'grey.400',
                cursor: 'pointer',
                fontSize: { xs: 16, lg: 20 },
                fontWeight: 600,
              }}
            >
              {eachSection.title}
            </Typography>
          </SwiperSlide>
        ))}
      </Slide>
    </Stack>
  );
};

export default TournamentsFilter;

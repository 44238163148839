import axiosHelper from 'helpers/axios';
import qs from 'qs';

interface PaymentRequest {
  planIds?: string;
  email?: string;
  paymentMethod: string;
  coupon: string;
  cardHolderName: string;
  creditCard: string;
  expirationDate: string;
  cvv: string;
  cpf: string;
  installments?: number;
}

export const changePaymentMethod = (data: PaymentRequest) =>
  axiosHelper.post<{ message: string }>(
    '/user/subscriptions/regulariza',
    qs.stringify(data),
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );

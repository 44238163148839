import { Avatar, Button, Stack, Typography } from '@material-ui/core';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import useLoggedIn from 'utils/hooks/use-logged-in';
import { useDialog } from '../../../../../components/Dialog';
import sadFace from '../../../../../assets/png/sadFace.png';
import { deleteUserProfile } from '../services/deleteUserProfile';

const AccountCancellationModal = () => {
  const alert = useAlert();
  const dialog = useDialog();
  const navigate = useNavigate();
  const { clear } = useLoggedIn();
  const deleteAccount = async () => {
    try {
      await deleteUserProfile();
      dialog.close();
      alert.success('Conta cancelada com sucesso!');
      clear();
      navigate('/');
    } catch {
      alert.error('Ocorreu um erro ao finalizar a sua conta.');
    }
  };
  const chargeDate = new Date(); // TODO Get the charge date of the user when integrate with backend
  const getDateAfterMonth = () => {
    const startDate = new Date(chargeDate);
    const endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      startDate.getDate(),
    );
    return endDate;
  };
  const formattedEndDate = getDateAfterMonth().toLocaleDateString('pt-br');
  return (
    <Stack
      data-testid="accountCancellationModal"
      sx={{ alignItems: 'center', justifyContent: 'space-around' }}
    >
      <Typography
        sx={{
          color: 'primary.light',
          fontFamily: 'secondary',
          fontSize: 14,
          left: '50%',
          position: 'absolute',
          textAlign: 'center',
          transform: 'translateX(-50%)',
          top: 25,
          width: '100%',
        }}
      >
        Finalizar conta
      </Typography>
      <Avatar src={sadFace} sx={{ height: 64, width: 64 }} />
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          fontWeight: 700,
          mt: 5,
        }}
      >
        Finalizar conta?
      </Typography>
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'secondary',
          fontSize: 14,
          fontWeight: 400,
          mt: 2,
          textAlign: 'center',
        }}
      >
        A cobrança do mês atual ainda será realizada e sua assinatura estará
        ativa até {formattedEndDate}. Após este período, sua conta será
        desativada.
      </Typography>
      <Button
        color="error"
        data-testid="confirmAccountCancellationButton"
        sx={{
          height: 60,
          mt: { xs: 3, lg: 6 },
          width: 291,
        }}
        onClick={deleteAccount}
      >
        Finalizar conta
      </Button>
    </Stack>
  );
};

export default AccountCancellationModal;

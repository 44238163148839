/* eslint-disable react/require-default-props */
import { Box, Stack, Typography } from '@material-ui/core';
import React from 'react';
import useLoggedIn from 'utils/hooks/use-logged-in';
import subscriptionRenewalError from '../../../assets/jpg/subscriptionRenewalError.jpg';
import PlaceholderNavigationButton from '../components/PlaceholderNavigationButton';
import { SubscriptionPageProps } from '../typings';

const SubscriptionRenewalError = ({ subscription }: SubscriptionPageProps) => {
  const { get } = useLoggedIn();
  const isLoggedIn = !!get();
  const destination = isLoggedIn ? '/account/subscriptions' : '/auth/register';
  return (
    <Stack sx={{ flexDirection: { xs: 'column', lg: 'row' }, gap: 5 }}>
      <Stack
        sx={{
          flex: 1,
          justifyContent: 'center',
          px: 3,
          textAlign: 'left',
        }}
      >
        <Typography sx={{ fontSize: { xs: 24, lg: 32 }, fontWeight: 600 }}>
          Não foi possível renovar o seu plano...
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, lg: 20 }, mt: { xs: 3, lg: 6 } }}>
          Ocorreu um problema com o meio de pagamento selecionado. Altere os
          seus dados na sua conta para garantir a renovação do seu plano!
        </Typography>
        <PlaceholderNavigationButton
          destination={destination}
          label="Alterar meio de pagamento"
        />
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <Box
          title={subscription?.title && subscription.title}
          sx={{
            backgroundImage: `url(${subscriptionRenewalError})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            height: { xs: 200, lg: 350 },
            width: { xs: '100%', lg: 609 },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default SubscriptionRenewalError;

import { Skeleton } from '@material-ui/core';

const ProfileFormPlaceholder = () => (
  <div>
    <Skeleton variant="rectangular" sx={{ height: 24, width: '20%' }} />
    <Skeleton variant="rectangular" sx={{ height: 36, mt: 2, width: '100%' }} />
    <Skeleton variant="rectangular" sx={{ height: 24, mt: 3, width: '20%' }} />
    <Skeleton variant="rectangular" sx={{ height: 36, mt: 2, width: '100%' }} />
    <Skeleton variant="rectangular" sx={{ height: 24, mt: 3, width: '20%' }} />
    <Skeleton variant="rectangular" sx={{ height: 36, mt: 2, width: '100%' }} />
  </div>
);

export default ProfileFormPlaceholder;

import {
  ContentCopyOutlined as CopyIcon,
  SentimentVeryDissatisfied as SadFace,
} from '@material-ui/icons';
import {
  CircularProgress,
  Divider,
  InputAdornment,
  Stack,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { Box } from '@material-ui/system';
import bolamurcha from 'assets/png/bolamurcha.png';
import goal from 'assets/png/goal.png';
import getPaymentStatus from 'modules/auth/pages/Register/services/getPaymentStatus';
import getPixData from 'modules/auth/pages/Register/services/getPixData';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { useDialog } from 'components/Dialog';
import { PlanStatus, PlanType, Plan } from 'interfaces/plan';
import FeedbackModal from './FeedbackModal';

interface PixModalProps {
  offer: Plan;
  signUpId: string;
  setUserPlans: (plans: Plan[]) => void;
  userPlans: Plan[];
  openModalAfterHiringPaidPlan: () => void;
}

const PixModal = ({
  offer,
  signUpId,
  setUserPlans,
  userPlans,
  openModalAfterHiringPaidPlan,
}: PixModalProps) => {
  const [operationStatus, setOperationStatus] = useState('awaiting');
  const { isLoading, data, error } = useQuery('secondpix', () =>
    getPixData(signUpId),
  );
  useQuery(
    'paymentStatus',
    async () => {
      const response = await getPaymentStatus(signUpId);
      setOperationStatus(response.data.message);
    },
    { refetchInterval: 10000 },
  );
  const pixData = data?.data;
  const dialog = useDialog();
  const alert = useAlert();
  const copyPixCode = async () => {
    await navigator.clipboard.writeText(pixData?.qr_code);
    alert.success('Código copiado com sucesso!');
  };
  const valueInReal = pixData?.amount / 100;
  const formatedPrice = valueInReal.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  useEffect(() => {
    if (!error) {
      if (operationStatus === 'Success') {
        const containsOnlyFreePlan = !userPlans?.find(
          (plan) =>
            plan.type === PlanType.monthly ||
            plan.type === PlanType.partner ||
            plan.type === PlanType.season,
        );
        if (containsOnlyFreePlan) {
          openModalAfterHiringPaidPlan();
        } else {
          dialog.open({
            element: (
              <FeedbackModal
                title="Seu plano foi contratado com sucesso!"
                icon={goal}
                title2="Aproveite o melhor do futebol paulista"
                description={offer.description}
                buttonText="Continuar"
                buttonColor="secondary.main"
              />
            ),
            dataTestId: 'pixFeedbackModal',
            sx: { bgcolor: 'common.white' },
          });
        }
        const newUserPlans = [
          { ...offer, status: PlanStatus.active },
          ...userPlans,
        ];
        setUserPlans(newUserPlans);
      }
    }
  }, [operationStatus]);
  useEffect(() => {
    const pixInterval = setTimeout(async () => {
      if (!error) {
        if (operationStatus !== 'Success') {
          await dialog.open({
            element: (
              <FeedbackModal
                title="Falha na contratação do seu plano!"
                icon={bolamurcha}
                title2="Ops, bateu na trave..."
                description="Como o pagamento não foi feito dentro do tempo limite, solicitamos que, caso queira, volte aos Dados de pagamento e efetue novamente a contratação do plano."
                buttonText="Tentar novamente"
                buttonColor="error.main"
              />
            ),
            sx: { bgcolor: 'common.white' },
          });
        }
      }
    }, 600000);
    return function cleanup() {
      clearTimeout(pixInterval);
    };
  }, []);
  if (error)
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: 400, width: '100%' }}
      >
        <SadFace sx={{ color: 'error.main', fontSize: 75, mb: 5, mr: 2 }} />
        <Typography
          sx={{
            color: 'grey.500',
            fontFamily: 'secondary',
            fontSize: 17,
          }}
        >
          Erro ao carregar os dados do pix...
        </Typography>
      </Stack>
    );
  if (isLoading)
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: 400, width: '100%' }}
      >
        <CircularProgress color="secondary" size="50px" sx={{ mb: 5, mr: 2 }} />
        <Typography
          sx={{ color: 'grey.500', fontFamily: 'primary', fontSize: 17 }}
        >
          Aguarde um instante...
        </Typography>
      </Stack>
    );
  return (
    <Box>
      <Typography
        sx={{
          color: 'secondary.main',
          fontFamily: 'primary',
          fontSize: 16,
          position: 'absolute',
          top: '22px',
        }}
      >
        Estamos quase lá...
      </Typography>
      <Divider sx={{ bgcolor: 'grey.100', mb: 4 }} />
      <Stack
        direction="row"
        justifyContent={{ xs: 'space-between', lg: 'flex-start' }}
      >
        <Typography
          sx={{
            color: 'grey.500',
            fontFamily: 'primary',
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          Total a pagar:
        </Typography>
        <Typography
          sx={{
            color: 'secondary.main',
            fontFamily: 'primary',
            fontSize: 14,
            fontWeight: 600,
            ml: 4,
          }}
        >
          {formatedPrice}
        </Typography>
      </Stack>
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'primary',
          fontSize: 14,
          mt: 2,
          textAlign: 'center',
          maxWidth: 327,
        }}
      >
        Acesse seu Internet Banking ou app de pagamentos, escolha PAGAR VIA PIX,
        e escaneie o QRCode abaixo para efetuar o pagamento:
      </Typography>
      <Stack alignItems="center">
        <Box
          sx={{
            backgroundImage: `url(${pixData?.qr_code_url})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: 111,
            my: 4,
            width: 114,
          }}
        />
      </Stack>
      <Typography
        sx={{ color: 'grey.400', fontFamily: 'secondary', fontSize: 14 }}
      >
        Ou copie o código para efetuar o pagamento:
      </Typography>
      <OutlinedInput
        value={pixData?.qr_code}
        fullWidth
        sx={{ mt: 4, height: 40 }}
        endAdornment={
          <InputAdornment position="end">
            <CopyIcon sx={{ cursor: 'pointer' }} onClick={copyPixCode} />
          </InputAdornment>
        }
      />
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'secondary',
          fontSize: 14,
          mt: 4,
          textAlign: 'center',
        }}
      >
        Assim que o pagamento for creditado, você terá acesso ao Paulistão Play.
        <br />
        Caso o pagamento não aconteça em 10 minutos, seu plano será
        automaticamente cancelado.
      </Typography>
    </Box>
  );
};

export default PixModal;

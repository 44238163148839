import { Button, CircularProgress, Stack, Typography } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDialog } from 'components/Dialog';
import { Plan } from 'interfaces/plan';
import { getSubscriptions } from 'modules/pages/services';
import useRegisterLocalStorage from 'utils/hooks/use-register-local-storage';
import yellowCard from '../../assets/png/yellowCard2.png';
import ChangePaymentConfirm from './ChangePaymentConfirm';

interface ReactivateSuspendedProps {
  setIsFirstAccess: (isFirstAccess: boolean) => void;
  setUserIsSuspended: (isFirstAccess: boolean) => void;
  filteredSupendedPlans: Plan[];
}

const ReactivateSuspended = ({
  setIsFirstAccess,
  setUserIsSuspended,
  filteredSupendedPlans,
}: ReactivateSuspendedProps) => {
  const { isLoading, data } = useQuery('usersubscriptions', () =>
    getSubscriptions(),
  );
  const { clearRegisterLocalStorage } = useRegisterLocalStorage();
  const dialog = useDialog();
  const navigate = useNavigate();
  const userSubscriptions = data?.data;
  const hasActivePlans = userSubscriptions?.some(
    (plan) => plan.status === 'active',
  );
  const buttonLabel = hasActivePlans
    ? 'Continuar acessando'
    : 'Sair do Paulistão';
  const logout = () => {
    clearRegisterLocalStorage();
    navigate('/');
    dialog.close();
  };
  const buttonOnclick = () => {
    if (hasActivePlans) {
      setIsFirstAccess(false);
      navigate('/home');
    } else {
      logout();
    }
  };
  const openChangePaymentModal = async () => {
    await dialog.open({
      element: (
        <ChangePaymentConfirm
          filteredSupendedPlans={filteredSupendedPlans}
          userSubscriptions={userSubscriptions}
          showPurchaseDetails
          setUserIsSuspended={setUserIsSuspended}
        />
      ),
      sx: {
        bgcolor: 'common.white',
        maxWidth: { lg: 375 },
        width: '100%',
      },
    });
  };
  const getSingleOrMultipleWarning = () => {
    if (hasActivePlans) {
      return 'Houve um problema na renovação do plano Paulistão Arquibancada. Você ainda pode continuar acessando o conteúdo dos seus outros planos';
    }
    return 'Houve um problema na cobrança da sua assinatura';
  };
  if (isLoading) return <CircularProgress />;
  return (
    <Stack
      data-testid="suspended-subscriptions-container"
      alignItems="center"
      justifyContent="center"
      sx={{ mt: { xs: 0, lg: 11 } }}
    >
      <Stack
        alignItems="center"
        justifyContent={{ xs: 'flex-start', lg: 'center' }}
        sx={{
          borderRadius: { xs: 0, lg: 3 },
          minHeight: { xs: '100%', lg: 'auto' },
          width: { xs: '100vw', lg: 1182 },
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={3}
          sx={{
            borderRadius: { xs: 0, lg: '16px 16px 0 0' },
            bgcolor: { xs: 'common.black', lg: 'grey.600' },
            height: 296,
            width: '100%',
          }}
        >
          <img src={yellowCard} alt="Cartão amarelo" style={{ height: 88 }} />
          <Typography
            data-testid="reactivate-suspended-title"
            sx={{ color: 'grey.300', fontFamily: 'primary', fontSize: 24 }}
          >
            Erro no pagamento
          </Typography>
          <Typography
            sx={{
              color: 'grey.300',
              fontFamily: 'secondary',
              fontSize: 16,
              textAlign: 'center',
              width: { xs: 331, lg: 424 },
            }}
          >
            {getSingleOrMultipleWarning()}
          </Typography>
        </Stack>
        <Stack
          gap={4}
          sx={{
            bgcolor: 'grey.500',
            borderRadius: { xs: '16px 16px 0 0', lg: '0 0 16px 16px' },
            minHeight: { xs: '50%', lg: '50%' },
            py: 9,
            width: '100%',
          }}
        >
          <Stack alignItems="center" justifyContent="center" gap={3}>
            <Button
              sx={{ height: 39, width: 291 }}
              onClick={openChangePaymentModal}
            >
              Substituir meio de pagamento
            </Button>
            <Button
              data-testid="secondary-button-when-suspended"
              sx={{ bgcolor: 'grey.600', height: 39, width: 291 }}
              onClick={() => buttonOnclick()}
            >
              {buttonLabel}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ReactivateSuspended;

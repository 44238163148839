import passwordRegex from 'modules/auth/providers/passwordRegex';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Campo obrigatório'),
  newPassword: yup
    .string()
    .required('Campo obrigatório')
    .matches(
      passwordRegex,
      'A senha deve conter 8 caracteres, letra maiúscula, letra minúscula, número e caracter especial',
    ),
  confirmNewPassword: yup
    .string()
    .required('Campo obrigatório')
    .oneOf([yup.ref('newPassword'), null], 'As senhas não coincidem.'),
});

export default validationSchema;

import { Button, Stack, TextField, Typography } from '@material-ui/core';
import { useDialog } from 'components/Dialog';
import { useFormik } from 'formik';
import { UserProfile } from 'modules/account/typings';
import { CpfMaskInput } from 'modules/auth/pages/Register/components/CreditCardStep';
import { TextMaskCustom } from 'modules/auth/pages/Register/components/EmailPhoneStep';
import { useAlert } from 'react-alert';
import updateUserData from '../services/updateUserData';
import { validationSchema } from './form';

export interface CheckData {
  id?: string;
  name: string;
  usercpf: string;
  oldcpf: string | undefined;
  phone: string;
}

interface CheckUserDataModalProps {
  userData: UserProfile;
  newSubscriptionHandler: () => void;
}

const CheckUserDataModal = ({
  newSubscriptionHandler,
  userData,
}: CheckUserDataModalProps) => {
  const alert = useAlert();
  const dialog = useDialog();
  const form = useFormik<CheckData>({
    initialValues: {
      id: userData.id,
      name: userData.name,
      usercpf: '',
      oldcpf: userData.usercpf,
      phone: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await updateUserData(values);
        newSubscriptionHandler();
      } catch {
        alert.error('Erro ao atualizar os dados');
        dialog.close();
      }
    },
  });
  return (
    <Stack alignItems="center">
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          fontWeight: 700,
        }}
      >
        Antes de continuar...
      </Typography>
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'secondary',
          fontSize: 14,
          my: 6,
          textAlign: 'center',
        }}
      >
        Para prosseguir, precisamos confirmar alguns dados cadastrais.
      </Typography>
      <Stack gap={1} sx={{ width: '100%' }}>
        <Typography
          sx={{
            color: 'grey.300',
            display: 'flex',
            fontFamily: 'secondary',
            fontSize: 14,
            textAlign: 'center',
          }}
        >
          Seu CPF:
          <Typography sx={{ color: 'error.main' }}>*</Typography>
        </Typography>
        <TextField
          placeholder="000.000.000-00"
          name="usercpf"
          value={form.values.usercpf}
          onChange={form.handleChange}
          error={!!form.errors.usercpf}
          helperText={form.errors.usercpf}
          InputProps={{ inputComponent: CpfMaskInput }}
        />
      </Stack>
      <Stack gap={1} sx={{ mt: 4, width: '100%' }}>
        <Typography
          sx={{
            color: 'grey.300',
            display: 'flex',
            fontFamily: 'secondary',
            fontSize: 14,
            textAlign: 'center',
          }}
        >
          Seu telefone celular:
          <Typography sx={{ color: 'error.main' }}>*</Typography>
        </Typography>
        <TextField
          placeholder="(00) 00000-0000"
          name="phone"
          value={form.values.phone}
          onChange={form.handleChange}
          error={!!form.errors.phone}
          helperText={form.errors.phone}
          InputProps={{ inputComponent: TextMaskCustom }}
        />
      </Stack>
      <Button
        sx={{ height: 60, mt: 8, width: '100%' }}
        onClick={() => form.handleSubmit()}
      >
        Continuar
      </Button>
    </Stack>
  );
};

export default CheckUserDataModal;

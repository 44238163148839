import { Button, Grid, Stack, Typography } from '@material-ui/core';
import { ReactQueryNames } from 'react-query/react-query-names';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useApplyingDiscount } from 'components/ApplyingDiscount';
import { Discount } from 'interfaces/Discount';
import { Plan } from 'interfaces/plan';
import { useContext, useState } from 'react';
import SubscriptionCancelationModal from 'components/SubscriptionCancelationModal';
import { getPlans } from 'modules/account/pages/Subscriptions/services/getPlans';
import getUserPlans from 'modules/account/pages/Subscriptions/services/getUserPlans';
import { useDialog } from '../../../components/Dialog';
import { deleteUserSubscription, getUserCards } from '../../account/services';
import { boots } from '../assets';
import { getRententionDiscounts } from '../services';
import { SubscriptionPageProps } from '../typings';
import RetentionDetails from './RententionDetails';
import RetentionDiscountCard from './RetentionDiscountCard';
import RetentionDiscountCardPlaceholderList from './RetentionDiscountCardPlaceholderList';
import Context from '../../../Context';

const CancellationHold = ({ subscription }: SubscriptionPageProps) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [userPlans, setUserPlans] = useState<Plan[]>([]);
  const { isLoading: isLoadingPlans } = useQuery('plans', getPlans, {
    onSuccess: ({ data }) => setPlans(data as Plan[]),
  });
  const { isLoading: isLoadingSubscriptions } = useQuery(
    'userPlans',
    getUserPlans,
    { onSuccess: ({ data }) => setUserPlans(data as Plan[]) },
  );
  const [applyingDiscount, setApplyingDiscount] = useApplyingDiscount();
  const { profile, isLoadingUser } = useContext(Context);
  const { data: card } = useQuery(ReactQueryNames.USER_CARDS, getUserCards);
  const { data: rententionDiscounts } = useQuery(
    [ReactQueryNames.RENTENTION_DISCOUNTS, subscription?.id],
    () => getRententionDiscounts(subscription?.id),
  );
  const retentionDiscountsData = rententionDiscounts?.data;
  const retentionDiscounts = retentionDiscountsData?.filter(
    (discount: Discount, index: number) => (index < 3 ? discount : null),
  );
  const dialog = useDialog();
  const navigate = useNavigate();
  const navigateToSuccessHandler = () => navigate('/account/subscriptions');
  const addPlanToDiscountSet = () => {
    setApplyingDiscount([
      ...applyingDiscount,
      { id: subscription?.id, netValue: subscription?.netValue },
    ]);
  };
  const openDiscountRetentionModal = async (discount: Discount) => {
    await dialog.open({
      element: (
        <RetentionDetails
          card={card}
          currentDiscount={discount}
          navigate={navigateToSuccessHandler}
          profile={profile}
          subscription={subscription}
          addPlanToDiscountSet={addPlanToDiscountSet}
        />
      ),
      sx: { bgcolor: 'common.white', width: { xs: '100%', lg: 375 } },
    });
  };
  const deleteSubscriptionHandler = async () => {
    if (subscription?.id) {
      await deleteUserSubscription(subscription.userPlanId);
      navigate('/account/subscriptions');
      dialog.close();
      await dialog.open({
        element: (
          <SubscriptionCancelationModal
            deleteSubscription={deleteUserSubscription}
            plans={plans}
            subscription={subscription}
            userPlans={userPlans}
          />
        ),
        sx: {
          bgcolor: 'common.white',
          maxWidth: 375,
          overflowY: 'hidden',
          width: '100%',
        },
      });
      return;
    }
    navigate('/404');
  };
  return (
    <Stack
      data-testid="retentionDiscountsContent"
      alignItems="center"
      sx={{
        borderRadius: 3,
        bgcolor: 'grey.500',
        minHeight: { xs: '100vh', lg: 'auto' },
        width: { xs: '100vw', lg: 1182 },
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        gap={3}
        sx={{
          borderRadius: '16px 16px 0 0',
          bgcolor: { xs: 'common.black', lg: 'grey.600' },
          height: 296,
          width: '100%',
        }}
      >
        <img
          src={boots}
          alt="Cartão amarelo"
          style={{ height: 88, width: 82 }}
        />
        <Typography
          sx={{ color: 'grey.300', fontSize: 24, fontWeight: 'bold' }}
        >
          Não nos abandone!
        </Typography>
        <Typography sx={{ color: 'grey.300', fontSize: 16, width: 634 }}>
          A vida não é a mesma sem futebol.
          <br /> Escolha um desconto e fica com a gente!
        </Typography>
      </Stack>
      <Stack
        gap={4}
        sx={{ minHeight: '50%', py: 5, width: { xs: '100%', lg: '85%' } }}
      >
        <Stack
          alignItems={{ xs: 'center', lg: 'flex-start' }}
          sx={{ width: '100%', textAlign: 'center' }}
        >
          <Typography sx={{ width: { xs: 263, lg: 'auto' } }}>
            Continue vendo a bola rolar com esses descontos...
          </Typography>
        </Stack>
        <Grid
          container
          alignItems="center"
          direction={{ xs: 'column', lg: 'row' }}
          flexWrap={{ lg: 'wrap' }}
          justifyContent={{ lg: 'space-between' }}
          spacing={5}
        >
          {isLoadingUser || isLoadingPlans || isLoadingSubscriptions ? (
            <RetentionDiscountCardPlaceholderList />
          ) : (
            retentionDiscounts?.map((discount) => (
              <Grid item xs={12} lg={4}>
                <RetentionDiscountCard
                  rententionDiscount={discount}
                  onclick={openDiscountRetentionModal}
                />
              </Grid>
            ))
          )}
        </Grid>
        <Stack
          alignItems={{ xs: 'center', lg: 'flex-end' }}
          sx={{ p: 6, width: '100%' }}
        >
          <Button
            data-testid="cancelButton"
            sx={{
              bgcolor: 'grey.600',
              mx: { xs: 4, lg: 0 },
              width: { xs: 280, lg: 'auto' },
            }}
            onClick={deleteSubscriptionHandler}
          >
            Cancelar plano
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CancellationHold;

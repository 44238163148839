/* eslint-disable react/require-default-props */
import { Stack } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface HalfContainerProps {
  children?: ReactNode;
}

const HalfContainer = ({ children }: HalfContainerProps) => (
  <Stack
    sx={{
      position: 'relative',
      py: { xs: 5, lg: 0 },
      gap: { xs: 2, lg: 5 },
      width: { xs: '100%', lg: '50%' },
      '& iframe': { height: { xs: 200, lg: 350 } }
    }}
  >
    {children}
  </Stack>
);

export default HalfContainer;

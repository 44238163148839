import { Stack, Typography } from '@material-ui/core';
import { Markup } from 'interweave';
import React from 'react';

interface DynamicTextProps {
  color: string;
  fontFamily: string;
  fontSize: number;
  text: string;
}

const DynamicText = ({
  color,
  fontFamily,
  fontSize,
  text,
}: DynamicTextProps) => (
  <Stack
    sx={{ justifyContent: 'flex-start', position: 'relative' }}
  >
    <Typography
      sx={{
        color,
        fontFamily,
        fontSize: {
          xs: fontSize * 0.8,
          lg: fontSize,
        },
        lineHeight: 1.5,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        // TODO: Find a better solution for rich text editor
        '& .ql-align-right': { textAlign: 'right' },
        '& .ql-align-center': { textAlign: 'center' },
        '& span > p': { margin: 0 },
      }}
    >
      <Markup content={text} />
    </Typography>
  </Stack>
);

export default DynamicText;

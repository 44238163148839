import LocalStorageService from 'services/local-storage.service';
import { LocalStorageKeys } from 'utils/localStorageKeys';

const localStorageService = new LocalStorageService();

const useApplyingPlansLocalStorage = () => {
  const get = () => localStorageService.get(LocalStorageKeys.APPLYING_DISCOUNT);

  const set = (value: string) =>
    localStorageService.set(LocalStorageKeys.APPLYING_DISCOUNT, value);

  const clear = () =>
    localStorageService.delete(LocalStorageKeys.APPLYING_DISCOUNT);

  return {
    get,
    set,
    clear,
  };
};

export default useApplyingPlansLocalStorage;

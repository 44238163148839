import { Stack, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { ConsumableContent } from '../../../interfaces/Content';
import { formatDate } from '../../../utils/date';
import { getDurationFromSecs } from '../../../utils/time';

interface DescriptionProps {
  content: Partial<ConsumableContent>;
}

// format date if not undefined
const formatDateIfNotUndefined = (date: Date | undefined) => {
  if (date) {
    return formatDate(date, "dd 'de' MMM 'de' yyyy',' HH'h'mm");
  }
  return '';
};

export const DescriptionTitle = ({ title }: { title: string }) => (
  <Typography
    sx={{
      color: 'common.white',
      fontFamily: 'primary',
      fontSize: { xs: 20, lg: 24 },
    }}
  >
    {title}
  </Typography>
);

const Description = ({ content }: DescriptionProps) => (
  <Stack>
    <Grid sx={{ mb: 6 }}>
      <DescriptionTitle title={content?.title || ''} />
    </Grid>
    <Grid
      sx={{
        mb: 6,
      }}
    >
      <Typography
        sx={{
          color: 'grey.100',
          fontFamily: 'secondary',
          fontSize: 16,
          width: { xs: '100%', lg: 701 },
        }}
      >
        {content.description}
      </Typography>
    </Grid>
    <Typography
      sx={{ color: 'grey.400', fontFamily: 'secondary', fontSize: 14 }}
    >
      Publicado:{' '}
      {formatDateIfNotUndefined(content.scheduleDate) ||
        formatDateIfNotUndefined(content.createdAt)}
    </Typography>
    <Typography
      sx={{
        color: 'grey.400',
        fontFamily: 'secondary',
        fontSize: 14,
        fontWeight: 400,
      }}
    >
      Duração: {getDurationFromSecs(content.sourceDuration!)}
    </Typography>
  </Stack>
);

export default Description;

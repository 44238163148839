import {
  Chip,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import {
  Cancel as CancelIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { useContext } from 'react';
import formatResultCountMessage from '../utils/formatResultCountMessage';
import Context from '../Context';
import EmptyState from './EmptyState';

const Search = () => {
  const {
    filteredSections,
    sections,
    selectedSection,
    setSelectedSection,
    term,
    setTerm,
  } = useContext(Context);
  const unsetSubSectionHandler = () => setSelectedSection(null);
  const setTermHandler = (evt: any) => setTerm!(evt.target.value);
  const unsetTermHandler = () => setTerm!('');
  const getIcon = () =>
    selectedSection ? (
      <Chip
        variant="outlined"
        label={selectedSection.title}
        sx={{
          bgcolor: 'grey.500',
          border: 'none',
          color: 'grey.100',
          height: 32,
          mr: 2,
        }}
        deleteIcon={<CloseIcon sx={{ path: { color: 'grey.400' } }} />}
        onDelete={unsetSubSectionHandler}
      />
    ) : (
      term && (
        <CancelIcon
          sx={{ cursor: 'pointer', fontSize: 32, path: { color: 'grey.400' } }}
          onClick={unsetTermHandler}
        />
      )
    );
  const sectionsAreEmpty = sections.length === 0;
  return (
    <>
      <OutlinedInput
        placeholder="Faça sua busca aqui"
        value={term}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'grey.100' }} />
          </InputAdornment>
        }
        endAdornment={getIcon()}
        sx={{
          bgcolor: 'grey.600',
          border: 'none',
          borderRadius: 25,
          color: 'grey.300',
          height: 48,
          mt: 5,
          pl: 4,
          pr: 2,
          py: 3,
          width: '100%',
          '&.Mui-focused .MuiInputBase-input': {
            bgcolor: 'transparent',
            color: 'grey.200',
          },
        }}
        onChange={setTermHandler}
      />
      <Typography
        sx={{
          color: 'grey.300',
          fontSize: 12,
          mb: {
            xs: 4,
            lg: 5,
          },
          mt: {
            xs: 5,
            lg: 5,
          },
        }}
      >
        {formatResultCountMessage(filteredSections.length, !!selectedSection)}
      </Typography>
      {sectionsAreEmpty && (
        <EmptyState
          title="O que você procura?"
          description="Os resultados da sua pesquisa vão aparecer aqui."
          sx={{ mt: 11 }}
        />
      )}
    </>
  );
};

export default Search;

/* eslint-disable import/no-extraneous-dependencies */
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import {
  Alert,
  CircularProgress,
  CssBaseline,
  Theme,
  ThemeProvider,
} from '@material-ui/core';
import { lazy, Suspense, useState } from 'react';
import { positions, Provider as AlertProvider } from 'react-alert';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'simplebar/dist/simplebar.min.css';
import 'video-react/dist/video-react.css';
import AccountCancelation from 'components/AccountCancelation';
import ApplyingDiscount from 'components/ApplyingDiscount';
import IsLoggedIn from 'components/IsLoggedIn';
import PushWooshProvider from 'components/PushWooshProvider';
import CheckTvCode from 'modules/auth/pages/CheckTvCode/CheckTvCodeTemplate';
import AppTemplate from './components/AppTemplate';
import { DialogProvider } from './components/Dialog';
import PrivateRoute from './components/PrivateRoute';
import Auth from './modules/auth';
import ErrorPage from './modules/pages/placeholders/ErrorPage';
import PageNotFound from './modules/pages/placeholders/PageNotFound';
import Partners from './modules/public-home/HelpPages/Partners';
import environment from './environment.config';
import './styles.css';

const appSlug = environment.AppSlug;

const PublicHome = lazy(
  () => import(`./modules/public-home/${appSlug}/PublicHome`),
);
const UseTerms = lazy(
  () => import(`./modules/public-home/HelpPages/${appSlug}/UseTerms`),
);
const PrivacyPolicy = lazy(
  () => import(`./modules/public-home/HelpPages/${appSlug}/PrivacyPolicy`),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...environment.ReactQuery,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const gtmParams = {
  id: environment.GoogleGTM.id,
  dataLayer: {
    originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
  },
};

SwiperCore.use([Autoplay, Navigation, Pagination, Virtual]);

// TODO: add typings
const AlertTemplate = ({ message, close, style, options }: any) => (
  <Alert severity={options.type} style={style} onClose={close}>
    {message}
  </Alert>
);

const App = () => {
  const [theme, setTheme] = useState<Theme>();
  import(`./components/Theme/${appSlug}`).then(
    (importedElement: { default: Theme }) => setTheme(importedElement.default),
  );
  if (!theme) return null;
  return (
    <Suspense fallback={<CircularProgress />}>
      <GTMProvider state={gtmParams}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AlertProvider
            template={AlertTemplate}
            position={positions.TOP_CENTER}
            timeout={2500}
            containerStyle={{ zIndex: theme.zIndex.tooltip }}
          >
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <DialogProvider>
                  <AccountCancelation>
                    <ApplyingDiscount>
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <PushWooshProvider />
                        <Routes>
                          <IsLoggedIn path="/" element={<PublicHome />} />
                          <Route
                            path="/codigo*"
                            element={
                              <PrivateRoute redirectTo="/auth/login">
                                <CheckTvCode />
                              </PrivateRoute>
                            }
                          />
                          <IsLoggedIn path="/partners" element={<Partners />} />
                          <Route path="/termos-de-uso" element={<UseTerms />} />
                          <Route
                            path="/politica-de-privacidade"
                            element={<PrivacyPolicy />}
                          />
                          <IsLoggedIn path="/auth*" element={<Auth />} />
                          <Route path="/404" element={<PageNotFound />} />
                          <Route
                            path="/*"
                            element={
                              <PrivateRoute redirectTo="/auth/login">
                                <AppTemplate />
                              </PrivateRoute>
                            }
                          />
                          <Route path="*" element={<Navigate to="/home" />} />
                        </Routes>
                      </ErrorBoundary>
                    </ApplyingDiscount>
                  </AccountCancelation>
                </DialogProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </AlertProvider>
        </ThemeProvider>
      </GTMProvider>
    </Suspense>
  );
};

export default App;

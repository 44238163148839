import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Slide from 'components/Slide';
import { useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import { VideoContentCard } from './VideoContentCard';

interface SectionContentLineProps {
  contentData: any;
}

const SectionContentLine = ({ contentData }: SectionContentLineProps) => {
  const slideRef = useRef<any>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { subSections, subSectionsContent } = contentData;
  const getSubSectionContents = (id: string) => subSectionsContent[id];
  const slidePerGroupsCalc = isMobile
    ? (window.innerWidth - 12) / (220 + 8)
    : (window.innerWidth - 95 - 8) / (224 + 16);
  return (
    <>
      {subSections?.map((eachSubSection: any) => (
        <>
          <Grid
            key={`subsection-section-title-${eachSubSection.id}`}
            container
            sx={{ zIndex: 200 }}
          >
            <Typography
              sx={{
                fontFamily: 'primary',
                fontSize: { xs: 20, lg: 26 },
                color: 'common.white',
                marginBottom: '26px',
                ml: { xs: 0, lg: '95px' },
              }}
            >
              {eachSubSection.title}
            </Typography>
          </Grid>
          <Slide
            key={`subsection-section-content-line-${eachSubSection.id}`}
            ref={slideRef}
            data-testid="sections-menu"
            sliderProps={{
              slidesPerView: slidePerGroupsCalc,
              slidesPerGroup: isMobile ? 1 : 4,
              spaceBetween: isMobile ? 8 : 16,
              style: {
                paddingLeft: !isMobile ? '95px' : 0,
              },
            }}
            style={{
              marginBottom: isMobile ? '32px' : '40px',
              width: '100%',
            }}
          >
            {getSubSectionContents(eachSubSection.id)?.map(
              (eachContent: any) => (
                <SwiperSlide key={`section-content-line-${eachContent.id}`}>
                  <VideoContentCard data={eachContent} />
                </SwiperSlide>
              ),
            )}
          </Slide>
        </>
      ))}
    </>
  );
};

export default SectionContentLine;

import { Chip, Divider, Grid, Stack, Typography } from '@material-ui/core';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { ValueType } from 'interfaces/Discount';
import { BillingHistory, DealStatus } from 'interfaces/BillingHistory';
import { formatDate } from 'utils/date';
import { getFriendlyNameToDealStatus } from '../utils/getFriendlyNameToDealStatus';
import getPaymentMethodLabel from '../utils/getPaymentMethodLabel';
import formatPriceByValueType from '../utils/formatPriceByValueType';

type BillingHistoryCardProps = {
  billingHistory: BillingHistory;
  isLast: boolean;
};

const BillingHistoryCard = ({
  billingHistory,
  isLast,
}: BillingHistoryCardProps) => {
  const appliedDate = formatDate(billingHistory.createdAt, 'dd LLL yyyy');
  const hasDiscountApplied = billingHistory?.discounts?.length > 0;
  const isntPlanCancellation = !!billingHistory.dealStatus;
  const isPlanRenewal = billingHistory.dealStatus === DealStatus.renewal;
  return (
    <TimelineItem
      sx={{
        mb: { xs: 6, lg: 3 },
        minHeight: hasDiscountApplied ? 75 : 50,
        '::before': { display: 'none' },
      }}
    >
      <TimelineSeparator
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginRight: { xs: 4, lg: 6 },
          minWidth: { xs: 'auto', lg: 75 },
        }}
      >
        <Typography
          sx={{
            color: isPlanRenewal ? 'grey.400' : 'grey.200',
            fontFamily: 'secondary',
            fontSize: 12,
            textAlign: 'center',
            textTransform: 'uppercase',
            width: { xs: 46, lg: 'auto' },
          }}
        >
          {appliedDate}
        </Typography>
        {!isLast && (
          <>
            <TimelineConnector
              sx={{
                backgroundColor: 'grey.500',
                height: hasDiscountApplied ? 15 : 10,
                mt: 1,
              }}
            />
            <TimelineDot
              sx={{
                alignSelf: 'center',
                backgroundColor: 'grey.400',
                border: 0,
                height: '4px !important',
                my: 1,
                p: 0,
                width: '4px !important',
              }}
            />
            <TimelineConnector
              sx={{
                backgroundColor: 'grey.500',
                height: hasDiscountApplied ? 15 : 10,
              }}
            />
          </>
        )}
      </TimelineSeparator>
      <TimelineContent sx={{ height: '100%', p: 0 }}>
        <Stack
          alignItems={{ xs: 'flex-start', lg: 'center' }}
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: isPlanRenewal ? 'grey.400' : 'grey.200',
              fontFamily: 'secondary',
              fontSize: 14,
              fontWeight: 400,
              textTransform: 'uppercase',
            }}
          >
            {getFriendlyNameToDealStatus(billingHistory.dealStatus)}{' '}
            <b className="text-bold">{billingHistory.plan?.title}</b>
          </Typography>
          {isntPlanCancellation && (
            <Typography
              sx={{
                color: hasDiscountApplied ? 'grey.400' : 'grey.200',
                fontSize: hasDiscountApplied ? 10 : 14,
                fontFamily: 'secondary',
                textDecorationLine: hasDiscountApplied
                  ? 'line-through'
                  : 'none',
              }}
            >
              {formatPriceByValueType(
                billingHistory.plan.price,
                ValueType.value,
              )}
            </Typography>
          )}
        </Stack>
        {(hasDiscountApplied ||
          billingHistory?.paymentHistory?.paymentMethod) && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ mt: 3 }}
          >
            <Grid container alignItems="center" spacing={{ xs: 2, lg: 4 }}>
              {hasDiscountApplied && (
                <>
                  <Grid item>
                    <Typography
                      sx={{
                        color: 'grey.200',
                        fontFamily: 'secondary',
                        fontSize: 10,
                      }}
                    >
                      DESCONTO APLICADO
                    </Typography>
                  </Grid>
                  {billingHistory.discounts.map((discount) => (
                    <Grid item>
                      <Chip
                        key={discount.id}
                        color="default"
                        variant="outlined"
                        size="small"
                        label={formatPriceByValueType(
                          discount.price || 0,
                          discount.valueType,
                        )}
                        sx={{ fontFamily: 'secondary' }}
                      />
                    </Grid>
                  ))}
                </>
              )}
              {billingHistory?.paymentHistory?.paymentMethod && (
                <Grid item>
                  <Chip
                    color="default"
                    variant="outlined"
                    size="small"
                    label={getPaymentMethodLabel(
                      billingHistory!.paymentHistory!.paymentMethod,
                    )}
                    sx={{ fontFamily: 'secondary' }}
                  />
                </Grid>
              )}
            </Grid>
            {hasDiscountApplied && (
              <Stack alignItems="center" direction="row">
                <Typography
                  sx={{
                    color: 'grey.200',
                    fontFamily: 'secondary',
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {formatPriceByValueType(
                    billingHistory.netValue,
                    ValueType.value,
                  )}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
        {!isLast && (
          <Divider
            sx={{
              bgcolor: 'grey.500',
              mt: { xs: 4, lg: 5 },
            }}
          />
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export default BillingHistoryCard;

import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const AuthTemplate = lazy(() => import('modules/auth/components/AuthTemplate'));
const CheckTvCode = lazy(() => import('./components/CheckTvCode'));
const SuccessfulCheckingTV = lazy(
  () => import('./components/SuccessfulCheckingTV'),
);

const CheckTvCodeTemplate = () => (
  <Suspense fallback={<CircularProgress />}>
    <Routes>
      <Route element={<AuthTemplate />}>
        <Route path=":code" element={<CheckTvCode />} />
        <Route path="" element={<CheckTvCode />} />
        <Route path="/success" element={<SuccessfulCheckingTV />} />
      </Route>
    </Routes>
  </Suspense>
);

export default CheckTvCodeTemplate;

// eslint-disable-next-line import/prefer-default-export
export const isValidCpf = (cpf: string) => {
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
    return false;
  }
  const cpfChars = cpf.split('');
  const validator = cpfChars
    .filter((value, index, array) => index >= array.length - 2 && value)
    .map((value) => +value);
  const toValidate = (pop: any) =>
    cpfChars
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el);
  const rest = (count: any, pop: any) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
};

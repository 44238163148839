import { Button, Stack, Typography } from '@material-ui/core';
import { CreditCard as Card } from '@material-ui/icons';
import { useDialog } from 'components/Dialog';
import { Plan } from 'interfaces/plan';
import ShowPurchaseDetails from './components/ShowPurchaseDetails';
import PaymentForm from './PaymentForm';

interface FeedbackModalProps {
  userSubscriptions?: Plan[];
  filteredSupendedPlans?: Plan[];
  showPurchaseDetails?: boolean;
  setUserIsSuspended?: (isFirstAccess: boolean) => void;
}

const ChangePaymentConfirm = ({
  filteredSupendedPlans,
  userSubscriptions,
  showPurchaseDetails = false,
  setUserIsSuspended,
}: FeedbackModalProps) => {
  const dialog = useDialog();
  const openChangePaymentModal = () => {
    dialog.open({
      element: (
        <PaymentForm
          userSubscriptions={userSubscriptions}
          setUserIsSuspended={setUserIsSuspended}
        />
      ),
      isBlocked: true,
      sx: {
        bgcolor: 'common.white',
        maxWidth: { xs: '100%', lg: 375 },
        overflowY: 'hidden',
        width: '100%',
      },
    });
  };
  const openShowPurchaseDetails = async () => {
    if (showPurchaseDetails) {
      const confirm = await dialog.open({
        element: (
          <ShowPurchaseDetails
            filteredSupendedPlans={filteredSupendedPlans || []}
          />
        ),
        closable: false,
        sx: {
          bgcolor: 'common.white',
          minWidth: 320,
          position: 'unset',
          width: { xs: '100%', lg: 327 },
        },
      });
      if (confirm) {
        openChangePaymentModal();
      }
      return;
    }
    openChangePaymentModal();
  };
  return (
    <Stack alignItems="center" gap={3}>
      <Card sx={{ color: 'grey.300', fontSize: '60px' }} />
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          fontWeight: 700,
        }}
      >
        Substituir meio de pagamento?
      </Typography>
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'secondary',
          fontSize: 14,
          fontWeight: 400,
          textAlign: 'center',
        }}
      >
        O meio de pagamento será substituído em todas as assinaturas caso haja
        mais de um plano contratado. A próxima cobrança será realizada neste
        meio.
      </Typography>
      <Button
        data-testid="change-payment-confirm-button"
        fullWidth
        sx={{ py: 5 }}
        onClick={openShowPurchaseDetails}
      >
        Substituir meio de pagamento
      </Button>
    </Stack>
  );
};

export default ChangePaymentConfirm;

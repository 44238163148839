import { useEffect, useRef, useState } from 'react';

type UseDynamicImportReturn = {
  loading: boolean;
  error: boolean;
  element: string;
}

const useDynamicImport = (path: string): UseDynamicImportReturn => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const element = useRef<string>('');
  useEffect(() => {
    const importElement = async () => {
      setLoading(true);
      try {
        const importedElement = await import(`../../assets/${path}`);
        element.current = importedElement.default;
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    importElement();
  }, [path]);
  return { loading, error, element: element.current };
};

export default useDynamicImport;

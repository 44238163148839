import { createContext } from 'react';

interface IContext {
  logo: string;
  navigateTo: (path: string) => void;
}

const Context = createContext<IContext>({
  logo: '',
  navigateTo: () => { },
});

export default Context;

import * as yup from 'yup';

const requiredError = 'Campo obrigatório';

const validationSchema = yup.object().shape({
  name: yup.string().required(requiredError),
  creditCard: yup
    .string()
    .required(requiredError)
    .matches(/^(\d{4}\s){3}\d{4}$/, 'Cartão inválido'),
  cvv: yup
    .string()
    .required(requiredError)
    .matches(/^\d{3}$/, 'CVV inválido'),
  expirationDate: yup
    .string()
    .required(requiredError)
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Data inválida'),
  cpf: yup
    .string()
    .required(requiredError)
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido'),
});

export default validationSchema;

import { Grid, Stack, Typography } from '@material-ui/core';

interface BodyItemProps {
  title: number;
  isFirst?: boolean;
}

const BodyItem = ({ title, isFirst }: BodyItemProps) => (
  <Grid item xs={1}>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      sx={{
        bgcolor: isFirst ? 'grey.600' : 'unset',
        height: '100%',
        m: '0 auto',
        width: isFirst ? 53 : 'auto',
      }}
    >
      <Typography
        sx={{
          color: 'white',
          fontSize: 14,
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
    </Stack>
  </Grid>
);

export default BodyItem;

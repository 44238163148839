/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  Typography,
} from '@material-ui/core';
import {
  GroupWork as DownloadIcon,
  Star as StarIcon,
  Tv as TvIcon,
} from '@material-ui/icons';
import React, { ComponentType, memo } from 'react';
import { Notification, NotificationType } from '../../interfaces/notification';
import { getFriendlyDate } from '../../utils/date';

interface NotificationListItemProps {
  notification: Notification;
  secondaryTextColor?: string;
  IconProps?: SvgIconProps;
}

// TODO: some text should be replaced by content metadata
const notificationMapper: Record<NotificationType, string> = {
  [NotificationType.contentStartingSoon]:
    'São Paulo x Bragantino começa em 45 minutos. Prepare a sua pipoca',
  [NotificationType.planRenewal]: 'Seu plano foi renovado',
  [NotificationType.recordingReady]:
    'A gravação de Santos x Ituano de 22 de Junho está pronta para assistir',
};

const iconMapper: Record<NotificationType, ComponentType<SvgIconProps>> = {
  [NotificationType.contentStartingSoon]: TvIcon,
  [NotificationType.planRenewal]: StarIcon,
  [NotificationType.recordingReady]: DownloadIcon,
};

const NotificationListItem = memo(
  ({
    notification,
    secondaryTextColor = 'grey.400',
    IconProps,
  }: NotificationListItemProps) => {
    const Icon = iconMapper[notification.type];
    return (
      <ListItem
        disableGutters
        divider
        secondaryAction={
          <Typography
            sx={{
              color: secondaryTextColor,
              fontSize: 12,
              fontWeight: 600,
              pr: 5,
            }}
          >
            {getFriendlyDate(notification.createdAt)}
          </Typography>
        }
        sx={{ borderColor: 'grey.500', height: { xs: 56, lg: 64 }, px: 5 }}
      >
        <ListItemIcon sx={{ minWidth: 0, mr: 4 }}>
          <Icon
            sx={{ color: 'secondary.main', height: 32, width: 32 }}
            {...IconProps}
          />
        </ListItemIcon>
        <ListItemText
          primary={notificationMapper[notification.type]}
          primaryTypographyProps={{ fontSize: 12 }}
          sx={{ pr: 10 }}
        />
      </ListItem>
    );
  },
);

export default NotificationListItem;

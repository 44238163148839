import { SxProps, Theme } from '@material-ui/system';

const styles: Record<any, SxProps<Theme>> = {
  header: {
    color: 'grey.100',
    fontFamily: 'primary',
    fontSize: { xs: 20, lg: 16 },
    fontWeight: 600,
  },
  form: {
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    width: { xs: '100%', lg: '50%' },
  },
  label: {
    color: 'grey.300',
    fontFamily: 'secondary',
    fontSize: 14,
    fontWeight: 400,
  },
  input: {
    bgcolor: 'grey.600',
    borderRadius: 1,
    height: 42,
    mt: 2,
    px: 2,
    py: 2,
    '.MuiOutlinedInput-input': { color: 'grey.300' },
    input: {
      '&::placeholder': { color: 'grey.300' },
    },
  },
  inputError: { color: 'error.main', fontSize: 12, mt: 1 },
  submitButton: {
    fontSize: { xs: 14, lg: 16 },
    mb: { xs: 4, lg: 0 },
    my: { xs: 8, lg: 0 },
    width: { xs: '100%', lg: 209 },
  },
};

export default styles;

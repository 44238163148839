import { Swiper, SwiperSlide } from 'swiper/react';
import SubscriptionCardPlaceholder from './SubscriptionCardPlaceholder';

const SubscriptionsListPlaceholder = () => (
  <Swiper
    navigation
    slidesPerView="auto"
    spaceBetween={16}
    style={{ marginTop: 16, width: '100%' }}
  >
    {[...Array(3)].map(() => (
      <SwiperSlide>
        <SubscriptionCardPlaceholder />
      </SwiperSlide>
    ))}
  </Swiper>
);

export default SubscriptionsListPlaceholder;

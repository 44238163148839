import React from 'react';
import { Grid, Popover, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface Props {
  anchorEl: any;
  children: React.ReactNode;
  onClose: () => void;
  headerText?: string;
}

export const ApplicationMenu = ({
  anchorEl,
  children,
  onClose,
  headerText,
}: Props) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    PaperProps={{
      sx: {
        backdropFilter: 'blur(24px)',
        background: 'rgba(30, 30, 30, 0.85)',
        borderRadius: { xs: 0, lg: 2 },
        height: { xs: 'calc(100vh - 72px)', lg: 391 },
        top: { xs: '72px !important', lg: 'unset' },
        right: { xs: '0 !important', lg: 'unset' },
        width: { xs: 'auto', lg: 243 },
        zIndex: 2500,
      },
    }}
  >
    <Grid
      container
      justifyItems="center"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        px: 4,
        pt: 3,
      }}
    >
      <Grid>
        <Typography variant="h5" sx={{ color: 'white' }}>
          {headerText}
        </Typography>
      </Grid>
      <Close sx={{ cursor: 'pointer', color: 'white' }} onClick={onClose} />
    </Grid>
    {children}
  </Popover>
);

import LocalStorageService from 'services/local-storage.service';
import { LocalStorageKeys } from 'utils/localStorageKeys';

const localStorageService = new LocalStorageService();

const useLoggedIn = () => {
  const get = () => localStorageService.get(LocalStorageKeys.LOGGED_IN);

  const set = (value: string) =>
    localStorageService.set(LocalStorageKeys.LOGGED_IN, value);

  const clear = () => localStorageService.delete(LocalStorageKeys.LOGGED_IN);

  return {
    get,
    set,
    clear,
  };
};

export default useLoggedIn;

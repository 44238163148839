/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Chip,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { ReactQueryNames } from 'react-query/react-query-names';
import { useSearchParams } from 'react-router-dom';
import Slide from '../../components/Slide';
import {
  Section,
  SortableSectionContent,
  SortableSubSectionContent,
  SubSection,
} from '../../interfaces/section';
import SectionsListSkeleton from '../../components/SectionsListSkeleton';
import { compareTerms } from '../../utils/string';
import ContentCard from './components/ContentCard';
import EmptyState from './components/EmptyState';
import SearchBox from './components/SearchBox';
import SectionView from './components/SectionView';
import Context from './Context';
import { getSections } from './services';

interface IElementProps {
  [key: string]: unknown;
}

const Search = () => {
  const [searchParams] = useSearchParams();
  const [term, setTerm] = useState<string>(
    (searchParams.get('term') as string) || '',
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const { isLoading, data } = useQuery(ReactQueryNames.SECTIONS, getSections);
  if (isLoading) {
    return (
      <SectionsListSkeleton
        data-testid="sections-list-skeleton"
        sx={{ mt: 5 }}
      />
    );
  }
  const filterTerms = (sections: Section[]) => {
    const newFilteredSections = [];
    for (const section of sections) {
      const sortableSectionContents = section.sortableSectionContents.filter(
        (sortableContent: SortableSectionContent) =>
          compareTerms(sortableContent.content.title, term),
      );
      if (sortableSectionContents.length > 0) {
        newFilteredSections.push({ ...section, sortableSectionContents });
      }
    }
    return newFilteredSections;
  };
  const filteredSections = selectedSection
    ? filterTerms([selectedSection])
    : filterTerms(data!.data);
  const hasTermWithoutSections = term && filteredSections.length === 0;

  const rowSpacing = {
    px: {
      xs: 4,
      lg: 11,
    },
  };
  return (
    <Context.Provider
      value={{
        filteredSections,
        sections: data!.data,
        term,
        setTerm,
        selectedSection,
        setSelectedSection,
      }}
    >
      <Box sx={rowSpacing}>
        <SearchBox />
      </Box>
      {hasTermWithoutSections ? (
        <EmptyState
          title="Não encontramos o que você pesquisou"
          description="Tente usar outros termos para buscar este conteúdo."
          sx={{ mt: 11 }}
        />
      ) : (
        filteredSections.map((section) => (
          <Box
            data-testid="sections-list"
            key={section.title}
            sx={{ mb: 6, position: 'relative' }}
          >
            {!selectedSection && (
              <Typography
                data-testid="watch-all"
                sx={{
                  color: {
                    xs: 'grey.400',
                    lg: 'grey.300',
                  },
                  cursor: 'pointer',
                  fontSize: {
                    xs: 14,
                    lg: 16,
                  },
                  fontWeight: 600,
                  position: 'absolute',
                  right: 50,
                }}
                onClick={() => setSelectedSection(section)}
              >
                Ver tudo
              </Typography>
            )}
            <SectionView
              section={section}
              renderItem={({ content }: SortableSubSectionContent) => (
                <SwiperSlide>
                  <ContentCard content={content} />
                </SwiperSlide>
              )}
              renderTag={(
                tag: SubSection['title'],
                tagProps: IElementProps,
              ) => (
                <SwiperSlide>
                  <Chip
                    sx={{ bgcolor: 'grey.500' }}
                    label={
                      <Typography
                        sx={{
                          color: 'grey.200',
                          fontSize: 14,
                          py: 2,
                          px: 4,
                        }}
                      >
                        {!tag ? 'Todos' : tag}
                      </Typography>
                    }
                    {...tagProps}
                  />
                </SwiperSlide>
              )}
              selectedSection={!!selectedSection}
              ContentWrapper={Slide}
              ContentWrapperProps={{
                style: {
                  padding: isMobile ? '0 16px' : '0 92px',
                },
                'data-testid': 'swiper-contents',
                sliderProps: { slidesPerView: 'auto', spaceBetween: 16 },
              }}
              SelectedTagProps={{
                sx: { bgcolor: 'secondary.main' },
              }}
              TagsWrapper={Slide}
              TagsWrapperProps={{
                'data-testid': 'swiper-sub-sections',
                sliderProps: { slidesPerView: 'auto', spaceBetween: 16 },
                style: { marginBottom: 36 },
              }}
            />
            <Divider
              sx={{
                bgcolor: 'grey.600',
                display: { xs: 'none', lg: 'block' },
                mt: 6,
              }}
            />
          </Box>
        ))
      )}
    </Context.Provider>
  );
};

export default Search;

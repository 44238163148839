/* eslint-disable no-nested-ternary */
import { useRef, useEffect } from 'react';
import { CircularProgress, Divider, Stack, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowBackIosNew as ArrowBack } from '@material-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactQueryNames } from 'react-query/react-query-names';
import { ConsumableContent } from 'interfaces/Content';
import useIsPortrait from 'modules/auth/pages/Register/hooks/use-is-portrait';
import { useQuery } from 'react-query';
import Description, { DescriptionTitle } from './components/Description';
import RelatedVideosList from './components/RelatedVideosList';
import getContent from './services/getContent';
import * as S from './styles';
import Player from './components/Player';
import WithChatPlayer from './components/WithChatPlayer';

const Content = () => {
  const { id } = useParams();
  const { isLoading, data: contentData } = useQuery([ReactQueryNames.CONTENT, id], () => getContent(id));
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isPortrait = useIsPortrait();
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView();
    }
  }, [containerRef]);
  if (isLoading) return <CircularProgress />;
  const hasChat = contentData?.chatEnable;
  const contentIsNotExternal = !contentData?.externalContent;
  return (
    <S.Container ref={containerRef} data-testid="content-wrapper" className="no-text-selection">
      <S.GoBackButton
        onClick={() => navigate(-1)}
        style={{
          position: 'absolute',
          marginTop: 30,
        }}
      >
        <ArrowBack
          sx={{
            color: 'common.white',
            cursor: 'pointer',
            height: 20,
            left: 0,
            ml: { xs: 0 },
            mr: 2,
            width: 20,
          }}
        />
      </S.GoBackButton>
      <Stack sx={{ alignItems: 'center' }}>
        {hasChat && contentIsNotExternal ? (
          <WithChatPlayer chatEmbed={contentData?.chatEmbed}>
            {isMobile || isPortrait ? (
              <>
                <Player contentData={contentData as ConsumableContent} />
                <Stack
                  sx={{
                    flexDirection: { xs: 'column-reverse', lg: 'row' },
                    justifyContent: 'space-between',
                    mt: 4,
                    width: '100%',
                  }}
                >
                  <DescriptionTitle title={contentData!?.title} />
                </Stack>
              </>
            ) : (
              <Player contentData={contentData as ConsumableContent} />
            )}
          </WithChatPlayer>
        ) : (
          <Player contentData={contentData as ConsumableContent} />
        )}
      </Stack>
      {hasChat ? (
        isMobile || isPortrait ? null : (
          <Stack
            sx={{
              flexDirection: { xs: 'column-reverse', lg: 'row' },
              justifyContent: 'space-between',
              my: 8,
              width: '100%',
            }}
          >
            <Description content={contentData!} />
            <Divider
              sx={{
                bgcolor: 'grey.400',
                display: { xs: 'block', lg: 'none' },
                mb: 6,
              }}
            />
          </Stack>
        )
      ) : (
        <Stack
          sx={{
            flexDirection: { xs: 'column-reverse', lg: 'row' },
            justifyContent: 'space-between',
            my: 8,
            width: '100%',
          }}
        >
          <Description content={contentData!} />
          <Divider
            sx={{
              bgcolor: 'grey.400',
              display: { xs: 'block', lg: 'none' },
              mb: 6,
            }}
          />
        </Stack>
      )}
      <RelatedVideosList relatedVideos={contentData!.relatedVideos} />
    </S.Container>
  );
};

export default Content;

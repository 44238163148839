import { Avatar, Box, Divider, Stack, Typography } from '@material-ui/core';
import {
  ArrowForwardIos as ArrowIcon,
  Edit as EditAvatarIcon,
  Sensors as LiveIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@material-ui/icons';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import getTeams from 'modules/auth/pages/Register/services/getTeams';
import { Team } from 'interfaces/team';
import ProfileContext from '../../../Context';
import AvatarPlaceholder from './AvatarPlaceholder';

type Props = {
  onClose?: () => void;
};

const UserInfoCard = ({ onClose = () => null }: Props) => {
  const { isLoading: isFetchingTeams, data: teams } = useQuery(
    'teams',
    getTeams,
  );
  const navigate = useNavigate();
  const { profile } = useContext(ProfileContext);
  const favoriteTeam = profile!.team;
  const mobileSectionLinks = [
    {
      icon: <LiveIcon sx={{ fontSize: 20 }} />,
      label: 'Ao Vivo',
      pathname: '/live-contents',
    },
    {
      icon: <FavoriteBorderIcon sx={{ fontSize: 20 }} />,
      label: favoriteTeam,
      pathname: '/team-content',
    },
  ];
  if (isFetchingTeams) {
    return <AvatarPlaceholder />;
  }
  const searchTeamByFavoriteTeamId = teams?.data?.filter(
    (team) => team.id === profile?.favoriteTeamId,
  )[0] as Team;
  const hasAvatars = searchTeamByFavoriteTeamId?.avatars?.length > 0;
  const goToEditAvatarPage = () => {
    if (hasAvatars) {
      onClose();
      navigate('/avatar/edit');
    }
  };
  return (
    <Stack alignItems="center" sx={{ width: '100%' }}>
      <Box
        sx={{
          cursor: hasAvatars ? 'pointer' : 'unset',
          height: 50,
          position: 'relative',
          width: 50,
        }}
        onClick={goToEditAvatarPage}
      >
        <Avatar src={profile?.avatar} sx={{ height: 50, width: 50 }} />
        {hasAvatars && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: 'secondary.main',
              borderRadius: '50%',
              bottom: '5%',
              height: 18,
              opacity: 0.9,
              position: 'absolute',
              right: '6%',
              width: 18,
            }}
          >
            <EditAvatarIcon sx={{ height: 12, width: 12 }} />
          </Stack>
        )}
      </Box>
      <Typography
        sx={{
          color: 'common.white',
          fontFamily: 'primary',
          fontSize: 16,
          mt: 3,
          display: 'inline-block',
          maxHeight: 50,
          maxWidth: 200,
          overflow: 'hidden',
          textAlign: 'center',
        }}
      >
        {profile?.name}
      </Typography>
      {mobileSectionLinks.map((section) => (
        <Stack
          sx={{
            alignItems: 'center',
            color: 'grey.300',
            cursor: 'pointer',
            display: { xs: 'flex', lg: 'none' },
            flexDirection: 'row',
            px: 5,
            mt: 4,
            width: '100%',
          }}
          onClick={() => {
            navigate(section.pathname);
            onClose();
          }}
        >
          {section.icon}
          <Typography sx={{ fontFamily: 'secondary', fontSize: 14, ml: 3 }}>
            {section.label}
          </Typography>
          <ArrowIcon sx={{ height: 13, ml: 'auto' }} />
        </Stack>
      ))}
      <Divider
        sx={{
          bgcolor: 'grey.300',
          mt: '18px',
          width: '100%',
        }}
      />
    </Stack>
  );
};

export default UserInfoCard;

import { Alert, Avatar, Box, Stack, SvgIcon } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useDynamicImport from 'utils/hooks/use-dynamic-import';
import environment from '../../../../../environment.config';
import saveUserAvatar from '../services/saveUserAvatar';

type Props = {
  avatarUrls: string[];
  user: { name: string; phone: string };
  userAvatar: string;
  setUserAvatar: (avatar: string) => void;
};

const AvatarList = ({ userAvatar, user, avatarUrls, setUserAvatar }: Props) => {
  const alert = useAlert();
  const { loading, element } = useDynamicImport(
    `${environment.AppSlug}/svg/selectedAvatar.svg`,
  );
  const [selectedAvatar, setSelectedAvatar] = useState(userAvatar);
  const [updatingAvatar, setUpdatingAvatar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const updateUserAvatar = async (saveAvatar: string) => {
    try {
      setUpdatingAvatar(true);
      const input = { ...user, avatar: saveAvatar };
      await saveUserAvatar(input);
      setUserAvatar(selectedAvatar);
      setSuccessMessage('Imagem alterada com sucesso!');
    } catch {
      alert.error('Ocorreu um erro ao atualizar a imagem.');
    } finally {
      setUpdatingAvatar(false);
    }
  };
  // TODO: add skeleton
  if (loading) return <div>Carregando...</div>;
  return (
    <>
      <Stack
        sx={{
          bgcolor: 'rgba(0, 0, 0, 0.25)',
          borderColor: 'grey.400',
          borderRadius: 1,
          borderStyle: 'solid',
          borderWidth: '1px',
          maxHeight: { xs: 208, md: 564 },
        }}
      >
        <PerfectScrollbar style={{ height: 'auto' }}>
          <Stack
            direction="row"
            justifyContent="center"
            gap={6}
            sx={{
              flexWrap: 'wrap',
              px: { xs: 0, md: 6 },
              py: 4,
              width: '100%',
            }}
          >
            {avatarUrls?.map((currentAvatar) => (
              <Box
                key={currentAvatar}
                sx={{
                  cursor: 'pointer',
                  height: { xs: 88, lg: 100 },
                  position: 'relative',
                  width: { xs: 88, lg: 100 },
                }}
                onClick={() => setSelectedAvatar(currentAvatar)}
              >
                <Avatar src={currentAvatar} sx={{ height: 100, width: 100 }} />
                {selectedAvatar === currentAvatar && (
                  <SvgIcon
                    component={element as any}
                    viewBox="0 0 100 100"
                    sx={{
                      height: 100,
                      left: 0,
                      position: 'absolute',
                      top: 0,
                      width: 100,
                      zIndex: 999,
                    }}
                  />
                )}
              </Box>
            ))}
          </Stack>
        </PerfectScrollbar>
      </Stack>
      <LoadingButton
        variant="contained"
        fullWidth
        loading={updatingAvatar}
        sx={{ mt: 6 }}
        onClick={() => {
          if (selectedAvatar) {
            updateUserAvatar(selectedAvatar);
          }
        }}
      >
        Alterar imagem de perfil
      </LoadingButton>
      {successMessage && (
        <Alert
          severity="success"
          sx={{
            bgcolor: 'rgba(0, 255, 102, 0.1)',
            border: '1px solid #00FF66',
            color: 'grey.200',
            py: 1,
            justifyContent: 'center',
            mt: 4,
            '.MuiAlert-icon': {
              display: 'none',
            },
          }}
        >
          {successMessage}
        </Alert>
      )}
    </>
  );
};

export default AvatarList;

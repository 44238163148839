import { Box, Stack, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { Timeline } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { ReactQueryNames } from 'react-query/react-query-names';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BillingHistoryCard from './components/BillingHistoryCard';
import BillingHistoryCardPlaceholderList from './components/BillingHistoryCardPlaceholderList';
import getBillingHistory from './services/getBillingHistory';

const BillingHistory = () => {
  const { isLoading, data } = useQuery(
    ReactQueryNames.BILLING_HISTORY,
    getBillingHistory,
  );
  const billingHistories = data?.data;
  return (
    <Box>
      <Typography
        sx={{
          color: 'grey.100',
          fontFamily: 'primary',
          fontSize: { xs: 20, lg: 16 },
        }}
      >
        Meu Histórico
      </Typography>
      <Typography
        sx={{ color: 'grey.300', fontFamily: 'secondary', fontSize: 14, mt: 2 }}
      >
        A mensalidade é cobrada no início de cada período de cobrança. Alguns
        dias podem transcorrer antes da cobrança ser exibida na sua conta.
      </Typography>
      <PerfectScrollbar style={{ height: '50vh', paddingRight: 25 }}>
        <Timeline
          sx={{
            alignSelf: 'flex-start',
            mt: { xs: 7, lg: 5 },
            p: 0,
            width: '100%',
          }}
        >
          {isLoading ? (
            <BillingHistoryCardPlaceholderList />
          ) : (
            billingHistories!.map((billingHistory, index: number) => (
              <BillingHistoryCard
                billingHistory={billingHistory}
                isLast={billingHistories!.length - 1 === index}
              />
            ))
          )}
        </Timeline>
        <Stack alignItems="center" direction="row" spacing={2}>
          <InfoIcon fontSize="small" sx={{ color: 'grey.300' }} />
          <Typography
            sx={{ color: 'grey.300', fontFamily: 'secondary', fontSize: 12 }}
          >
            Mostramos apenas até um ano do histórico de pagamentos. Caso
            necessário, entre em contato.
          </Typography>
        </Stack>
      </PerfectScrollbar>
    </Box>
  );
};

export default BillingHistory;

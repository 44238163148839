import { ValueType } from 'interfaces/Discount';
import getSymbolByValueType from './getCurrencySymbolByValueType';

const formatPriceByValueType = (price: number, type: ValueType): string => {
  const symbol = getSymbolByValueType(type);
  const isValue = type === ValueType.value;
  if (isValue) {
    return `${price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })}`;
  }
  return `${price} ${symbol}`;
};

export default formatPriceByValueType;

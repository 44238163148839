import { Timeline } from '@material-ui/lab';
import BillingHistoryCardPlaceholder from './BillingHistoryCardPlaceholder';

const BillingHistoryCardPlaceholderList = () => (
  <Timeline>
    <BillingHistoryCardPlaceholder />
    <BillingHistoryCardPlaceholder />
    <BillingHistoryCardPlaceholder isLast />
  </Timeline>
);

export default BillingHistoryCardPlaceholderList;

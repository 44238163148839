/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { Box, Chip, Stack, StackProps, Typography } from '@material-ui/core';
import { AccessTimeFilled as ClockIcon } from '@material-ui/icons';
import { SxProps, Theme } from '@material-ui/system';
import React, { useMemo } from 'react';
import { Content, ContentType } from '../../../interfaces/Content';
import getColorByContentType from '../../home/utils/getColorByContentType';
import { getContentType } from '../../home/utils/getPropsByContentType';
import getContentTypeFriendlyName from '../../home/utils/getContentTypeFriendlyName';
import { formatDate } from '../../../utils/date';

interface ContentCardProps {
  content: Content;
  iconVisible?: boolean;
  scheduleDateVisible?: boolean;
  typeVisible?: boolean;
  ContentDateProps?: StackProps;
  CoverProps?: SxProps<Theme>;
  DescriptionProps?: StackProps;
  sx?: SxProps<Theme>;
  onClickCard?: (content: Content) => void;
  className?: string;
}

const ContentCard = ({
  content,
  iconVisible = true,
  scheduleDateVisible = true,
  typeVisible = false,
  ContentDateProps,
  CoverProps,
  DescriptionProps,
  sx,
  onClickCard,
  className,
}: ContentCardProps) => {
  const date = useMemo(
    () => formatDate(content!.scheduleDate!, "dd 'de' MMMM, HH'h'mm"),
    [],
  );
  const contentType = getContentType(content.scheduleDate, content.finishDate);
  const contentTypeFriendlyName = getContentTypeFriendlyName(
    contentType as ContentType,
  );
  return (
    <Stack
      className={`${className || ''} contentCard`}
      sx={{
        width: { xs: 220, lg: 277 },
        ...sx,
      }}
      onClick={() => onClickCard?.(content)}
    >
      <Box
        sx={{
          backgroundImage: `url(${content.thumbnail})`,
          backgroundSize: 'cover',
          borderRadius: 1,
          height: { xs: 124, lg: 150 },
          mb: 4,
          width: { xs: 220, lg: 277 },
          ...CoverProps,
        }}
      />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 3, gap: { xs: 0, lg: 2 } }}
        {...DescriptionProps}
      >
        <Typography
          title={content.title}
          sx={{
            display: '-webkit-box',
            color: 'grey.100',
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 1.5,
            overflow: 'hidden',
            textAlign: { xs: 'center', lg: 'left' },
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {content.title}
        </Typography>
        {typeVisible && contentTypeFriendlyName !== 'Vod' && (
          <Chip
            label={contentTypeFriendlyName}
            sx={{
              bgcolor: getColorByContentType(contentType as ContentType),
              fontSize: 10,
              height: 18,
              borderRadius: '4px',
            }}
          />
        )}
      </Stack>
      {scheduleDateVisible && (
        <Stack alignItems="center" direction="row" {...ContentDateProps}>
          {iconVisible && (
            <ClockIcon
              sx={{ color: 'grey.400', height: 15, mr: 2, width: 15 }}
            />
          )}
          <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
            {date}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ContentCard;

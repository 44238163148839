import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Radio,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { CardNumberVerification } from 'card-validator/dist/card-number';
import creditCardValidator from 'card-validator';
import { Plan } from 'interfaces/plan';
import MaskedCreditCardNumberInput from 'modules/account/pages/Payment/MaskedCreditCardNumberInput';
import {
  CpfMaskInput,
  getInstallments,
} from 'modules/auth/pages/Register/components/CreditCardStep';
import cardsImgMapper from 'modules/auth/pages/Register/providers/cards';
import { CardType } from 'modules/auth/pages/Register/typings';
import React, { forwardRef, useState } from 'react';
import preventFloatingNumbers from 'utils/number';
import MaskedInput from 'react-text-mask';

export const ExpirationDateInput = forwardRef((props, ref) => {
  const setRef = React.useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={setRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
      guide={false}
      placeholderChar={'\u2000'}
    />
  );
}) as any;

export const CvvMask = forwardRef((props, ref) => {
  const setRef = React.useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={setRef}
      mask={[/\d/, /\d/, /\d/]}
      guide={false}
      placeholderChar={'\u2000'}
    />
  );
}) as any;

interface PaymentFormProps {
  offer: Plan;
  form: any;
}

const PaymentForm = ({ offer, form }: PaymentFormProps) => {
  const [cardDetails, setCardDetails] = useState<
    CardNumberVerification | undefined
  >();
  const [expanded, setExpanded] = useState<string | false>(false);
  const haveInstallments = offer.installments > 1;
  const isPlanSupportCreditCard = offer.paymentType?.includes('credit');
  const isPlanSupportDebitCard = offer.paymentType?.includes('debit');
  const isPlanSupportPix = offer.paymentType?.includes('pix');
  const accordionSwitch = (field: string, painel: string) => {
    if (expanded === painel) {
      setExpanded(false);
    } else {
      form.setFieldValue('paymentMethod', field);
      setExpanded(painel);
    }
  };
  return (
    <>
      {isPlanSupportCreditCard && (
        <Accordion
          expanded={expanded === 'painel1'}
          sx={{
            bgcolor: 'common.white',
            border: '1px solid',
            borderColor: 'primary.light',
            borderRadius: 1,
            mb: 2,
          }}
          onChange={() => accordionSwitch('credit', 'painel1')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Radio
                data-testid="creditCardRadio"
                checked={form.values.paymentMethod === 'credit'}
                value="credit"
                onChange={(e) =>
                  form.setFieldValue('paymentMethod', e.target.value)
                }
              />
              <Typography
                sx={{
                  color: 'grey.400',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
              >
                Cartão de Crédito
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography
                sx={{
                  color: 'grey.400',
                  fontSize: 14,
                  fontWeight: 'bold',
                  mt: 5,
                }}
              >
                Nome do Titular:
              </Typography>
              <TextField
                data-testid="cardHolderNameInput"
                name="cardHolderName"
                error={
                  form.touched.cardHolderName && !!form.errors.cardHolderName
                }
                helperText={
                  form.touched.cardHolderName && form.errors.cardHolderName
                }
                fullWidth
                value={form.values.cardHolderName}
                sx={{
                  mt: 2,
                  width: '100%',
                  '.MuiFormHelperText-root': { ml: 0 },
                }}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
              <Typography
                sx={{
                  color: 'grey.400',
                  fontSize: 14,
                  fontWeight: 'bold',
                  mt: 5,
                }}
              >
                Número do cartão:
              </Typography>
              <TextField
                data-testid="creditCardInput"
                error={form.touched.creditCard && !!form.errors.creditCard}
                helperText={form.touched.creditCard && form.errors.creditCard}
                name="creditCard"
                type="tel"
                placeholder="0000-0000-0000-0000"
                value={form.values.creditCard}
                InputProps={{
                  endAdornment: cardDetails?.card && (
                    <SvgIcon
                      component={
                        cardsImgMapper[cardDetails!.card!.type as CardType]
                      }
                      viewBox="0 0 780 500"
                      sx={{ height: 19, width: 29 }}
                    />
                  ),
                  inputComponent: MaskedCreditCardNumberInput,
                }}
                sx={{
                  mt: 2,
                  width: '100%',
                  '.MuiFormHelperText-root': { ml: 0 },
                }}
                onBlur={form.handleBlur}
                onChange={(evt) => {
                  form.setFieldValue('cardBrand', cardDetails?.card?.type);
                  form.handleChange(evt);
                  setCardDetails(
                    creditCardValidator.number(form.values.creditCard),
                  );
                }}
              />
            </Box>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={haveInstallments ? 4 : 6}>
                <Typography
                  sx={{ color: 'grey.400', fontSize: 14, fontWeight: 'bold' }}
                >
                  Validade:
                </Typography>
                <TextField
                  data-testid="expirationDateInput"
                  value={form.values.expirationDate.replaceAll(' ', '')}
                  name="expirationDate"
                  type="tel"
                  error={
                    form.touched.expirationDate && !!form.errors.expirationDate
                  }
                  helperText={
                    form.touched.expirationDate && form.errors.expirationDate
                  }
                  fullWidth
                  InputProps={{ inputComponent: ExpirationDateInput }}
                  placeholder="MM/AA"
                  sx={{
                    mt: 2,
                    width: '100%',
                    '.MuiFormHelperText-root': { ml: 0 },
                  }}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item xs={haveInstallments ? 4 : 6}>
                <Typography
                  sx={{ color: 'grey.400', fontSize: 14, fontWeight: 'bold' }}
                >
                  CVV:
                </Typography>
                <TextField
                  data-testid="cvvInput"
                  error={form.touched.cvv && !!form.errors.cvv}
                  helperText={form.touched.cvv && form.errors.cvv}
                  name="cvv"
                  placeholder="000"
                  value={form.values.cvv}
                  sx={{
                    mt: 2,
                    width: '100%',
                  }}
                  InputProps={{ inputComponent: CvvMask }}
                  onBlur={form.handleBlur}
                  onKeyDown={(e) =>
                    preventFloatingNumbers(e, ['E', 'e', '-', '+', ',', '.'])
                  }
                  onChange={form.handleChange}
                />
              </Grid>
              {haveInstallments && (
                <Grid item xs={4}>
                  <Typography
                    sx={{ color: 'grey.400', fontSize: 14, fontWeight: 'bold' }}
                  >
                    Parcelas:
                  </Typography>
                  <Select
                    displayEmpty
                    data-testid="installmentsInput"
                    error={
                      form.touched.installments && !!form.errors.installments
                    }
                    name="installments"
                    renderValue={() => form.values.installments}
                    type="number"
                    value={form.values.installments}
                    sx={{
                      mt: 2,
                      width: '100%',
                    }}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                  >
                    {getInstallments(offer.installments)}
                  </Select>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  sx={{ color: 'grey.400', fontSize: 14, fontWeight: 'bold' }}
                >
                  CPF do titular:
                </Typography>
                <TextField
                  data-testid="cpfInput"
                  name="cpf"
                  type="tel"
                  error={form.touched.cpf && !!form.errors.cpf}
                  helperText={form.touched.cpf && form.errors.cpf}
                  InputProps={{ inputComponent: CpfMaskInput }}
                  placeholder="000.000.000-00"
                  value={form.values.cpf}
                  sx={{
                    mt: 2,
                    width: '100%',
                    '.MuiFormHelperText-root': { ml: 0 },
                  }}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {isPlanSupportDebitCard && (
        <Accordion
          expanded={expanded === 'painel2'}
          sx={{
            bgcolor: 'common.white',
            border: '1px solid',
            borderColor: 'primary.light',
            borderRadius: 1,
            mb: 2,
          }}
          onChange={() => accordionSwitch('debit', 'painel2')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Radio
                data-testid="debitCardRadio"
                checked={form.values.paymentMethod === 'debit'}
                value="debit"
                onChange={(e) =>
                  form.setFieldValue('paymentMethod', e.target.value)
                }
              />
              <Typography
                sx={{
                  color: 'grey.400',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
              >
                Cartão de Débito
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography
                sx={{
                  color: 'grey.400',
                  fontSize: 14,
                  fontWeight: 'bold',
                  mt: 5,
                }}
              >
                Nome do Titular:
              </Typography>
              <TextField
                data-testid="debitCardHolderNameInput"
                name="debitCardHolderName"
                error={
                  form.touched.debitCardHolderName &&
                  !!form.errors.debitCardHolderName
                }
                helperText={
                  form.touched.debitCardHolderName &&
                  form.errors.debitCardHolderName
                }
                fullWidth
                value={form.values.debitCardHolderName}
                sx={{
                  mt: 2,
                  width: '100%',
                  '.MuiFormHelperText-root': { ml: 0 },
                }}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
              <Typography
                sx={{
                  color: 'grey.400',
                  fontSize: 14,
                  fontWeight: 'bold',
                  mt: 5,
                }}
              >
                Número do cartão:
              </Typography>
              <TextField
                data-testid="debitCardInput"
                error={form.touched.debitCard && !!form.errors.debitCard}
                helperText={form.touched.debitCard && form.errors.debitCard}
                name="debitCard"
                placeholder="0000-0000-0000-0000"
                type="tel"
                value={form.values.debitCard}
                InputProps={{
                  endAdornment: cardDetails?.card && (
                    <SvgIcon
                      component={
                        cardsImgMapper[cardDetails!.card!.type as CardType]
                      }
                      viewBox="0 0 780 500"
                      sx={{ height: 19, width: 29 }}
                    />
                  ),
                  inputComponent: MaskedCreditCardNumberInput,
                }}
                sx={{
                  mt: 2,
                  width: '100%',
                  '.MuiFormHelperText-root': { ml: 0 },
                }}
                onBlur={form.handleBlur}
                onChange={(evt) => {
                  form.setFieldValue('cardBrand', cardDetails?.card?.type);
                  form.handleChange(evt);
                  setCardDetails(
                    creditCardValidator.number(form.values.debitCard),
                  );
                }}
              />
            </Box>
            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={6} lg={3}>
                <Typography
                  sx={{ color: 'grey.400', fontSize: 14, fontWeight: 'bold' }}
                >
                  Validade:
                </Typography>
                <TextField
                  data-testid="expirationDateInput"
                  value={form.values.debitExpirationDate.replaceAll(' ', '')}
                  name="debitExpirationDate"
                  type="tel"
                  error={
                    form.touched.debitExpirationDate &&
                    !!form.errors.debitExpirationDate
                  }
                  helperText={
                    form.touched.debitExpirationDate &&
                    form.errors.debitExpirationDate
                  }
                  fullWidth
                  InputProps={{ inputComponent: ExpirationDateInput }}
                  placeholder="MM/AA"
                  sx={{
                    mt: 2,
                    width: '100%',
                    '.MuiFormHelperText-root': { ml: 0 },
                  }}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography
                  sx={{ color: 'grey.400', fontSize: 14, fontWeight: 'bold' }}
                >
                  CVV:
                </Typography>
                <TextField
                  data-testid="cvvInput"
                  error={form.touched.debitCVV && !!form.errors.debitCVV}
                  helperText={form.touched.debitCVV && form.errors.debitCVV}
                  name="debitCVV"
                  placeholder="000"
                  value={form.values.debitCVV}
                  sx={{
                    mt: 2,
                    width: '100%',
                  }}
                  InputProps={{ inputComponent: CvvMask }}
                  onBlur={form.handleBlur}
                  onKeyDown={(e) =>
                    preventFloatingNumbers(e, ['E', 'e', '-', '+', ',', '.'])
                  }
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography
                  sx={{ color: 'grey.400', fontSize: 14, fontWeight: 'bold' }}
                >
                  CPF do titular:
                </Typography>
                <TextField
                  data-testid="cpfInput"
                  name="debitCPF"
                  type="tel"
                  error={form.touched.debitCPF && !!form.errors.debitCPF}
                  helperText={form.touched.debitCPF && form.errors.debitCPF}
                  InputProps={{ inputComponent: CpfMaskInput }}
                  placeholder="000.000.000-00"
                  value={form.values.debitCPF}
                  sx={{
                    mt: 2,
                    width: '100%',
                    '.MuiFormHelperText-root': { ml: 0 },
                  }}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {isPlanSupportPix && (
        <Accordion
          expanded={false}
          sx={{
            bgcolor: 'common.white',
            border: '1px solid',
            borderColor: 'primary.light',
            borderRadius: 1,
            mb: 2,
          }}
          onChange={() => accordionSwitch('pix', 'painel3')}
        >
          <AccordionSummary>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Radio
                data-testid="pixRadio"
                checked={form.values.paymentMethod === 'pix'}
                value="pix"
                onChange={(e) =>
                  form.setFieldValue('paymentMethod', e.target.value)
                }
              />
              <Typography
                sx={{ color: 'grey.400', fontSize: 14, fontWeight: 'bold' }}
              >
                PIX
              </Typography>
            </Box>
          </AccordionSummary>
        </Accordion>
      )}
    </>
  );
};

export default PaymentForm;

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@material-ui/core';
import { CreditCard as CreditCardIcon } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useDialog } from 'components/Dialog';
import paymentStatusMsgMapper from '../../../auth/pages/Register/providers/paymentStatusMsgMapper';
import { PaymentStatus } from '../../../auth/pages/Register/typings';
import { addUserPayMethod, getUserStatus } from '../../services';
import { NewCreditCard } from '../../typings';
import MaskedCpfInput from './MaskedCpfInput';
import MaskedCreditCardNumberInput from './MaskedCreditCardNumberInput';
import MaskedShortDateInput from './MaskedShortDateInput';
import validationSchema from './validationSchema';

const NewCard = ({ user }: { user?: any }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const dialog = useDialog();
  const addPaymentMethodHandler = async (card: NewCreditCard) => {
    await addUserPayMethod(card);
    setLoading(true);
    setTimeout(() => {
      const getCardRegistrationStatus = new Promise((resolve) => {
        const response = getUserStatus();
        resolve(response);
      });
      Promise.resolve(getCardRegistrationStatus).then((response: any) => {
        const successInRegistrationCard = response.data.message === 'Success';
        if (successInRegistrationCard) {
          alert.success('Cartão registrado com sucesso');
          const newCreditCard = card.creditCard.substr(15, 19);
          dialog.close(newCreditCard);
          return;
        }
        const status: PaymentStatus = response.data.message;
        setError(paymentStatusMsgMapper[status]);
      });
      setLoading(false);
    }, 3000);
  };
  const form = useFormik<NewCreditCard>({
    initialValues: {
      email: user.email,
      cpf: '',
      cvv: '',
      expirationDate: '',
      creditCard: '',
      name: '',
    },
    validationSchema,
    onSubmit: (input) => addPaymentMethodHandler(input),
  });
  const getInputError = (err?: string) => (
    <Typography sx={{ color: 'error.main', fontSize: 10, textAlign: 'left' }}>
      {err}
    </Typography>
  );
  const getAsterisk = () => (
    <Typography component="span" sx={{ color: 'error.main', fontSize: 14 }}>
      *
    </Typography>
  );
  return (
    <Stack
      sx={{
        alignItems: 'center',
        gap: 3,
        justifyContent: 'space-around',
        mb: { xs: 6 },
        textAlign: 'center',
      }}
    >
      <Typography
        sx={{ color: 'grey.300', fontSize: 14, position: 'absolute', top: 26 }}
      >
        Novo cartão de crédito
      </Typography>
      <Stack
        sx={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 3,
          justifyContent: 'space-between',
        }}
      >
        <Stack sx={{ alignItems: 'flex-start', gap: 2, width: '100%' }}>
          <InputLabel sx={{ color: 'grey.300', fontSize: 14 }}>
            Nome do titular {getAsterisk()}
          </InputLabel>
          <OutlinedInput
            data-testid="owner-input"
            name="name"
            error={!!form.errors.name}
            fullWidth
            value={form.values.name}
            onChange={form.handleChange}
          />
          {getInputError(form.errors.name)}
        </Stack>
        <Stack sx={{ alignItems: 'flex-start', gap: 2, width: '100%' }}>
          <InputLabel sx={{ color: 'grey.300', fontSize: 14 }}>
            Número do cartão: {getAsterisk()}
          </InputLabel>
          <OutlinedInput
            data-testid="number-input"
            name="creditCard"
            type="tel"
            endAdornment={<CreditCardIcon />}
            error={!!form.errors.creditCard}
            fullWidth
            inputComponent={MaskedCreditCardNumberInput}
            value={form.values.creditCard}
            onChange={form.handleChange}
          />
          {getInputError(form.errors.creditCard)}
        </Stack>
        <Stack sx={{ alignItems: 'flex-start', gap: 2, width: 69 }}>
          <InputLabel sx={{ color: 'grey.300', fontSize: 14 }}>
            CVV: {getAsterisk()}
          </InputLabel>
          <OutlinedInput
            data-testid="cvv-input"
            name="cvv"
            error={!!form.errors.cvv}
            fullWidth
            value={form.values.cvv}
            onChange={form.handleChange}
          />
          {getInputError(form.errors.cvv)}
        </Stack>
        <Stack sx={{ alignItems: 'flex-start', gap: 2, width: 81 }}>
          <InputLabel sx={{ color: 'grey.300', fontSize: 14 }}>
            Validade: {getAsterisk()}
          </InputLabel>
          <OutlinedInput
            data-testid="expiring-date-input"
            name="expirationDate"
            error={!!form.errors.expirationDate}
            fullWidth
            inputComponent={MaskedShortDateInput}
            value={form.values.expirationDate}
            onChange={form.handleChange}
          />
          {getInputError(form.errors.expirationDate)}
        </Stack>
        <Stack sx={{ alignItems: 'flex-start', gap: 2, width: 145 }}>
          <InputLabel sx={{ color: 'grey.300', fontSize: 14 }}>
            Seu CPF: {getAsterisk()}
          </InputLabel>
          <OutlinedInput
            data-testid="cpf-input"
            name="cpf"
            error={!!form.errors.cpf}
            fullWidth
            inputComponent={MaskedCpfInput}
            value={form.values.cpf}
            onChange={form.handleChange}
          />
          {getInputError(form.errors.cpf)}
        </Stack>
      </Stack>
      {error && getInputError(error)}
      <LoadingButton
        data-testid="submit-button"
        loading={loading}
        disabled={!form.isValid}
        sx={{ bgcolor: 'secondary.main', height: 60, mt: 2, width: '100%' }}
        onClick={() => form.handleSubmit()}
      >
        Adicionar cartão
      </LoadingButton>
    </Stack>
  );
};

export default NewCard;

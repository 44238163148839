import { Box, Stack, Typography } from '@material-ui/core';
import { ArrowBackIosNew as ArrowBack } from '@material-ui/icons';
import { SwiperSlide } from 'swiper/react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Slide from '../../../components/Slide';
import {
  Section as ISection,
  SortableSubSectionContent,
  SubSection,
} from '../../../interfaces/section';
import ContentCard from '../../search/components/ContentCard';
import Context from '../Context';
import ChipCard from './ChipCard';
import SectionView from './SectionView';

type ComponentProps = Record<string, unknown>;

const Section = () => {
  const navigate = useNavigate();
  const { selectedSection, setSelectedSection } = useContext(Context);
  const spacings = { px: { xs: 5, lg: 11 } };
  return (
    <Box data-testid={`section-view-${selectedSection?.id}`}>
      <Stack
        onClick={() => setSelectedSection(null)}
        alignItems="center"
        direction="row"
        sx={{
          justifyContent: { xs: 'center', lg: 'flex-start' },
          mb: 8,
          mt: 5,
          position: 'relative',
          ...spacings,
        }}
      >
        <ArrowBack
          sx={{
            color: 'common.white',
            cursor: 'pointer',
            height: 25,
            left: 0,
            ml: { xs: 0 },
            mr: 4,
            width: 25,
          }}
        />
        <Typography
          sx={{
            color: 'grey.100',
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          Todos os conteúdos
        </Typography>
      </Stack>

      <SectionView
        section={selectedSection as ISection}
        renderItem={({ content }: SortableSubSectionContent) => (
          <ContentCard
            content={content}
            typeVisible
            scheduleDateVisible={false}
            CoverProps={{
              height: { xs: 146, lg: 194 },
              width: { xs: 260, lg: 300, xl: 400 },
            }}
            DescriptionProps={{
              direction: { xs: 'column', lg: 'row' },
              spacing: { xs: 2, lg: 0 },
            }}
            sx={{ width: { xs: 260, lg: 300, xl: 400 }, cursor: 'pointer' }}
            onClickCard={() => {
              navigate(`/content/${content.id}`);
            }}
          />
        )}
        renderTag={(tag: SubSection['title'], tagProps: ComponentProps) => (
          <SwiperSlide>
            <ChipCard label={!tag ? 'Todos' : tag} ChipCardProps={tagProps} />
          </SwiperSlide>
        )}
        ContentWrapper={Box}
        ContentWrapperProps={{
          display: 'grid',
          gap: 5,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' },
          ...spacings,
        }}
        SelectedTagProps={{ sx: { bgcolor: 'secondary.main' } }}
        TagsWrapper={Slide}
        TagsWrapperProps={{
          'data-testid': 'swiper-sub-sections',
          sliderProps: { slidesPerView: 'auto', spaceBetween: 16 },
          style: { marginBottom: 36 },
        }}
      />
    </Box>
  );
};

export default Section;

import { Box } from '@material-ui/core';
import { useMemo, useState } from 'react';
import FilterableTags from '../../../components/FilterableTags';
import { SortableSubSectionContent } from '../../../interfaces/section';
import { SectionViewProps } from '../../search/components/SectionView';

type ISectionViewProps = Omit<SectionViewProps, 'selectedSection'>;

const SectionView = ({
  section,
  renderItem,
  renderTag,
  ContentWrapper,
  ContentWrapperProps,
  SelectedTagProps,
  TagsWrapper,
  TagsWrapperProps,
  TitleSx,
}: ISectionViewProps) => {
  const getAllSubSectionContents = () => {
    const subSectionContents: SortableSubSectionContent[] = [];
    section.subSections.forEach((subsection) => {
      subsection.sortableSubSectionContents.forEach((content) => {
        subSectionContents.push(content);
      });
    });
    return subSectionContents;
  };
  const allContents = section.sortableSectionContents.concat(
    getAllSubSectionContents(),
  );
  const [items, setItems] = useState(allContents);
  const onSelectTagHandle = (tag: string, index: number) => {
    setItems(section.subSections[index].sortableSubSectionContents);
  };

  const componentFilterableTags = useMemo(
    () => (
      <FilterableTags
        title={section.title}
        tags={[...section.subSections.map((subSection) => subSection.title)]}
        items={items}
        renderItem={renderItem}
        renderTag={renderTag}
        ContentWrapper={ContentWrapper}
        ContentWrapperProps={ContentWrapperProps}
        TagsWrapper={TagsWrapper}
        TagsWrapperProps={TagsWrapperProps}
        TitleSx={TitleSx}
        SelectedTagProps={SelectedTagProps}
        onSelectTag={(tag: string, index: number) =>
          onSelectTagHandle(tag, index)
        }
      />
    ),
    [items],
  );
  return <Box>{componentFilterableTags}</Box>;
};

export default SectionView;

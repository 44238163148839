import { Stack } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface SingleContainerProps {
  bgColor: string;
  children: ReactNode;
}

const SingleContainer = ({
  bgColor,
  children,
}: SingleContainerProps) => (
  <Stack
    sx={{
      bgcolor: bgColor,
      gap: { xs: 2, lg: 5 },
      height: 'auto',
      p: { xs: 5, lg: 10 },
      position: 'relative',
      minHeight: { xs: 200, lg: 400 },
      mx: { xs: -5, lg: -10 },
    }}
  >
    {children}
  </Stack>
);

export default SingleContainer;

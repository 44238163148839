import { keyframes } from '@emotion/react';
import { Stack, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  ArrowForwardIos as ArrowIcon,
  Sensors as LiveIcon,
} from '@material-ui/icons';
import { forwardRef, memo, useCallback, useRef } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import Slide from 'components/Slide';
import { SectionDataDTO } from 'services/Section/SectionService.types';
import { VideoContentCard } from './VideoContentCard';

const liveAnimation = keyframes`
  to {
    opacity: 0;
  }
  `;

interface SectionLineProps {
  sectionData: SectionDataDTO;
  hasShowMoreLink?: boolean;
}
const SectionLine = (
  { sectionData, hasShowMoreLink = true }: SectionLineProps,
  ref: any,
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const contentData = useRef<SectionDataDTO['contentList']>(
    sectionData.contentList,
  );
  const match = useMatch('/section-content/:item');
  const locationParams = match?.params.item;
  const pathIsTeam = locationParams === 'team';
  const hasSubsections = sectionData.subSections;
  const sectionIslive = sectionData.isLive;
  const slidePerGroupsCalc = isMobile
    ? (window.innerWidth - 12) / (220 + 8)
    : (window.innerWidth - 95 - 8) / (224 + 16);
  const hasContents = !!contentData?.current;
  const titleLabel = sectionIslive ? (
    <Stack alignItems="center" direction="row" gap={{ xs: 2, lg: 3 }}>
      <LiveIcon
        color="error"
        sx={{
          opacity: 1,
          animation: `${liveAnimation} 1s ease infinite`,
        }}
      />
      <Typography
        sx={{
          color: 'common.white',
          fontFamily: 'primary',
          fontSize: { xs: 20, lg: 24 },
        }}
      >
        Ao Vivo
      </Typography>
    </Stack>
  ) : (
    sectionData.title
  );
  const renderItem = useCallback(
    (eachContent, index) => (
      <SwiperSlide key={`Slide ${eachContent.id}`} virtualIndex={index}>
        <VideoContentCard data={eachContent} />
      </SwiperSlide>
    ),
    [],
  );
  if (!hasContents) return null;
  return (
    <Stack ref={ref} key={sectionData.id} sx={{ mb: { xs: 6, lg: 7 } }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 4, width: '100%' }}
      >
        <Typography
          sx={{
            color: 'common.white',
            fontFamily: 'primary',
            fontSize: { xs: 20, lg: 24 },
            ml: { xs: 0, lg: '95px' },
            maxWidth: { xs: '75%', lg: '100%' },
          }}
        >
          {titleLabel}
        </Typography>
        {hasShowMoreLink && hasSubsections && (
          <Stack
            alignItems="center"
            direction="row"
            onClick={() => {
              navigate(
                `/section-content/${sectionData.id}?pathIsTeam=${pathIsTeam}`,
              );
            }}
            sx={{
              color: 'grey.300',
              cursor: 'pointer',
              mr: { xs: 2, lg: '87px' },
              transition: ' all 0.2s',
              minWidth: { xs: 51, lg: 80 },
              ':hover': {
                color: 'common.white',
              },
            }}
          >
            <Typography
              sx={{
                color: 'inherit',
                fontFamily: 'primary',
                fontSize: { xs: 10, lg: 16 },
                whiteSpace: 'nowrap',
              }}
            >
              Veja mais
            </Typography>
            <ArrowIcon sx={{ color: 'inherit', height: { xs: 10, lg: 13 } }} />
          </Stack>
        )}
      </Stack>
      {!!contentData?.current && (
        <Slide
          data-testid="sections-menu"
          sliderProps={{
            virtual: true,
            slidesPerGroup: isMobile ? 1 : 4,
            slidesPerView: slidePerGroupsCalc,
            spaceBetween: isMobile ? 8 : 16,
            style: { paddingLeft: isMobile ? 0 : '95px' },
          }}
          style={{ width: '100%' }}
        >
          {contentData.current.map(renderItem)}
        </Slide>
      )}
    </Stack>
  );
};

export default memo(forwardRef(SectionLine));

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { Box } from '@material-ui/core';
import { useRef } from 'react';
import { Swiper } from 'swiper/react';

const buttonClassDisabled = 'swiper-button-disabled';

const Slide = ({ children, sliderProps, onSlideChange, sx, ...props }: any) => {
  const navigationPrevRef = useRef<any>(null);
  const navigationNextRef = useRef<any>(null);
  const shouldShowGradient = useRef<any>(!sliderProps?.pagination?.clickable);
  const nextButtonIsDisabled =
    navigationNextRef?.current?.classList[3] === buttonClassDisabled;
  if (nextButtonIsDisabled) {
    shouldShowGradient.current = false;
  }
  return (
    <Box sx={{ position: 'relative' }} {...props}>
      <Box className="swiper-button-prev" ref={navigationPrevRef} />
      <Box className="swiper-button-next" ref={navigationNextRef} />
      {shouldShowGradient.current && (
        <Box
          className="swiper-button-container"
          sx={{
            position: 'absolute',
            background:
              'linear-gradient(to left, rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.75),rgba(0, 0, 0, 0.25))',
            height: 127,
            mt: 1,
            right: 0,
            width: 83,
            zIndex: 9,
            ...sx
          }}
        />
      )}
      <Swiper
        slidesPerView="auto"
        spaceBetween={24}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper: any /* TODO: add typing */) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        {...sliderProps}
        style={{ width: '100%', ...sliderProps.style }}
        onSlideChangeTransitionEnd={(swiper: Swiper) => onSlideChange?.(swiper)}
      >
        {children}
      </Swiper>
    </Box>
  );
};

export default Slide;

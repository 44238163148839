import { Typography } from '@material-ui/core';
import useLoggedIn from 'utils/hooks/use-logged-in';
import PlaceholderNavigationButton from '../components/PlaceholderNavigationButton';
import { SubscriptionPageProps } from '../typings';
import { PlanType } from '../../../interfaces/plan';

const SubscriptionError = ({ subscription }: SubscriptionPageProps) => {
  const { get } = useLoggedIn();
  const isLoggedIn = !!get();
  const destination = isLoggedIn ? '/account/subscriptions' : '/auth/register';
  const buttonLabel = isLoggedIn ? 'Minhas Assinaturas' : 'Voltar ao Cadastro';
  return (
    <>
      <Typography sx={{ fontSize: { xs: 24, lg: 32 }, fontWeight: 600 }}>
        Que pena! Não foi possível realizar a contratação do plano
        {subscription?.title ? ` ${subscription.title}` : ''}!
      </Typography>
      {subscription?.type === PlanType.partner ? (
        <Typography sx={{ fontSize: { xs: 14, lg: 20 }, mt: { xs: 3, lg: 6 } }}>
          Parece que seu acesso não foi autorizado ou os dados já foram
          utilizados. Confirme seus dados ou procure a sua empresa parceira.
        </Typography>
      ) : (
        <Typography sx={{ fontSize: { xs: 14, lg: 20 }, mt: { xs: 3, lg: 6 } }}>
          Houve uma falha no meio de pagamento escolhido. Confira os dados do
          seu cartão e retorne a página de check-out.
        </Typography>
      )}
      <PlaceholderNavigationButton
        destination={destination}
        label={buttonLabel}
      />
    </>
  );
};

export default SubscriptionError;

import { useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useDialog } from '../../../../components/Dialog';
import SecurityForm from './components/SecurityForm';
import ConfirmPasswordChangeModal from './components/SecurityForm/ConfirmPasswordChangeModal';
import Context from './Context';
import validationSchema from './providers/validationSchema';
import changeUserPassword from './services/changeUserPassword';
import { PasswordForm, PasswordPayload } from './typings';

const Security = () => {
  const alert = useAlert();
  const dialog = useDialog();
  const [isSaving, setIsSaving] = useState(false);
  const form = useFormik<PasswordForm>({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema,
    onSubmit: async ({ currentPassword, newPassword }) => {
      try {
        setIsSaving(true);
        const passwordPayload: PasswordPayload = {
          currentPassword,
          newPassword,
        };
        await changeUserPassword(passwordPayload);
        form.resetForm();
        alert.success('Senha alterada com sucesso!');
      } catch (error: any) {
        const isInvalidPasswordError =
          error.response?.status === StatusCodes.BAD_REQUEST;
        if (isInvalidPasswordError) {
          alert.error('Senha atual inválida!');
          return;
        }
        alert.error('Ocorreu um erro ao alterar senha!');
      } finally {
        setIsSaving(false);
      }
    },
  });
  const confirmPasswordChange = async () => {
    await dialog.open({
      element: <ConfirmPasswordChangeModal onSubmit={form.handleSubmit} />,
      sx: {
        bgcolor: 'common.white',
        height: 239,
        maxWidth: 372,
        width: '100%',
      },
    });
  };
  return (
    <Context.Provider value={{ form }}>
      <SecurityForm isSaving={isSaving} onSubmit={confirmPasswordChange} />
    </Context.Provider>
  );
};

export default Security;

import { Stack } from '@material-ui/core';
import SectionDetail from 'modules/home/refactor/components/SectionDetail';
import { useEffect, useRef } from 'react';
import { SectionDataDTO } from 'services/Section/SectionService.types';
import SectionContentEmptyState from './SectionContentEmptyState';

type ContentsDataProps = {
  sectionIsLive: boolean;
  contentsData: SectionDataDTO[];
};

const ContentsData = ({ contentsData, sectionIsLive }: ContentsDataProps) => {
  const fieldRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    fieldRef.current?.scrollIntoView();
  }, []);
  return (
    <Stack ref={fieldRef}>
      {contentsData &&
      contentsData.length > 0 &&
      contentsData[0].contentList ? (
        contentsData.map((eachSection) => (
          <SectionDetail key={eachSection.id} sectionData={eachSection} />
        ))
      ) : (
        <SectionContentEmptyState sectionIsLive={sectionIsLive} />
      )}
    </Stack>
  );
};
export default ContentsData;

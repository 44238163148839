import { Card, Skeleton, Stack } from '@material-ui/core';

const SubscriptionCardPlaceholder = () => (
  <Card
    sx={{
      bgcolor: 'grey.600',
      borderRadius: 3,
      height: 188,
      px: 4,
      width: 261,
    }}
  >
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        height: 54,
        justifyContent: 'space-between',
      }}
    >
      <Skeleton variant="rectangular" sx={{ height: 24, width: '60%' }} />
    </Stack>
    <Skeleton variant="rectangular" sx={{ height: 36, mt: 2, width: '80%' }} />
    <Stack
      alignItems="flex-end"
      direction="row"
      justifyContent="space-between"
      sx={{ mt: 3, width: '100%' }}
    >
      <Skeleton variant="rectangular" sx={{ height: 36, width: '45%' }} />
      <Skeleton variant="rectangular" sx={{ height: 24, width: '45%' }} />
    </Stack>
  </Card>
);

export default SubscriptionCardPlaceholder;

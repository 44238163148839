import { Button, Stack, Typography } from '@material-ui/core';
import redBall from 'assets/png/bolamurcha.png';
import { Plan, PlanType } from 'interfaces/plan';
import getNextSeasonDate from 'modules/account/utils/getNextSeasonDate';
import { useDialog } from './Dialog';

interface SubscriptionCancelationModalProps {
  subscription: Plan;
  userPlans: Plan[];
  plans: Plan[];
  deleteSubscription: (id: string) => void;
}

const SubscriptionCancelationModal = ({
  deleteSubscription,
  plans,
  subscription,
  userPlans,
}: SubscriptionCancelationModalProps) => {
  const dialog = useDialog();
  const isSubscriptionFree = subscription.type === PlanType.free;
  const isMonthlySubscription = subscription.type === PlanType.monthly;
  const isPartnerSubscription = subscription.type === PlanType.partner;
  const isSeasonSubscription = subscription.type === PlanType.season;
  const hasOnlyOnePlan = userPlans.length === 1;
  const nextBillingDateOfSeasonPlan = getNextSeasonDate(subscription.id, plans);
  const getDateAfterMonth = () => {
    const startDate = new Date();
    const endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      subscription.recurrenceDay,
    );
    return endDate;
  };
  const getDateAfterYear = () => {
    const splittedDate = nextBillingDateOfSeasonPlan?.split('-');
    const endDate =
      splittedDate &&
      new Date(
        Number(splittedDate[0]),
        Number(splittedDate[1]) - 1,
        Number(splittedDate[2]),
      );
    return endDate;
  };
  const formattedEndDate =
    subscription.type === PlanType.monthly
      ? getDateAfterMonth().toLocaleDateString('pt-br')
      : getDateAfterYear()?.toLocaleDateString('pt-br');
  const haveTwoPlans = userPlans.length === 2;
  const removePlanBeingCanceled = userPlans.filter(
    (plan) => plan.id !== subscription.id,
  );
  return (
    <Stack
      data-testid="alertSubscriptionModal"
      alignItems="center"
      justifyContent="space-around"
      gap={3}
    >
      <img
        src={redBall}
        alt="Cartão vermelho"
        style={{ height: 82, width: 76 }}
      />
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          fontWeight: 'bold',
        }}
      >
        Ah que pena...
      </Typography>
      {(isMonthlySubscription || isSeasonSubscription) && (
        <>
          <Typography
            sx={{
              color: 'grey.400',
              fontFamily: 'secondary',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            Sua assinatura estará ativa até{' '}
            <Typography
              sx={{
                display: 'inline',
                fontFamily: 'secondary',
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              {formattedEndDate}
            </Typography>
          </Typography>
          <Typography
            sx={{
              color: 'grey.400',
              fontFamily: 'secondary',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            Fique tranquilo que seu plano{' '}
            <Typography
              sx={{
                display: 'inline',
                fontFamily: 'primary',
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              {subscription.title}{' '}
            </Typography>
            permanece ativo.
          </Typography>
        </>
      )}
      {isPartnerSubscription &&
        !hasOnlyOnePlan &&
        (haveTwoPlans ? (
          <Typography
            sx={{
              color: 'grey.400',
              fontFamily: 'secondary',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            Fique tranquilo que seu plano{' '}
            <Typography
              sx={{
                display: 'inline',
                fontFamily: 'primary',
                fontSize: 14,
              }}
            >
              {removePlanBeingCanceled[0].title}{' '}
            </Typography>
            permanece ativo.
          </Typography>
        ) : (
          <Typography
            sx={{
              color: 'grey.400',
              fontFamily: 'secondary',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            Fique tranquilo que os outros planos permanecem ativos.
          </Typography>
        ))}
      {hasOnlyOnePlan && (
        <Typography
          sx={{
            color: 'grey.400',
            fontFamily: 'secondary',
            fontSize: 14,
            textAlign: 'center',
          }}
        >
          Sentiremos a sua falta.
          <br />
          Esperamos que volte em breve!
        </Typography>
      )}
      <Button
        data-testid="exitButton"
        color="error"
        size="large"
        fullWidth
        sx={{ height: 60, mt: 3 }}
        onClick={() => {
          if (hasOnlyOnePlan && isSubscriptionFree) {
            deleteSubscription(subscription.userPlanId);
          }
          dialog.close();
        }}
      >
        Continuar
      </Button>
    </Stack>
  );
};

export default SubscriptionCancelationModal;

import { Stack, Typography } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { SxProps } from '@material-ui/system';
import React from 'react';

interface EmptyStateProps {
  title: string;
  description: string;
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps;
}

const EmptyState = ({ title, description, sx }: EmptyStateProps) => (
  <Stack
    sx={{
      alignItems: 'center',
      bgcolor: 'transparent',
      height: 140,
      justifyContent: 'center',
      width: '100%',
      ...sx,
    }}
  >
    <SearchIcon sx={{ color: 'secondary.main', height: 48, width: 48 }} />
    <Typography
      sx={{
        color: 'common.white',
        fontSize: 20,
        fontWeight: 600,
        textAlign: 'center',
      }}
    >
      {title}
    </Typography>
    <Typography
      sx={{ color: 'grey.300', fontSize: 16, mt: 1, textAlign: 'center' }}
    >
      {description}
    </Typography>
  </Stack>
);

export default EmptyState;

import { Grid, Typography, Stack, Box } from '@material-ui/core';
import BodyItem from './BodyItem';

interface TournamentsBodyProps {
  teamData: any;
  visibilityToggle: boolean;
}

const TournamentsBody = ({
  teamData,
  visibilityToggle,
}: TournamentsBodyProps) => {
  const tableVisibilityHandler = (data: number) => {
    if (visibilityToggle) {
      return data;
    }
    return 0;
  };
  return (
    <Grid container>
      <Grid item xs={2} sx={{ bgcolor: 'grey.600', minWidth: 204 }}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={4}
          sx={{
            alignItems: 'center',
            color: 'common.white',
            pl: { xs: 4, lg: 5 },
            py: 3,
          }}
        >
          <Typography sx={{ textAlign: 'right', width: 20 }}>
            {teamData.position}
          </Typography>
          <Box
            sx={{
              bgcolor: 'common.white',
              borderRadius: 1,
              height: 32,
              width: 32,
            }}
          />
          <Typography
            sx={{ color: 'common.white', fontSize: 16, fontWeight: 600 }}
          >
            {teamData.title}
          </Typography>
        </Stack>
      </Grid>
      <BodyItem title={tableVisibilityHandler(teamData.label)} isFirst />
      <BodyItem title={tableVisibilityHandler(teamData.label)} />
      <BodyItem title={tableVisibilityHandler(teamData.label)} />
      <BodyItem title={tableVisibilityHandler(teamData.label)} />
      <BodyItem title={tableVisibilityHandler(teamData.label)} />
      <BodyItem title={tableVisibilityHandler(teamData.label)} />
      <BodyItem title={tableVisibilityHandler(teamData.label)} />
      <BodyItem title={tableVisibilityHandler(teamData.label)} />
      <BodyItem title={tableVisibilityHandler(teamData.label)} />
    </Grid>
  );
};

export default TournamentsBody;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import Navbar from './components/Navbar';
import Context from './context';

const Header = ({ headerRef, isLogged, isOnTop }: { isLogged?: boolean, headerRef?: any, isOnTop?: boolean }) => {
  const [open, setOpen] = useState(false);
  const toggleMenuHandler = () => setOpen((previousState) => !previousState);
  const closeMenuHandler = () => setOpen(false);
  return (
    <Context.Provider value={{ open, closeMenuHandler, toggleMenuHandler }}>
      <Navbar headerRef={headerRef} isOnTop={isOnTop} isLogged={!!isLogged} />
    </Context.Provider>
  );
};

export default Header;

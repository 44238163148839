import { PaymentStatus } from '../typings';

const paymentStatusMsgMapper: Record<PaymentStatus, string> = {
  BadRequest: 'Ops, ocorreu algum erro, tente novamente.',
  AccountError: 'Ops, ocorreu um erro com o seu cartão de crédito.',
  Awaiting: 'Aguardando pagamento...',
  FreeOrPartnerAwaiting: 'Aguardando...',
  CardDeclined: 'Parece que a forma de pagamento informada possui um problema.',
  CouponDeclined: 'Ops, ocorreu um erro ao efetuar pagando utilizando o cupom.',
  GenericError: 'Ops, revise seus dados de pagamento',
  InsuficientBalance: 'Ops, parece que não há saldo suficiente no seu cartão.',
  Success: 'Sucesso',
  SigninError: 'Seu registro foi realizado com sucesso, faça login para acessar o conteúdo.',
  UserCardError: 'Parece que a forma de pagamento informada possui um problema.',
  CPFOrEmailExists: 'Seus dados de cadastro não estão corretos, ou já existe uma conta com eles',
  PurchaseInProgress: 'Sua tentativa de compra anterior ainda está em andamento. Por favor, aguarde 10 minutos.'
};

export default paymentStatusMsgMapper;

import { Grid } from '@material-ui/core';
import RetentionDiscountCardPlaceholder from './RetentionDiscountCardPlaceholder';

const RetentionDiscountCardPlaceholderList = () => (
  <>
    {[...Array(3)].map(() => (
      <Grid item xs={12} lg={4}>
        <RetentionDiscountCardPlaceholder />
      </Grid>
    ))}
  </>
);

export default RetentionDiscountCardPlaceholderList;

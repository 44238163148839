import { AccordionDetails, Stack, Typography } from '@material-ui/core';

interface AccordeonItemsProps {
  primaryText: string;
  secondaryText: string;
}

const AccordeonItems = ({
  primaryText,
  secondaryText,
}: AccordeonItemsProps) => (
  <AccordionDetails sx={{ p: 0 }}>
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
        {primaryText}
      </Typography>
      <Typography sx={{ color: 'secondary.main' }}>{secondaryText}</Typography>
    </Stack>
  </AccordionDetails>
);

export default AccordeonItems;

import axios, { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import LocalStorageService from 'services/local-storage.service';
import { LocalStorageKeys } from 'utils/localStorageKeys';
import environment from '../environment.config';

const axiosHelper = axios.create({
  baseURL: environment.ApiBaseUrl,
  withCredentials: true,
  timeout: environment.ApiTimeout,
});

export const isAxiosError = (err: any): err is AxiosError => err.isAxiosError;

axiosHelper.interceptors.response.use(
  (config) => config,
  (error) => {
    const statusCode = error?.response?.status;
    const isUnauthorized =
      statusCode === StatusCodes.UNAUTHORIZED ||
      statusCode === StatusCodes.FORBIDDEN;
    if (isUnauthorized) {
      new LocalStorageService().delete(LocalStorageKeys.LOGGED_IN);
      window.location.href = `${process.env.REACT_APP_OUTPUT_PATH}${process.env.REACT_APP_BASENAME}`;
    }
    return Promise.reject(error);
  },
);

export default axiosHelper;

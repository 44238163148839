// @eslint-ignore react/prop-types
import { ForwardRefRenderFunction } from 'react';
import Grid from '@material-ui/core/Grid';
import { Stack, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { SectionDataDTO } from 'services/Section/SectionService.types';
import { useLocation } from 'react-router-dom';
import SectionHero from './SectionHero';
import SectionContentLine from './SectionContentLine';
import ContentsGrid from './ContentsGrid';

interface SectionLineProps {
  sectionData: SectionDataDTO;
}

const SectionDetail: ForwardRefRenderFunction<
  HTMLDivElement,
  SectionLineProps
> = ({ sectionData }) => {
  const { pathname } = useLocation();
  const isFavoriteTeamSection = pathname === '/team-content';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { featuredContent, contentList } = sectionData;
  const hasFeaturedContents = featuredContent && featuredContent?.length > 0;
  const hasSubsections = !!sectionData.subSections;
  const sectionIsLive = sectionData.isLive;
  return (
    <Stack
      key={sectionData.id}
      sx={{
        color: 'grey.300',
        minHeight: 'calc(100vh - 150px)',
        fontSize: 16,
        transition: 'all 0.2s',
        ':hover': {
          color: 'common.white',
        },
      }}
    >
      {hasFeaturedContents && <SectionHero data={sectionData} />}
      <Stack
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: '100%',
          px: { xs: 2, lg: hasSubsections && !sectionIsLive ? 0 : '92px' },
          pt: !hasFeaturedContents ? { xs: 2, lg: '80px' } : 0,
          maxWidth: 1900,
          m: '0 auto',
          mt: {
            xs: hasFeaturedContents ? '-100px' : 0,
            lg: hasFeaturedContents ? '-200px' : 0,
          },
        }}
      >
        {(hasSubsections && !sectionIsLive) ||
        (isFavoriteTeamSection && hasSubsections) ? (
          <SectionContentLine contentData={sectionData} />
        ) : (
          <>
            <Grid
              container
              justifyContent={isMobile ? 'center' : 'flex-start'}
              sx={{ mt: '30px', zIndex: 1100 }}
            >
              <Typography
                sx={{
                  fontFamily: 'primary',
                  fontSize: 26,
                  color: 'common.white',
                  marginBottom: '26px',
                  display: sectionIsLive ? 'none' : 'block',
                }}
              >
                {sectionData.title}
              </Typography>
            </Grid>
            <ContentsGrid contentList={contentList} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default SectionDetail;

import { Stack, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDialog } from '../../../../components/Dialog';
import ActionButtons from './components/ActionButtons';
import Context from './Context';
import ProfileContext from '../../../../Context';
import AccountCancellationModal from './components/AccountCancellationModal';
import ProfileForm from './components/ProfileForm';
import ProfileFormPlaceholder from './components/ProfileFormPlaceholder';
import SaveChangesModal from './components/SaveChangesModal';
import validationSchema from './providers/validationSchema';
import saveUserProfile from './services/saveUserProfile';
import { UserProfileForm, UserProfilePayload } from './typings';

const Profile = () => {
  const alert = useAlert();
  const dialog = useDialog();
  const [isEditing, setIsEditing] = useState(false);
  const { profile, isLoadingUser } = useContext(ProfileContext);
  const form = useFormik<UserProfileForm>({
    enableReinitialize: true,
    initialValues: {
      name: profile?.name ?? '',
      email: profile?.email ?? '',
      phone: profile?.phone ?? '',
      usercpf: profile?.usercpf ?? '',
    },
    validationSchema,
    onSubmit: async ({ name, phone }) => {
      try {
        setIsEditing(true);
        dialog.close();
        const input: UserProfilePayload = { name, phone };
        await saveUserProfile(input);
        alert.success('Alterações salvas com sucesso!');
      } catch (error: any) {
        alert.error('Ocorreu um erro ao salvar as alterações!');
      } finally {
        setIsEditing(false);
      }
    },
  });
  const openDeleteModal = async () => {
    await dialog.open({
      element: <AccountCancellationModal />,
      sx: {
        bgcolor: 'common.white',
        height: 375,
        maxWidth: 372,
        width: '100%',
      },
    });
  };
  const saveChanges = async () => {
    await dialog.open({
      element: <SaveChangesModal onSubmit={form.handleSubmit} />,
      sx: {
        bgcolor: 'common.white',
        height: 239,
        maxWidth: 372,
        width: '100%',
      },
    });
  };
  const allowedToSubmit = !form.dirty;
  return (
    <Context.Provider value={{ form, isLoadingUser }}>
      <Stack
        sx={{
          alignItems: 'center',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          sx={{
            alignItems: 'center',
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Stack
            sx={{ mt: { lg: 0 }, width: { xs: '100%', lg: '50%' } }}
            spacing={4}
          >
            <Typography
              sx={{
                color: 'grey.100',
                fontFamily: 'primary',
                fontSize: { xs: 20, lg: 16 },
              }}
            >
              Dados pessoais
            </Typography>
            {isLoadingUser ? <ProfileFormPlaceholder /> : <ProfileForm />}
          </Stack>
        </Stack>
        {!isLoadingUser && (
          <ActionButtons
            isEditing={isEditing}
            saveChanges={saveChanges}
            onClick={openDeleteModal}
            allowedToSubmit={allowedToSubmit}
          />
        )}
      </Stack>
    </Context.Provider>
  );
};

export default Profile;

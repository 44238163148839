import { Button, Stack, Typography } from '@material-ui/core';
import { useDialog } from '../../../../../../components/Dialog';

interface ConfirmPasswordChangeModalProps {
  onSubmit: () => void;
}

const ConfirmPasswordChangeModal = ({
  onSubmit,
}: ConfirmPasswordChangeModalProps) => {
  const dialog = useDialog();
  const submitForm = () => {
    dialog.close();
    onSubmit();
  };
  return (
    <Stack sx={{ alignItems: 'center', justifyContent: 'space-around' }}>
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          left: '50%',
          position: 'absolute',
          textAlign: 'center',
          transform: 'translateX(-50%)',
          top: 25,
          width: '100%',
        }}
      >
        Salvar alterações
      </Typography>
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'secondary',
          fontSize: 14,
          fontWeight: 400,
          mt: 2,
          textAlign: 'center',
        }}
      >
        Você tem certeza que deseja salvar as alterações preenchidas ?
      </Typography>
      <Button
        data-testid="confirmChangesButton"
        sx={{
          height: 60,
          mt: { xs: 3, lg: 6 },
          width: 291,
        }}
        onClick={submitForm}
      >
        Sim, tenho certeza
      </Button>
    </Stack>
  );
};

export default ConfirmPasswordChangeModal;

import { Avatar, Button, Stack, Typography } from '@material-ui/core';
import { useDialog } from 'components/Dialog';
import { useNavigate } from 'react-router-dom';
import useRegisterLocalStorage from 'utils/hooks/use-register-local-storage';
import errorIcon from '../../assets/png/errorIcon.png';
import successIcon from '../../assets/png/successIcon.png';

interface FeedbackModalProps {
  approved?: boolean;
  hasActivePlans?: boolean;
  setUserIsSuspended?: (isFirstAccess: boolean) => void;
}

const FeedbackModal = ({
  approved,
  hasActivePlans,
  setUserIsSuspended,
}: FeedbackModalProps) => {
  const dialog = useDialog();
  const navigate = useNavigate();
  const { clearRegisterLocalStorage } = useRegisterLocalStorage();
  const titleLabel = approved
    ? 'Pronto, seu pagamento foi aprovado!'
    : 'Algo deu errado!';
  const descriptionLabel = approved
    ? 'Seu pagamento foi efetuado com sucesso, clique em continuar para acessar seus conteúdos.'
    : 'Infelizmente seu pagamento não foi finalizado, tente novamente ou entre em contato com a agência responsável pelo seu cartão.';
  const buttonLabel = approved ? 'Continuar' : 'Tentar novamente';
  const buttonColor = approved ? 'secondary.main' : 'error.main';
  const logout = () => {
    clearRegisterLocalStorage();
    navigate('/');
    dialog.close();
  };
  const confirmModal = () => {
    if (approved && setUserIsSuspended) {
      setUserIsSuspended(false);
    }
    dialog.close();
    navigate('/home');
  };
  return (
    <Stack alignItems="center" gap={4}>
      {approved ? (
        <Avatar src={successIcon} sx={{ height: 64, width: 64 }} />
      ) : (
        <Avatar src={errorIcon} sx={{ height: 64, width: 64 }} />
      )}
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          textAlign: 'center',
        }}
      >
        {titleLabel}
      </Typography>
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'secondary',
          fontSize: 14,
          textAlign: 'center',
        }}
      >
        {descriptionLabel}
      </Typography>
      <Button
        sx={{
          bgcolor: buttonColor,
          height: 60,
          width: '100%',
          '&:hover': {
            bgcolor: approved ? 'auto' : 'error.main',
          },
        }}
        onClick={confirmModal}
      >
        {buttonLabel}
      </Button>
      {hasActivePlans && (
        <Button
          sx={{
            border: '1px solid',
            borderColor: buttonColor,
            bgcolor: 'common.white',
            color: 'error.main',
            height: 60,
            width: '100%',
            '&:hover': {
              bgcolor: 'common.white',
            },
          }}
          onClick={logout}
        >
          Sair do Paulistão Play
        </Button>
      )}
    </Stack>
  );
};

export default FeedbackModal;

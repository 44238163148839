/* eslint-disable react/require-default-props */
import { Box, Stack, Typography } from '@material-ui/core';
import React from 'react';
import useLoggedIn from 'utils/hooks/use-logged-in';
import logo from '../../../assets/png/logoW.png';
import PlaceholderNavigationButton from '../components/PlaceholderNavigationButton';

const PageNotFound = () => {
  const { get } = useLoggedIn();
  const isLoggedIn = !!get();
  const destination = isLoggedIn ? '/home' : '/';
  const buttonLabel = isLoggedIn ? 'Voltar à plataforma' : 'Voltar ao login';
  return (
    <Stack
      sx={{
        alignItems: 'center',
        color: 'common.white',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <Box
        title="Paulistão Play"
        sx={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: { xs: 26, lg: 44 },
          mb: { xs: 4, lg: 8 },
          width: { xs: 88, lg: 148 },
        }}
      />
      <Typography sx={{ fontSize: { xs: 24, lg: 32 }, fontWeight: 600 }}>
        Ops! Página não encontrada...
      </Typography>
      <Typography sx={{ fontSize: { xs: 14, lg: 20 }, mt: { xs: 3, lg: 6 } }}>
        Ocorreu algum erro que te trouxe até aqui. Mas não se preocupe, você
        pode voltar para a plataforma.
      </Typography>
      <PlaceholderNavigationButton
        destination={destination}
        label={buttonLabel}
      />
    </Stack>
  );
};

export default PageNotFound;

import qs from 'qs';
import axiosHelper from '../../../../../helpers/axios';

interface CheckRequest {
  email: string;
  usercpf: string;
}

export const emailAndcpfCheck = (data: CheckRequest) =>
  axiosHelper.post<{ message: string }>('/signup/check', qs.stringify(data), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

import creditCardValidator from 'card-validator';
import { PaymentMethod } from 'interfaces/BillingHistory';
import { Plan, PlanType } from 'interfaces/plan';
import { isValidCpf } from 'modules/auth/pages/Register/utils';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object().shape({
    usercpf: yup
      .string()
      .required('Campo obrigatório')
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
      // @ts-ignore
      .test('cpfValidator', 'CPF inválido', (cpf) => {
        const cpfIsEmpty = !cpf;
        if (cpfIsEmpty) {
          return cpf;
        }
        const newCpf = cpf!.replace(/[.-]/g, '');
        const validCpf = isValidCpf(newCpf);
        return validCpf;
      }),
    phone: yup
      .string()
      .trim()
      .matches(/^\(\d{2}\)\s9\d{4}-\d{4}$/, 'Celular inválido')
      .required('Campo obrigatório'),
  });

export const defaultValidationSchema = (
  plan: Plan | undefined,
  hasCard: boolean,
) => {
  const planType = plan!?.type || '';
  const isFreeOrPartnerPlan = [PlanType.free, PlanType.partner]?.includes(
    planType,
  );
  return yup.object().shape({
    paymentMethod:
      planType === 'free' || hasCard
        ? yup.mixed<PaymentMethod>()
        : yup
            .mixed<PaymentMethod>()
            .required('Selecione pelo menos uma forma de pagamento!'),
    creditCard:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'credit',
            then: yup
              .string()
              .required('Campo obrigatório')
              .test(
                'credit',
                'Cartão de crédito inválido',
                (credit) => creditCardValidator.number(credit).isValid,
              ),
          }),
    expirationDate:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'credit',
            then: yup
              .string()
              .required('Campo obrigatório')
              .matches(/^[0-9]{2}\/[0-9]{2}$/, 'Data inválida')
              .test('expiration-date', 'Data inválida', (expirationDate) => {
                const expirationDateIsEmpty = !expirationDate;
                if (expirationDateIsEmpty) {
                  return false;
                }
                const today = new Date();
                const monthToday = today.getMonth() + 1;
                const yearToday = today.getFullYear().toString().substr(-2);
                const [expMonth, expYear] = expirationDate!.split('/');
                const currentYearIsLessThanInputYear = +expYear < +yearToday;
                if (currentYearIsLessThanInputYear) {
                  return false;
                }
                const dateIsPast =
                  +expMonth < monthToday && +expYear <= +yearToday;
                if (dateIsPast) {
                  return false;
                }
                return true;
              }),
          }),
    cardHolderName:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'credit',
            then: yup.string().required('Campo obrigatório'),
          }),
    cvv:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'credit',
            then: yup.string().required('Campo obrigatório'),
          }),
    cpf:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'credit',
            then: yup
              .string()
              .required('Campo obrigatório')
              .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
              // @ts-ignore
              .test('cpfValidator', 'CPF inválido', (cpf) => {
                const cpfIsEmpty = !cpf;
                if (cpfIsEmpty) {
                  return cpf;
                }
                const newCpf = cpf!.replace(/[.-]/g, '');
                const validCpf = isValidCpf(newCpf);
                return validCpf;
              }),
          }),
    debitCard:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'debit',
            then: yup
              .string()
              .required('Campo obrigatório')
              .test(
                'credit',
                'Cartão de crédito inválido',
                (credit) => creditCardValidator.number(credit).isValid,
              ),
          }),
    debitExpirationDate:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'debit',
            then: yup
              .string()
              .required('Campo obrigatório')
              .matches(/^[0-9]{2}\/[0-9]{2}$/, 'Data inválida')
              .test('expiration-date', 'Data inválida', (expirationDate) => {
                const expirationDateIsEmpty = !expirationDate;
                if (expirationDateIsEmpty) {
                  return false;
                }
                const today = new Date();
                const monthToday = today.getMonth() + 1;
                const yearToday = today.getFullYear().toString().substr(-2);
                const [expMonth, expYear] = expirationDate!.split('/');
                const currentYearIsLessThanInputYear = +expYear < +yearToday;
                if (currentYearIsLessThanInputYear) {
                  return false;
                }
                const dateIsPast =
                  +expMonth < monthToday && +expYear <= +yearToday;
                if (dateIsPast) {
                  return false;
                }
                return true;
              }),
          }),
    debitCardHolderName:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'debit',
            then: yup.string().required('Campo obrigatório'),
          }),
    debitCVV:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'debit',
            then: yup.string().required('Campo obrigatório'),
          }),
    debitCPF:
      isFreeOrPartnerPlan || hasCard
        ? yup.string()
        : yup.string().when('paymentMethod', {
            is: 'debit',
            then: yup
              .string()
              .required('Campo obrigatório')
              .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
              // @ts-ignore
              .test('cpfValidator', 'CPF inválido', (cpf) => {
                const cpfIsEmpty = !cpf;
                if (cpfIsEmpty) {
                  return cpf;
                }
                const newCpf = cpf!.replace(/[.-]/g, '');
                const validCpf = isValidCpf(newCpf);
                return validCpf;
              }),
          }),
    hasCoupon: yup.boolean(),
    coupon: yup.string().when('hasCoupon', {
      is: true,
      then: yup.string().required('Campo obrigatório'),
    }),
    agreeWithData: yup.bool().oneOf([true]),
    agreeWithTerms: yup.bool().oneOf([true]),
  });
};

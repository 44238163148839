/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import useIsPortrait from 'modules/auth/pages/Register/hooks/use-is-portrait';

interface WithChatProps {
  children: React.ReactNode;
  chatEmbed?: string;
}

const WithChatPlayer = ({ children, chatEmbed }: WithChatProps) => {
  const isPortrait = useIsPortrait();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  if (isMobile || isPortrait) {
    return (
      <Grid container spacing={2} style={{ height: '100vh' }}>
        <Grid item xs={12} style={{ height: '55%' }}>
          {children}
        </Grid>
        <Grid item xs={12} style={{ height: '40%' }}>
          <iframe
            title="chat"
            src={chatEmbed}
            style={{
              border: 0,
              width: '100%',
              height: '100%',
              borderRadius: '4px',
              background: '#dcd3d3',
            }}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={7} md={8} lg={9}>
        {children}
      </Grid>
      <Grid item xs={5} md={4} lg={3}>
        <iframe
          title="chat"
          src={chatEmbed}
          style={{
            border: 0,
            width: '100%',
            height: '100%',
            borderRadius: '4px',
            background: '#dcd3d3',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default WithChatPlayer;

import { useEffect, useState } from 'react';

const useIsOnTop = (scrollableNodeRef: any) => {
  const [isOnTop, setIsOnTop] = useState(false);
  useEffect(() => {
    const scrollHandler = () => {
      const hasNoScrollableNodeRef = !scrollableNodeRef.current;
      if (hasNoScrollableNodeRef) {
        return;
      }
      const { scrollTop } = scrollableNodeRef.current;
      const scrollIsNearToTop = scrollTop > 10;
      setIsOnTop(scrollIsNearToTop);
    };
    scrollableNodeRef.current?.addEventListener('scroll', scrollHandler);
    return () =>
      scrollableNodeRef.current?.removeEventListener(
        'scroll',
        scrollHandler,
        true,
      );
  });
  return isOnTop;
};

export default useIsOnTop;

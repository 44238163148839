import { createContext } from 'react';

interface IContext {
  open: boolean;
  closeMenuHandler: () => void;
  toggleMenuHandler: () => void;
}

const Context = createContext<IContext>({
  open: false as boolean,
  closeMenuHandler: () => {},
  toggleMenuHandler: () => {},
});

export default Context;

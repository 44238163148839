import axiosHelperV2 from 'helpers/axiosV2';
import { ContentResponse, SubSectionContentDTO } from 'services/Section/SectionService.types';

interface SearchResponse {
  content: SubSectionContentDTO[];
  results: number;
}

export const getCotentsByTeam = (treeHash?: string, id?: string) =>
  axiosHelperV2.get<ContentResponse>(`/content/${treeHash}/tag/${id}`);

export const searchContents = (query: string) =>
  axiosHelperV2.get<SearchResponse>(`/search?s=${query}`);

import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { VideoContentCard } from './VideoContentCard';

interface ContentsGridProps {
  contentList?: {
    color: string;
    description: string;
    featured: boolean;
    id: string;
    position: number;
    thumbnail: string;
    title: string;
    type: string;
  }[];
}

const ContentsGrid = ({ contentList }: ContentsGridProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Grid container justifyContent={isMobile ? 'center' : 'flex-start'}>
      {contentList?.map((eachContent) => (
        <Grid
          key={eachContent.id}
          item
          sx={{
            mb: { xs: 4, lg: 7 },
            mr: { xs: 0, sm: 4, lg: 4 },
            width: {
              xs: '100%',
              sm: 'calc((100%/2) - 16px)',
              lg: 'calc((100%/5) - 16px)',
            },
            ':nth-child(2n)': {
              mb: { sm: 0, lg: 7 },
              mr: { xs: 0, lg: 4 },
            },
            ':nth-child(5n)': { mr: { lg: 0 } },
          }}
        >
          <VideoContentCard
            data={eachContent}
            isGridVersion
            thumbnailSx={{
              width: { xs: '100%', sm: '100%', lg: '100%' },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ContentsGrid;

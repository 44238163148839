import qs from 'qs';
import { Discount } from '../../interfaces/Discount';
import { Plan } from '../../interfaces/plan';
import axiosHelper from '../../helpers/axios';
import { Page } from './typings';

interface DataProps {
  discountId: string;
  planId: string;
  userPlanId: string;
  userId: string;
}

export const getPage = (id: string) => axiosHelper.get<Page>(`/page/${id}`);

export const getSubscriptions = () =>
  axiosHelper.get<Plan[]>('/user/subscriptions');

export const getRententionDiscounts = (planId?: string) =>
  axiosHelper.get<Discount[]>(`/plans/${planId}/retentionDiscounts`);

export const acceptRententionDiscount = (data: DataProps) =>
  axiosHelper.post('/user/subscriptions/retention', qs.stringify(data), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

export const doLogout = (): Promise<any> => axiosHelper.get('/auth/logout');

import { ContentType } from '../../../interfaces/Content';

const ContentTypeFriendly = {
  [ContentType.external]: 'Externo',
  [ContentType.live]: 'Ao vivo',
  [ContentType.video]: 'Vídeo',
  [ContentType.vod]: 'Vod',
  'scheduled': 'Agendado',
};

const getContentTypeFriendlyName = (contentType: ContentType) =>
  ContentTypeFriendly[contentType];

export default getContentTypeFriendlyName;

import { format, formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// TODO: check generated phrases within system requirements
export const getFriendlyDate = (date: Date): string =>
  formatDistance(
    parseISO(new Date(date).toISOString()),
    parseISO(new Date().toISOString()),
    { locale: ptBR },
  );

export const formatDate = (date: Date, dateFormat: string): string =>
  format(parseISO(new Date(date).toISOString()), dateFormat, {
    locale: ptBR,
  });

import creditCardValidator from 'card-validator';
import { PaymentMethod } from 'interfaces/BillingHistory';
import { isValidCpf } from 'modules/auth/pages/Register/utils';
import * as yup from 'yup';

export const validationSchema = () =>
  yup.object().shape({
    paymentMethod: yup
      .mixed<PaymentMethod>()
      .required('Selecione pelo menos uma forma de pagamento!'),
    creditCard: yup.string().when('paymentMethod', {
      is: 'credit',
      then: yup
        .string()
        .required('Campo obrigatório')
        .test(
          'credit',
          'Cartão de crédito inválido',
          (credit) => creditCardValidator.number(credit).isValid,
        ),
    }),
    expirationDate: yup.string().when('paymentMethod', {
      is: 'credit',
      then: yup
        .string()
        .required('Campo obrigatório')
        .matches(/^[0-9]{2}\/[0-9]{2}$/, 'Data inválida')
        .test('expiration-date', 'Data inválida', (expirationDate) => {
          const expirationDateIsEmpty = !expirationDate;
          if (expirationDateIsEmpty) {
            return false;
          }
          const today = new Date();
          const monthToday = today.getMonth() + 1;
          const yearToday = today.getFullYear().toString().substr(-2);
          const [expMonth, expYear] = expirationDate!.split('/');
          const currentYearIsLessThanInputYear = +expYear < +yearToday;
          if (currentYearIsLessThanInputYear) {
            return false;
          }
          const dateIsPast = +expMonth < monthToday && +expYear <= +yearToday;
          if (dateIsPast) {
            return false;
          }
          return true;
        }),
    }),
    cardHolderName: yup.string().when('paymentMethod', {
      is: 'credit',
      then: yup.string().required('Campo obrigatório'),
    }),
    cvv: yup.string().when('paymentMethod', {
      is: 'credit',
      then: yup.string().required('Campo obrigatório'),
    }),
    cpf: yup.string().when('paymentMethod', {
      is: 'credit',
      then: yup
        .string()
        .required('Campo obrigatório')
        .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
        // @ts-ignore
        .test('cpfValidator', 'CPF inválido', (cpf) => {
          const cpfIsEmpty = !cpf;
          if (cpfIsEmpty) {
            return cpf;
          }
          const newCpf = cpf!.replace(/[.-]/g, '');
          const validCpf = isValidCpf(newCpf);
          return validCpf;
        }),
    }),
    debitCard: yup.string().when('paymentMethod', {
      is: 'debit',
      then: yup
        .string()
        .required('Campo obrigatório')
        .test(
          'credit',
          'Cartão de crédito inválido',
          (credit) => creditCardValidator.number(credit).isValid,
        ),
    }),
    debitExpirationDate: yup.string().when('paymentMethod', {
      is: 'debit',
      then: yup
        .string()
        .required('Campo obrigatório')
        .matches(/^[0-9]{2}\/[0-9]{2}$/, 'Data inválida')
        .test('expiration-date', 'Data inválida', (expirationDate) => {
          const expirationDateIsEmpty = !expirationDate;
          if (expirationDateIsEmpty) {
            return false;
          }
          const today = new Date();
          const monthToday = today.getMonth() + 1;
          const yearToday = today.getFullYear().toString().substr(-2);
          const [expMonth, expYear] = expirationDate!.split('/');
          const currentYearIsLessThanInputYear = +expYear < +yearToday;
          if (currentYearIsLessThanInputYear) {
            return false;
          }
          const dateIsPast = +expMonth < monthToday && +expYear <= +yearToday;
          if (dateIsPast) {
            return false;
          }
          return true;
        }),
    }),
    debitCardHolderName: yup.string().when('paymentMethod', {
      is: 'debit',
      then: yup.string().required('Campo obrigatório'),
    }),
    debitCVV: yup.string().when('paymentMethod', {
      is: 'debit',
      then: yup.string().required('Campo obrigatório'),
    }),
    debitCPF: yup.string().when('paymentMethod', {
      is: 'debit',
      then: yup
        .string()
        .required('Campo obrigatório')
        .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
        // @ts-ignore
        .test('cpfValidator', 'CPF inválido', (cpf) => {
          const cpfIsEmpty = !cpf;
          if (cpfIsEmpty) {
            return cpf;
          }
          const newCpf = cpf!.replace(/[.-]/g, '');
          const validCpf = isValidCpf(newCpf);
          return validCpf;
        }),
    }),
  });

import { ContentResponse } from 'services/Section/SectionService.types';

export const useSectionData = (data: ContentResponse) => {
  const sectionContentKeys = Object?.keys(data?.sectionContent);
  const sectionsData = data?.section?.map((eachKey) => ({
    id: eachKey.id,
    title: eachKey.title,
    position: eachKey.position,
    contentList: data.sectionContent[eachKey.id],
    isLive: eachKey.live,
    superSection: eachKey.superSection,
    superSectionItems: data?.superSection,
    subSections: data.subSection[eachKey.id],
    subSectionsContent: data.subSectionContent,
    featuredContent: eachKey.featuredContent,
  }));
  return {
    sectionContentKeys,
    sectionsData: sectionsData?.sort(
      (a: { position: number }, b: { position: number }) =>
        a.position - b.position,
    ),
  };
};

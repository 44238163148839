import { useMatch, useSearchParams } from 'react-router-dom';
import { useSectionData } from 'modules/home/refactor/use-section-data';
import { useQuery } from 'react-query';
import { getSectionsV2 } from 'services/Section/SectionService';
import { ContentResponse } from 'services/Section/SectionService.types';
import { ReactQueryNames } from 'react-query/react-query-names';
import { Stack, Typography } from '@material-ui/core';
import { useContext, useEffect, useRef } from 'react';
import { HomePlaceholder } from 'modules/home/refactor/components/HomePlaceholder';
import {
  getCotentsByTeam,
  searchContents,
} from 'modules/home/refactor/services';
import ContentsGrid from 'modules/home/refactor/components/ContentsGrid';
import Footer from 'components/Footer/Footer';
import Context from '../../Context';
import SearchEmptyState from './components/SearchEmptyState';
import SearchLoading from './components/SearchLoading';
import ContentsData from './components/ContentsData';

const SectionContent = () => {
  const { profile } = useContext(Context);
  const userTreeHash = profile!.treeHash;
  const [params] = useSearchParams();
  const searchKey = params.get('s') as string;
  const favoriteTeamKey = params.get('pathIsTeam') as string;
  const teamId = profile?.favoriteTeamId;
  const fieldRef = useRef<HTMLDivElement>(null);
  const { isLoading, data } = useQuery(ReactQueryNames.SECTIONSV2, () =>
    getSectionsV2(userTreeHash),
  );
  const match = useMatch('/section-content/:item');
  const locationParams = match?.params.item;
  const pathIsTeam = favoriteTeamKey === 'true';
  const pathIsSearch = !!searchKey;
  const { isLoading: teamContentIsLoading, data: teamContentData } = useQuery(
    [pathIsTeam],
    () => (pathIsTeam ? getCotentsByTeam(userTreeHash, teamId) : null),
  );
  const { isLoading: searchIsLoading, data: searchResults } = useQuery(
    [searchKey],
    () => (pathIsSearch ? searchContents(searchKey) : null),
  );
  const searchContentsResults = searchResults?.data.content;
  const resultsNumber = searchResults?.data.results;
  const sectionIsLive = locationParams === 'live';
  const favoriteTeamData =
    data && teamContentData?.data
      ? teamContentData.data
      : ({
          sectionContent: {},
        } as ContentResponse);
  const sections =
    data && data.data
      ? data.data
      : ({
          sectionContent: {},
        } as ContentResponse);
  const { sectionsData } = pathIsTeam
    ? useSectionData(favoriteTeamData)
    : useSectionData(sections);
  const contentsData = sectionsData?.filter(
    (item) => item.id === locationParams || (item.isLive && sectionIsLive),
  );
  useEffect(() => {
    fieldRef.current?.scrollIntoView();
  }, []);
  if (searchIsLoading && pathIsSearch) return <SearchLoading />;
  if (searchContentsResults?.length === 0 && pathIsSearch)
    return <SearchEmptyState searchKey={searchKey} />;
  if (isLoading || (pathIsTeam && teamContentIsLoading))
    return <HomePlaceholder isContent />;
  return (
    <>
      <Stack
        ref={fieldRef}
        sx={{
          width: '100%',
          height: 'auto',
          minHeight: 'calc(100vh - 173px)',
          marginBottom: 0,
        }}
      >
        {pathIsSearch ? (
          <Stack
            gap={4}
            sx={{ mt: { xs: 7, lg: '104px' }, px: { xs: 2, lg: '92px' } }}
          >
            {resultsNumber && (
              <Typography
                sx={{
                  color: 'grey.300',
                  fontFamily: 'secondary',
                  fontSize: 14,
                  ml: 2,
                }}
              >
                {resultsNumber} resultados encontrados
              </Typography>
            )}
            <ContentsGrid contentList={searchContentsResults} />
          </Stack>
        ) : (
          <ContentsData
            contentsData={contentsData}
            sectionIsLive={sectionIsLive}
          />
        )}
      </Stack>
      <Footer />
    </>
  );
};

export default SectionContent;

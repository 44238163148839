import { DealStatus } from 'interfaces/BillingHistory';

const dealStatusName = {
  [DealStatus.activation]: 'Contratação do plano',
  [DealStatus.reactivation]: 'Reativação do plano',
  [DealStatus.renewal]: 'Renovação do plano',
  [DealStatus.reactivationPreCanceled]: 'Reativação do plano pré cancelado',
};

export const getFriendlyNameToDealStatus = (dealStatus?: DealStatus): string =>
  dealStatus ? dealStatusName[dealStatus] : 'Cancelamento do plano';

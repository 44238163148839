import {
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { useContext } from 'react';
import { CpfMaskInput } from 'modules/auth/pages/Register/components/CreditCardStep';
import Context from '../Context';
import 'react-phone-number-input/style.css';
import { MaskedPhoneInput } from './MaskedPhoneInput';

const ProfileForm = () => {
  const { form } = useContext(Context);
  const { palette } = useTheme();
  const inputStyles = {
    bgcolor: 'grey.600',
    borderRadius: 1,
    height: 42,
    mt: 2,
    px: 3,
    py: 2,
    '.MuiOutlinedInput-input': { color: 'grey.300' },
    '.Mui-disabled': {
      WebkitTextFillColor: `${palette.grey[400]} !important`,
    },
  };
  return (
    <>
      <Stack sx={{ height: 64 }}>
        <Typography
          sx={{ color: 'grey.300', fontFamily: 'secondary', fontSize: 14 }}
        >
          Nome{' '}
          <Typography
            component="span"
            sx={{ color: 'error.main', fontFamily: 'secondary', fontSize: 14 }}
          >
            *
          </Typography>
        </Typography>
        <OutlinedInput
          data-testid="nameInput"
          error={!!form.errors.name}
          fullWidth
          name="name"
          title="Nome"
          type="text"
          value={form.values.name}
          onChange={form.handleChange}
          sx={inputStyles}
        />
        <Typography
          data-testid="nameErrorAlert"
          sx={{ color: 'error.main', fontFamily: 'secondary', fontSize: 12 }}
        >
          {form.errors.name}
        </Typography>
      </Stack>
      <Stack>
        <Typography
          sx={{ color: 'grey.300', fontFamily: 'secondary', fontSize: 14 }}
        >
          CPF
        </Typography>
        <OutlinedInput
          data-testid="cpfInput"
          readOnly
          disabled
          fullWidth
          name="cpf"
          title="CPF"
          endAdornment={
            <InputAdornment position="end">
              <Lock sx={{ color: 'grey.400' }} />
            </InputAdornment>
          }
          type="text"
          inputComponent={CpfMaskInput}
          value={form.values.usercpf}
          sx={inputStyles}
        />
      </Stack>
      <Stack>
        <Typography
          sx={{ color: 'grey.300', fontFamily: 'secondary', fontSize: 14 }}
        >
          E-mail
        </Typography>
        <OutlinedInput
          readOnly
          fullWidth
          disabled
          name="email"
          title="E-mail"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <Lock sx={{ color: 'grey.400' }} />
            </InputAdornment>
          }
          value={form.values.email}
          sx={inputStyles}
        />
      </Stack>
      <Stack>
        <Typography
          sx={{ color: 'grey.300', fontFamily: 'secondary', fontSize: 14 }}
        >
          Telefone{' '}
          <Typography
            component="span"
            sx={{ color: 'error.main', fontFamily: 'secondary', fontSize: 14 }}
          >
            *
          </Typography>
        </Typography>
        <OutlinedInput
          data-testid="phoneInput"
          fullWidth
          name="phone"
          title="E-mail"
          type="text"
          value={form.values.phone}
          placeholder="+55 (11) 99999-9999"
          inputComponent={MaskedPhoneInput}
          sx={inputStyles}
          onChange={form.handleChange}
        />
        <Typography
          data-testid="phoneErrorAlert"
          sx={{ color: 'error.main', fontFamily: 'secondary', fontSize: 12 }}
        >
          {form.errors.phone || ''}
        </Typography>
      </Stack>
    </>
  );
};

export default ProfileForm;

import { Button, Stack, Typography } from '@material-ui/core';
import { useDialog } from 'components/Dialog';

const PixWithPriceZero = () => {
  const dialog = useDialog();
  return (
    <Stack alignItems="center" gap={4}>
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        Boas Noticias...
      </Typography>
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'secondary',
          fontSize: 14,
          textAlign: 'center',
        }}
      >
        Você atingiu <b className="text-bold">100% de desconto</b>, continue
        para finalizar a compra.
      </Typography>
      <Button fullWidth sx={{ height: 60 }} onClick={() => dialog.close()}>
        Continuar
      </Button>
    </Stack>
  );
};

export default PixWithPriceZero;

import { PaymentMethod } from 'interfaces/BillingHistory';

const getPaymentMethodLabel = (paymentMethod: PaymentMethod): string => {
  switch (paymentMethod) {
    case PaymentMethod.credit:
      return 'Cartão de crédito';
    case PaymentMethod.debit:
      return 'Cartão de débito';
    case PaymentMethod.pix:
      return 'Pix';
    default:
      return '';
  }
};

export default getPaymentMethodLabel;

import { intersection } from 'lodash';
import { isAxiosError } from 'helpers/axios';
import { StatusCodes } from 'http-status-codes';
import { createContext, useContext, useEffect, useState } from 'react';
import getUserPlans from 'modules/account/pages/Subscriptions/services/getUserPlans';
import useLoggedIn from 'utils/hooks/use-logged-in';
import useDeletingPlansLocalStorage from 'utils/hooks/use-deleting-plans';

export const AccountCancelationContext = createContext(null as any);

export const useDeletingPlans = () => {
  const { deletingPlans, setDeletingPlans } = useContext(
    AccountCancelationContext,
  );
  return [deletingPlans, setDeletingPlans];
};

const AccountCancelation = ({ children }: any) => {
  const { clear: clearLoggedIn } = useLoggedIn();
  const {
    clear: clearDeletingPlansLocalStorage,
    get,
    set,
  } = useDeletingPlansLocalStorage();
  const getDeletingPlansFromStorage = () => {
    const data = get() || '[]';
    return JSON.parse(data);
  };
  const setDeletingPlansToStorage = (deletingPlans: any) =>
    set(JSON.stringify(deletingPlans));
  const [deletingPlans, setDeletingPlans] = useState(
    getDeletingPlansFromStorage(),
  );
  const setDeletingPlansHandler = (newPlans: any) => {
    setDeletingPlansToStorage(newPlans);
    setDeletingPlans(newPlans);
  };
  useEffect(() => {
    const interval = setInterval(async () => {
      if (deletingPlans.length > 0) {
        try {
          const res = await getUserPlans();
          const filteredSubscriptions = res.data.filter(
            (subscriptions) => subscriptions.status === 'active',
          );
          const activePlans = filteredSubscriptions.map(({ id }) => id);
          setDeletingPlansHandler(intersection(deletingPlans, activePlans));
          if (!intersection(deletingPlans, activePlans)) {
            clearDeletingPlansLocalStorage();
          }
        } catch (err) {
          let isUnauthorized = false;
          if (isAxiosError(err)) {
            isUnauthorized = err?.response?.status === StatusCodes.UNAUTHORIZED;
          }
          if (isUnauthorized) {
            clearDeletingPlansLocalStorage();
            clearLoggedIn();
            window.location.href = `${process.env.REACT_APP_OUTPUT_PATH}${process.env.REACT_APP_BASENAME}`;
          }
        }
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [deletingPlans]);
  return (
    <AccountCancelationContext.Provider
      value={{ deletingPlans, setDeletingPlans: setDeletingPlansHandler }}
    >
      {children}
    </AccountCancelationContext.Provider>
  );
};
export default AccountCancelation;

import {
  Container,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  AccountCircle as UserIcon,
  ExpandMore as ExpandMoreIcon,
  Sensors as LiveIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Search as SearchIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';
import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationMenu } from 'components/ApplicationMenu';
import useDynamicImport from 'utils/hooks/use-dynamic-import';
import Account from '../../../modules/account';
import ProfileContext from '../../../Context';
import environment from '../../../environment.config'
import Context from '../context';

const Navbar = ({
  isLogged,
  headerRef,
  isOnTop,
}: {
  isLogged: boolean;
  headerRef?: any;
  isOnTop?: boolean;
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [switchSearch, setSwitchSearch] = useState(false);
  const { closeMenuHandler } = useContext(Context);
  const { profile } = useContext(ProfileContext);
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loading: lookingForLogo, element: logo } = useDynamicImport(
    `${environment.AppSlug}/png/logo.png`,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const favoriteTeam = profile?.team;
  const navSections = [
    {
      icon: <LiveIcon />,
      label: 'Ao Vivo',
      href: '/live-contents',
    },
    {
      icon: <FavoriteBorderIcon />,
      label: favoriteTeam,
      href: '/team-content',
    },
  ];
  const sectionAndPathMatch = (sectionHref: string) => {
    if (pathname === sectionHref) {
      return true;
    }
    return false;
  };
  const switchSearchBar = () => {
    setSwitchSearch(!switchSearch);
  };
  const navigateToLinkHandler = (route: string) => {
    navigate(route);
    closeMenuHandler();
  };
  // TODO: add typing
  const openAccountMenu = (evt: any) => {
    setAccountAnchorEl(evt.currentTarget);
    closeMenuHandler();
  };
  const closeAccountMenu = () => setAccountAnchorEl(null);
  const searchHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    const enterKeyCode = 13;
    const enterWasPressed = e.which === enterKeyCode;
    if (enterWasPressed) {
      const searchInput = searchInputRef.current?.querySelector('input');
      if (searchInput) {
        const { value } = searchInput;
        navigateToLinkHandler(`/section-content/search?s=${value}`);
      }
    }
  };
  // TODO: add skeleton
  if (lookingForLogo) return <div>Carregando...</div>;
  return (
    <Stack
      ref={headerRef}
      sx={{
        alignItems: 'center',
        bgcolor: { xs: 'grey.600', lg: isOnTop ? 'grey.600' : 'transparent' },
        background:
          'linear-gradient(180deg, rgba(20, 20, 20, 0.8) 0%, rgba(20, 20, 20, 0) 100%);',
        position: 'fixed',
        transition: 'all .6s ease-in-out',
        top: 0,
        width: '100%',
        zIndex: 1200,
      }}
    >
      <Container
        maxWidth={isLogged ? 'xl' : 'lg'}
        sx={{ px: { xl: 11, lg: 11, md: 10, sm: 4 } }}
      >
        <Stack
          sx={{
            alignItems: 'center',
            flexDirection: 'row',
            height: 72,
            justifyContent: 'space-between',
            position: 'relative',
            pl: { lg: 0, md: 5, sm: 0 },
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            gap={8}
            sx={{ width: { xs: '100%', lg: 'auto' } }}
          >
            <Stack
              sx={{
                cursor: 'pointer',
                width: { xs: 100, lg: 131 },
              }}
              onClick={() => navigateToLinkHandler('/home')}
            >
              <img src={logo} alt="Paulistão Play" />
            </Stack>
            {navSections.map((section) => (
              <Stack
                key={section.label}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                gap={2}
                sx={{
                  color: sectionAndPathMatch(section.href)
                    ? 'tertiary.main'
                    : 'grey.300',
                  cursor: 'pointer',
                  display: { xs: 'none', lg: 'flex' },
                  '&:hover': { color: 'tertiary.main' },
                }}
              >
                {section.icon}
                <Typography
                  sx={{ fontFamily: 'primary' }}
                  onClick={() => navigateToLinkHandler(section.href)}
                >
                  {section.label}
                </Typography>
              </Stack>
            ))}
            {switchSearch || isMobile ? (
              <OutlinedInput
                ref={searchInputRef}
                autoFocus={!isMobile}
                placeholder="Busca"
                startAdornment={
                  <SearchIcon sx={{ mr: 2, width: { xs: 16, lg: 18 } }} />
                }
                sx={{
                  border: 'none !important',
                  boxShadow: 'none !important',
                  borderRadius: 1,
                  bgcolor: '#4E4E4E66',
                  color: 'grey.300',
                  height: { xs: 24, lg: 40 },
                  width: { xs: 120, lg: 376 },
                  '&:hover': {
                    boxShadow: 'none !important',
                    border: 'none !important',
                  },
                  '.MuiOutlinedInput-notchedOutline Mui-focused css-1o2jng6-MuiOutlinedInput-notchedOutline':
                    {
                      border: 'none !important',
                    },
                  input: {
                    '&::placeholder': {
                      color: 'grey.300',
                    },
                  },
                }}
                onBlur={switchSearchBar}
                onKeyPress={searchHandler}
              />
            ) : (
              <SearchIcon
                sx={{
                  color: 'grey.300',
                  cursor: 'pointer',
                  '&:hover': { color: 'tertiary.main' },
                }}
                onClick={switchSearchBar}
              />
            )}
          </Stack>
          <Stack
            sx={{
              alignItems: 'center',
              color: 'grey.100',
              cursor: 'pointer',
              display: { xs: 'flex', lg: 'flex' },
              flexDirection: 'row',
              gap: 2,
              ml: 3,
              mt: 3,
              pb: 3,
            }}
            onClick={openAccountMenu}
          >
            {isMobile ? (
              <MenuIcon data-testid="accountButton" sx={{ width: 22 }} />
            ) : (
              <>
                <UserIcon data-testid="accountButton" />
                <ExpandMoreIcon style={{ width: 15 }} />
              </>
            )}
          </Stack>
          <ApplicationMenu
            onClose={closeAccountMenu}
            anchorEl={accountAnchorEl}
          >
            <Account onClose={closeAccountMenu} />
          </ApplicationMenu>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Navbar;

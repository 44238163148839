import { Card, Skeleton, Stack } from '@material-ui/core';

const OfferedPlanCardPlaceholder = () => (
  <Card
    sx={{
      bgcolor: 'grey.600',
      borderRadius: 3,
      display: 'flex',
      flexDirection: 'column',
      height: 158,
      px: 4,
      width: { xs: 300, lg: 327 },
    }}
  >
    <Stack sx={{ alignItems: 'center', flexDirection: 'row', height: 54 }}>
      <Skeleton variant="rectangular" sx={{ height: 24, width: '60%' }} />
    </Stack>
    <Skeleton variant="rectangular" sx={{ height: 36, width: '100%' }} />
    <Stack
      sx={{
        alignItems: 'center',
        color: 'grey.300',
        flexDirection: 'row',
        height: 68,
        justifyContent: 'space-between',
        mt: 'auto',
      }}
    >
      <Skeleton variant="rectangular" sx={{ height: 36, width: '40%' }} />
      <Skeleton variant="rectangular" sx={{ height: 36, width: '40%' }} />
    </Stack>
  </Card>
);

export default OfferedPlanCardPlaceholder;

import { Box } from '@material-ui/core';
import { Element } from 'react-scroll';
import { ContentResponse } from 'services/Section/SectionService.types';
import SectionLine from './SectionLine';
import { useSectionData } from '../use-section-data';
import MultiSectionItems from './MultiSectionItems';

interface SectionListProps {
  data: ContentResponse;
  hasShowMoreLink?: boolean;
  setRef: (element: HTMLDivElement | null, index: number) => void;
}

const SectionList = ({
  data,
  setRef,
  hasShowMoreLink = true,
}: SectionListProps) => {
  const { sectionsData } = useSectionData(data);
  return (
    <Box sx={{ width: '100%' }}>
      {sectionsData?.map((eachSection: any, index: any) => {
        const isSuperSection = eachSection.superSection;
        if (isSuperSection) {
          return (
            <Element
              key={eachSection.id}
              name={`section-element-${eachSection.id}`}
            >
              <MultiSectionItems
                key={eachSection.id}
                ref={(el) => setRef(el, index)}
                sectionData={eachSection}
              />
            </Element>
          );
        }
        return (
          <Element
            key={eachSection.id}
            name={`section-element-${eachSection.id}`}
          >
            <SectionLine
              key={eachSection.id}
              hasShowMoreLink={hasShowMoreLink}
              sectionData={eachSection}
            />
          </Element>
        );
      })}
    </Box>
  );
};

export default SectionList;

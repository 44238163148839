import { Skeleton, Stack, Switch, Typography } from '@material-ui/core';
import React from 'react';
import { HomeContentPlaceholder } from './HomeContentPlaceholder';

interface HomePlaceholderProps {
  isContent?: boolean;
}

export const HomePlaceholder = ({ isContent }: HomePlaceholderProps) => (
  <>
    {!isContent && (
      <Stack
        alignItems="center"
        direction="row"
        sx={{
          background:
            'linear-gradient(180deg, rgba(23,23,23,1) 3%, #1f1f1f 88%, rgba(106,106,106,0) 100%)',
          height: { xs: 488, lg: 635 },
          width: '100%',
          m: '0 auto',
          pl: { xs: 4, lg: '95px' },
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: 1,
            boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)',
            height: 174,
            width: { xs: 248, lg: 401 },
          }}
        />
      </Stack>
    )}
    <Skeleton
      variant="rectangular"
      sx={{
        borderRadius: 1,
        boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)',
        height: 6,
        width: 279,
        m: '0 auto',
        mb: 4,
        mt: isContent ? '30px' : '-40px',
      }}
    />
    {!isContent && (
      <>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          sx={{ ml: { xs: 4, lg: '95px' } }}
        >
          <Switch />
          <Typography sx={{ color: 'grey.200', fontFamily: 'secondary', fontSize: 16 }}>
            Exibir placar
          </Typography>
        </Stack>
        <HomeContentPlaceholder />
      </>
    )}
    <HomeContentPlaceholder isVideo />
    <HomeContentPlaceholder isVideo />
    <HomeContentPlaceholder isVideo />
    {!isContent && <HomeContentPlaceholder isMultiSection />}
    <HomeContentPlaceholder isVideo />
  </>
);

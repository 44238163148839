import { Button, Container, Stack } from '@material-ui/core';
import logo from '../../../assets/png/logoW.png';

interface PageHeaderProps {
  hasButton: boolean;
  bgColor: string;
  buttonColor: string;
  buttonLink: string;
  textColor: string;
  buttonText: string;
}

const PageHeader = ({
  hasButton = false,
  bgColor = '#171717',
  buttonColor = '#DE2A2F',
  buttonLink = '',
  textColor = '#FFFFFF',
  buttonText = 'Assine agora!',
}: PageHeaderProps) => (
  <Stack sx={{ bgcolor: bgColor }}>
    <Container maxWidth="lg">
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent={hasButton ? 'space-between' : 'center'}
        sx={{
          bgcolor: bgColor,
          height: 70,
          px: { xs: 2, lg: 8 },
          py: { xs: 1, lg: 10 },
          width: '100%',
        }}
      >
        <img style={{ maxHeight: '50px' }} src={logo} alt="logo" />
        {hasButton && (
          <a href={buttonLink}>
            <Button
              sx={{
                bgcolor: buttonColor,
                color: textColor,
                '&:hover': { bgcolor: buttonColor },
              }}
            >
              {buttonText}
            </Button>
          </a>
        )}
      </Stack>
    </Container>
  </Stack>
);

export default PageHeader;

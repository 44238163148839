import {
  Divider,
  Link,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useDynamicImport from 'utils/hooks/use-dynamic-import';
import twitterIcon from 'assets/svg/twitterIcon.svg';
import twitchIcon from 'assets/svg/twitchIcon.svg';
import facebookIcon from 'assets/svg/facebookIcon.svg';
import youtubeIcon from 'assets/svg/youtubeIcon.svg';
import instagramIcon from 'assets/svg/instagramIcon.svg';
import environment from 'environment.config';

type SocialMedia = { name: string; href: string };
type FooterLinks = { label: string; href: string };
type FooterProps = { sx?: SxProps<Theme> };
const socialMediasIcons = [
  {
    name: 'twitch',
    icon: twitchIcon,
  },
  {
    name: 'twitter',
    icon: twitterIcon,
  },
  {
    name: 'facebook',
    icon: facebookIcon,
  },
  {
    name: 'instagram',
    icon: instagramIcon,
  },
  {
    name: 'youtube',
    icon: youtubeIcon,
  },
];

const Footer = ({ sx }: FooterProps) => {
  const { loading, element: logoIcon } = useDynamicImport(
    `${environment.AppSlug}/png/logoIcon.png`,
  );
  const getTermsLinkProps = {
    fontFamily: 'secondary',
    fontSize: { xs: 14, lg: 16 },
    color: 'grey.200',
    '&:hover': {
      color: 'tertiary.main',
    },
    margin: { xs: 1 },
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const footerLinks: FooterLinks[] = JSON.parse(
    process.env.REACT_APP_FOOTER_LINKS as string,
  );
  const socialMedias: SocialMedia[] = JSON.parse(
    process.env.REACT_APP_SOCIAL_MEDIAS as string,
  );
  const findSocialMediaByName = (name: string) =>
    socialMediasIcons.find((socialMedia) => socialMedia.name === name);
  // TODO: add skeleton
  if (loading) return <div>Carregando...</div>;
  return (
    <Stack
      sx={{
        height: { xs: 'unset', lg: 77 },
        mt: { xs: 5, lg: 11 },
        ...sx,
      }}
    >
      <Divider sx={{ bgcolor: 'tertiary.main', opacity: 0.1, width: '100%' }} />
      <Stack
        gap={{ xs: 5, lg: 'unset' }}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          flexDirection: { xs: 'column', lg: 'row' },
          height: '100%',
          padding: { xs: '24px 48px 72px 48px', lg: '0 32px' },
        }}
      >
        <Stack
          alignItems="center"
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="center"
          gap={{ xs: 0, lg: 6 }}
          sx={{ fontSize: { xs: 10, lg: 14 } }}
        >
          <LazyLoadImage
            src={logoIcon}
            height={34}
            width={33}
            style={{ display: isMobile ? 'none' : 'initial' }}
          />
          {footerLinks.map((footerLink) => (
            <Link
              key={footerLink.href}
              target="_blank"
              href={footerLink.href}
              sx={{ textDecoration: 'none' }}
            >
              <Typography sx={getTermsLinkProps}>{footerLink.label}</Typography>
            </Link>
          ))}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          sx={{
            color: 'grey.100',
            gap: 3,
          }}
        >
          {socialMedias.map(({ name, href }) => {
            const getSocialMedia = findSocialMediaByName(name);
            return (
              <Link
                key={name}
                href={href}
                target="_blank"
                style={{ height: 22, width: 22 }}
              >
                <SvgIcon
                  component={getSocialMedia?.icon as any}
                  sx={{
                    color: 'grey.100',
                    cursor: 'pointer',
                    height: name === 'twitter' ? 21 : 22,
                    width: name === 'twitter' ? 21 : 22,
                    '&:hover': { color: 'tertiary.dark' },
                  }}
                />
              </Link>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;

import { UserProfile } from 'modules/account/typings';
import { createContext } from 'react';

type ContextType = {
  isLoadingUser: boolean;
  profile: UserProfile | undefined;
  refetch: () => void;
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;

import { AxiosResponse } from 'axios';
import axiosHelper from 'helpers/axios';
import { Plan } from 'interfaces/plan';
import { Partner } from 'interfaces/partner';

interface PartnersListResult {
  idp: Partner[];
}

export const getPartners = () =>
  axiosHelper
    .get<PartnersListResult>('/partners/idp')
    .then(({ data }) => data.idp);

export const getPlans = (): Promise<AxiosResponse<Plan[]>> =>
  axiosHelper.get<Plan[]>('/plans');

export enum ReactQueryNames {
  CONTENT = 'content',
  BILLING_HISTORY = 'billing_history',
  PARTNERS = 'partnes',
  PLANS = 'plans',
  PROFILE = 'profile',
  QUESTIONS = 'questions',
  RENTENTION_DISCOUNTS = 'rentention_discounts',
  SECTIONS = 'sections',
  SECTIONSV2 = 'sectionsV2',
  USER = 'user',
  USER_CARDS = 'user_cards',
  PIX = 'pix',
  TEAM_CONTENTS = 'team_contents',
  SEARCH_QUERY = 'search_query',
}

import { Button, Stack, Typography } from '@material-ui/core';
import { useDialog } from 'components/Dialog';
import React from 'react';
import PixModal from './PixModal';
import UpdateFreePlanToPaidPlan from './UpdateFreePlanToPaidPlan';

const ClosePixConfirm = ({
  offer,
  signUpId,
  setUserPlans,
  userPlans,
  closePixHandler,
}: any) => {
  const dialog = useDialog();
  const openModalAfterHiringPaidPlan = () => {
    dialog.open({
      element: <UpdateFreePlanToPaidPlan plan={offer} />,
      sx: {
        bgcolor: 'common.white',
        width: { xs: '100%', lg: 372 },
      },
    });
  };
  const backToPixHandler = () => {
    dialog.open({
      element: (
        <PixModal
          userPlans={userPlans}
          offer={offer}
          setUserPlans={setUserPlans}
          openModalAfterHiringPaidPlan={openModalAfterHiringPaidPlan}
          signUpId={signUpId}
        />
      ),
      isBlocked: true,
      alternativeCloseHadler: closePixHandler,
      sx: {
        bgcolor: 'common.white',
        height: 528,
        maxWidth: 375,
        width: '100%',
      },
    });
  };
  const closeHandler = () => {
    dialog.close();
  };
  return (
    <Stack alignItems="center" gap={4}>
      <Typography sx={{ fontFamily: 'primary', fontSize: 20, fontWeight: 700 }}>
        Cancelar pagamento PIX
      </Typography>
      <Typography sx={{ fontFamily: 'secondary', fontSize: 14 }}>
        Sua compra não será finalizada, você tem certeza que deseja cancelar o
        pagamento PIX ?
      </Typography>
      <Button fullWidth sx={{ height: 37 }} onClick={closeHandler}>
        Sim, tenho certeza
      </Button>
      <Button
        fullWidth
        sx={{
          border: '2px solid',
          bgcolor: 'common.white',
          color: 'secondary.main',
          height: 37,
          '&:hover': { bgcolor: 'common.white' },
        }}
        onClick={backToPixHandler}
      >
        Voltar
      </Button>
    </Stack>
  );
};

export default ClosePixConfirm;

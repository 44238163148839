import { Alert, Box, Button, TextField, Typography } from '@material-ui/core';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import React, { useContext, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { withEnter } from '../../../../../utils/form';
import Context from '../Context';
import { CpfMaskInput } from './CreditCardStep';
import { emailAndcpfCheck } from '../services/emailAndcpfCheck';

export const TextMaskCustom = React.forwardRef((props, ref) => {
  const setRef = React.useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={setRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
      placeholderChar={'\u2000'}
    />
  );
}) as any;

const EmailPhoneStep = () => {
  const [hasEmailOrCpfError, setEmailOrCpfError] = useState(false);
  const sendDataToGTM = useGTMDispatch();
  const { currentStep, form, setCurrentStep, hasRequired } = useContext(Context);
  const handleNextStep = async () => {
    form.setTouched({
      email: true,
      confirmEmail: true,
      phone: true,
      usercpf: true,
    });
    const errors = await form.validateForm(form.values);
    const emailOrPhoneContainsErrors =
      errors.email || errors.confirmEmail || errors.phone || errors.usercpf;
    if (emailOrPhoneContainsErrors) {
      return;
    }
    const numberOfStepsToTake = hasRequired ? 1 : 2;
    const nextStep = currentStep + numberOfStepsToTake;
    try {
      await emailAndcpfCheck({
        email: form.values.email,
        usercpf: form.values.usercpf,
      });
      setEmailOrCpfError(false);
      setCurrentStep(nextStep);
    } catch {
      setEmailOrCpfError(true);
    }
    sendDataToGTM({
      event: 'EmailPhoneStep',
      email: form.values.email,
      phone: form.values.phone,
    });
  };
  return (
    <Box
      style={{
        marginBottom: 100,
      }}
    >
      <Typography
        sx={{ color: 'grey.100', fontFamily: 'tertiary', fontSize: 24, mt: 8 }}
      >
        QUAL O SEU E-MAIL?
      </Typography>
      <TextField
        variant="filled"
        fullWidth
        name="email"
        label="E-mail"
        value={form.values.email}
        error={form.touched.email && !!form.errors.email}
        helperText={form.touched.email && form.errors.email}
        inputProps={{ 'data-testid': 'registerEmailInput' }}
        sx={{ mt: 5 }}
        onBlur={form.handleBlur}
        onKeyPress={withEnter(handleNextStep)}
        onChange={(e) => {
          const changeAtSign = e.target.value.replace('@@', '@');
          e.target.value = changeAtSign;
          form.handleChange(e);
        }}
      />
      <TextField
        variant="filled"
        fullWidth
        name="confirmEmail"
        label="Repita seu e-mail"
        value={form.values.confirmEmail}
        error={form.touched.confirmEmail && !!form.errors.confirmEmail}
        helperText={form.touched.confirmEmail && form.errors.confirmEmail}
        inputProps={{ 'data-testid': 'registerEmailConfirmationInput' }}
        sx={{ mt: 5 }}
        onBlur={form.handleBlur}
        onKeyPress={withEnter(handleNextStep)}
        onChange={form.handleChange}
      />
      <Typography
        sx={{ color: 'grey.100', fontFamily: 'tertiary', fontSize: 24, mt: 8 }}
      >
        QUAL O SEU CPF?
      </Typography>
      <TextField
        variant="filled"
        fullWidth
        name="usercpf"
        label="Informe seu CPF"
        type="tel"
        value={form.values.usercpf}
        error={form.touched.usercpf && !!form.errors.usercpf}
        helperText={form.touched.usercpf && form.errors.usercpf}
        InputProps={{
          inputComponent: CpfMaskInput,
          inputProps: { 'data-testid': 'registerCpfInput' },
        }}
        sx={{ mt: 5 }}
        onBlur={form.handleBlur}
        onKeyPress={withEnter(handleNextStep)}
        onChange={form.handleChange}
      />
      <Typography
        sx={{ color: 'grey.100', fontFamily: 'tertiary', fontSize: 24, mt: 8 }}
      >
        E O SEU TELEFONE?
      </Typography>
      <TextField
        variant="filled"
        fullWidth
        name="phone"
        label="DDD + Celular"
        type="tel"
        value={form.values.phone}
        error={form.touched.phone && !!form.errors.phone}
        helperText={form.touched.phone && form.errors.phone}
        InputProps={{
          inputComponent: TextMaskCustom,
          inputProps: { 'data-testid': 'registerPhoneInput' },
        }}
        sx={{ mt: 5 }}
        onBlur={form.handleBlur}
        onKeyPress={withEnter(handleNextStep)}
        onChange={form.handleChange}
      />
      <Button
        data-testid="registerNextButton"
        color="tertiary"
        fullWidth
        size="large"
        sx={{ mt: 6 }}
        onClick={handleNextStep}
      >
        Continuar
      </Button>
      {hasEmailOrCpfError && (
        <Alert variant="filled" severity="error" sx={{ mt: 4 }}>
          Seus dados de cadastro não estão corretos, ou já existe uma conta com
          eles
        </Alert>
      )}
    </Box>
  );
};

export default EmailPhoneStep;

import { Container, Stack, Switch, Typography } from '@material-ui/core';
import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TournamentsBody from './TournamentsBody';
import TournamentsFilter from './TournamentsFilter';
import TournamentsHead from './TournamentsHead';

const times = [
  {
    title: 'Flamengo',
    position: 1,
    label: 3,
  },
  {
    title: 'Fluminense',
    position: 2,
    label: 3,
  },
  {
    title: 'Botafogo',
    position: 3,
    label: 3,
  },
  {
    title: 'São Paulo',
    position: 4,
    label: 3,
  },
  {
    title: 'Flamengo',
    position: 5,
    label: 3,
  },
  {
    title: 'Fluminense',
    position: 6,
    label: 3,
  },
  {
    title: 'Botafogo',
    position: 7,
    label: 3,
  },
  {
    title: 'São Paulo',
    position: 8,
    label: 3,
  },
  {
    title: 'Flamengo',
    position: 9,
    label: 3,
  },
  {
    title: 'Fluminense',
    position: 10,
    label: 3,
  },
];

const TournamentsTable = () => {
  const [visibilityToggle, setVisibilityToggle] = useState(false);
  return (
    <Container
      maxWidth="xl"
      sx={{ mb: 11, px: { xl: 11, lg: 11, md: 10, sm: 4 } }}
    >
      <Typography sx={{ color: 'common.white', fontSize: 28 }}>
        TABELA PAULISTÃO
      </Typography>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography
          sx={{ color: 'grey.400', fontSize: 14, fontWeight: 600, my: 8 }}
        >
          SELECIONE O CAMPEONATO
        </Typography>
        <Stack alignItems="center" direction="row" gap={3}>
          <Switch value={visibilityToggle} onChange={() => setVisibilityToggle(!visibilityToggle)} />
          <Typography sx={{ color: 'grey.300', fontSize: 12, fontWeight: 400 }}>
            Exibir resultados
          </Typography>
        </Stack>
      </Stack>
      <TournamentsFilter />
      <PerfectScrollbar style={{ width: 'auto' }}>
        <Stack
          justifyContent="center"
          sx={{ bgcolor: 'grey.500', borderRadius: 4, minWidth: 859, mt: 10 }}
        >
          <TournamentsHead />
          {times.map((time: any) => (
            <TournamentsBody teamData={time} visibilityToggle={visibilityToggle} />
          ))}
          <Typography
            sx={{ color: 'grey.100', fontSize: 14, ml: 6, mb: 2, mt: 4 }}
          >
            Legenda:
          </Typography>
          <Typography sx={{ color: 'grey.100', fontSize: 14, ml: 6, mb: 4 }}>
            PTS - Pontos Ganhos | JOG - Jogos Disputados | VIT - Vitórias | EMP
            - Empates | DER - Derrotas | GOL.P - Gols Pró | GOL.C - Gols Contras
            | SG - Saldo de Gols | % - Aproveitamento
          </Typography>
        </Stack>
      </PerfectScrollbar>
    </Container>
  );
};
export default TournamentsTable;

import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Info as InfoIcon,
  MoreVert as OptionsIcon,
} from '@material-ui/icons';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { useDeletingPlans } from 'components/AccountCancelation';
import { useApplyingDiscount } from 'components/ApplyingDiscount';
import SubscriptionCancelationModal from 'components/SubscriptionCancelationModal';
import { Plan, PlanStatus, PlanType } from 'interfaces/plan';
import { getFeedbackDependingOnPlan } from 'modules/account/utils/getFeedbackDependingOnPlan';
import getNextSeasonDate from 'modules/account/utils/getNextSeasonDate';
import { doLogout, getRententionDiscounts } from 'modules/pages/services';
import {
  formatNextMonthlyPlanBilling,
  formatNextSeasonPlanBilling,
} from 'utils/formatNextBilling';
import useRegisterLocalStorage from 'utils/hooks/use-register-local-storage';
import { useDialog } from '../../../../../components/Dialog';
import { formatDate } from '../../../../../utils/date';
import { deleteUserSubscription } from '../../../services';
import { getStatusFriendlyName } from '../../../utils/getStatusFriendlyName';
import SubscriptionsContext from '../context';
import getUserPlans from '../services/getUserPlans';
import DeleteSubscription from './DeleteSubscription';
import SubscriptionCardModal from './SubscriptionCardModal';

type SubscriptionCardProps = {
  activeSubscription: Plan;
  openModalToReEnableSubscription: (id: string, userPlanId: string) => void;
};

const SubscriptionCard = ({
  activeSubscription,
  openModalToReEnableSubscription,
}: SubscriptionCardProps) => {
  const [deletingPlans, setDeletingPlans] = useDeletingPlans();
  const [applyingDiscount] = useApplyingDiscount();
  const { plans, userPlans, setUserPlans } = useContext(SubscriptionsContext);
  const navigate = useNavigate();
  const { clearRegisterLocalStorage } = useRegisterLocalStorage();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const alert = useAlert();
  const dialog = useDialog();
  const open = Boolean(anchorEl);
  const nextBillingDateOfSeasonPlan = getNextSeasonDate(
    activeSubscription.id,
    plans,
  );
  const openMenuHandler = (evt: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(evt.currentTarget);
  const closeMenuHandler = () => setAnchorEl(null);
  const thePlanIsFree = activeSubscription.type === PlanType.free;
  const haveOnlyOnePlan = userPlans.length === 1;
  const deleteSubscription = async (id: string) => {
    try {
      await deleteUserSubscription(id);
      dialog.close();
      if (thePlanIsFree) {
        const newPlans = userPlans.filter(
          (plan) => plan.id !== activeSubscription.id,
        );
        alert.success('Plano cancelado com sucesso!');
        setUserPlans(newPlans);
        if (haveOnlyOnePlan) {
          try {
            await doLogout();
            // eslint-disable-next-line no-empty
          } catch {
          } finally {
            clearRegisterLocalStorage();
            navigate('/');
          }
        }
        return;
      }
      alert.success('O plano está sendo cancelado...');
      setDeletingPlans([...deletingPlans, id]);
    } catch {
      alert.error('Ocorreu um erro ao cancelar o plano.');
    }
  };
  const deleteSubscriptionHandler = async (id: string) => {
    try {
      const { data: retentionDiscounts } = await getRententionDiscounts(
        activeSubscription?.id,
      );
      if (retentionDiscounts?.length > 0) {
        navigate('/plan/cancellation-hold', { state: { subscriptionId: id } });
        dialog.close();
      }
    } catch {
      if (!thePlanIsFree) {
        await deleteSubscription(id);
      }
      await dialog.open({
        element: (
          <SubscriptionCancelationModal
            deleteSubscription={deleteSubscription}
            plans={plans}
            subscription={activeSubscription}
            userPlans={userPlans}
          />
        ),
        sx: {
          bgcolor: 'common.white',
          maxWidth: { lg: 375 },
          overflowY: 'hidden',
          width: '100%',
        },
      });
    }
  };
  const isDeletingPlan = deletingPlans.includes(activeSubscription.userPlanId);
  const openDeleteSubscriptionModal = async () => {
    closeMenuHandler();
    await dialog.open({
      closable: false,
      isBlocked: true,
      element: (
        <DeleteSubscription
          title="Cancelar plano?"
          description={getFeedbackDependingOnPlan(
            userPlans,
            activeSubscription,
          )}
          sx={{ maxWidth: { xs: 'unset', lg: 338 } }}
        >
          <Stack
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Button
              data-testid="keepSubscriptionButton"
              color="secondary"
              size="large"
              fullWidth
              onClick={() => dialog.close(true)}
            >
              Manter assinatura
            </Button>
            <Button
              data-testid="cancelSubscriptionButton"
              variant="outlined"
              color="secondary"
              fullWidth
              size="large"
              onClick={() =>
                deleteSubscriptionHandler(activeSubscription.userPlanId)
              }
            >
              Cancelar plano
            </Button>
          </Stack>
        </DeleteSubscription>
      ),
      sx: {
        bgcolor: 'common.white',
        width: '100%',
      },
    });
  };
  const openPlanSubsciptionModal = async (subscription: any) => {
    closeMenuHandler();
    await dialog.open({
      element: <SubscriptionCardModal subscription={subscription} />,
      title: (
        <Typography
          sx={{
            color: 'primary.light',
            fontFamily: 'secondary',
            textAlign: 'center',
          }}
        >
          Informações do plano
        </Typography>
      ),
      sx: {
        bgcolor: 'common.white',
        maxWidth: '100%',
        width: 375,
      },
    });
  };
  const formattedDate = formatDate(activeSubscription.createdAt, "dd/MM/yyyy'");
  const formattedPrice = activeSubscription.netValue?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const isFreePlan = activeSubscription.type === PlanType.free;
  const isPartnerOrFree =
    activeSubscription.type === PlanType.partner || isFreePlan;
  const getSubscriptionValue = () => {
    if (isPartnerOrFree) {
      return null;
    }
    return formattedPrice;
  };
  const formattedType = () => {
    if (activeSubscription.type === PlanType.partner) {
      return 'Parceiro';
    }
    if (activeSubscription.type === PlanType.free) {
      return 'Gratuito';
    }
    if (activeSubscription.type === PlanType.monthly) {
      return 'Mensal';
    }
    return 'Temporada';
  };
  const getPlanDetails = getStatusFriendlyName(
    activeSubscription.status as PlanStatus,
  );
  const isPlanDisabled = activeSubscription.status !== 'active';
  useEffect(() => {
    const refreshUserPlanList = async () => {
      const newPlans = await getUserPlans();
      setUserPlans(newPlans.data);
    };
    refreshUserPlanList();
  }, [deletingPlans, applyingDiscount]);
  const isApplyingDiscount = useMemo(
    () =>
      [...applyingDiscount].find(
        (plan: Plan) => plan.id === activeSubscription.id,
      ),
    [applyingDiscount],
  );
  const isSeasonPlan = activeSubscription.type === PlanType.season;
  const isMonthlyOrSeasonPlan =
    isSeasonPlan || activeSubscription.type === PlanType.monthly;
  const hasNextBilling =
    isMonthlyOrSeasonPlan && activeSubscription.status === PlanStatus.active;
  const nextPlanBillingDate = isSeasonPlan
    ? formatNextSeasonPlanBilling(nextBillingDateOfSeasonPlan as string)
    : formatNextMonthlyPlanBilling(activeSubscription.recurrenceDay);
  const isPlanActive = plans.some(
    (currentPlan) => currentPlan.id === activeSubscription.id,
  );
  const isPreCancelled = activeSubscription.status === PlanStatus.preCancelled;
  const canReactivatePlan = isPlanActive && isPreCancelled;
  return (
    <Card
      data-testid={`subscriptionCard-${activeSubscription.id}`}
      key={activeSubscription.title}
      sx={{ bgcolor: 'grey.600', borderRadius: 3, p: 4, width: 297 }}
    >
      <Stack sx={{ flexDirection: 'column' }} spacing={3}>
        <Stack
          sx={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ color: 'common.white', fontFamily: 'primary' }}>
            {activeSubscription.title}
          </Typography>
          {isDeletingPlan ? (
            <CircularProgress color="error" size={20} />
          ) : (
            <Box
              sx={{ display: isPlanDisabled ? 'none' : 'block' }}
              onClick={openMenuHandler}
            >
              <OptionsIcon
                data-testid="optionsButton"
                sx={{ color: 'grey.400', cursor: 'pointer' }}
              />
            </Box>
          )}
          <Menu
            data-testid="subscriptionOptionsMenu"
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={closeMenuHandler}
          >
            <MenuItem
              divider
              onClick={() => openPlanSubsciptionModal(activeSubscription)}
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText sx={{ fontFamily: 'secondary' }}>
                Informações do plano
              </ListItemText>
            </MenuItem>
            <MenuItem
              data-testid="cancelSubscriptionOption"
              onClick={openDeleteSubscriptionModal}
            >
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <ListItemText sx={{ fontFamily: 'secondary' }}>
                Cancelar plano
              </ListItemText>
            </MenuItem>
          </Menu>
        </Stack>
        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.05)' }} />
        <Stack
          sx={{
            color: 'grey.300',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Stack>
            <Typography
              sx={{ fontFamily: 'secondary', fontSize: 10, opacity: 0.5 }}
            >
              ASSINADO EM:
            </Typography>
            <Typography
              sx={{ fontFamily: 'secondary', color: 'grey.200', fontSize: 12 }}
            >
              {formattedDate}
            </Typography>
          </Stack>
          {!isPartnerOrFree && (
            <Stack>
              <Typography
                sx={{ fontFamily: 'secondary', fontSize: 10, opacity: 0.5 }}
              >
                VALOR:
              </Typography>
              <Typography
                sx={{
                  color: 'grey.200',
                  fontFamily: 'secondary',
                  fontSize: 12,
                }}
              >
                {getSubscriptionValue()}
              </Typography>
            </Stack>
          )}
          <Stack>
            <Typography
              sx={{ fontFamily: 'secondary', fontSize: 10, opacity: 0.5 }}
            >
              TIPO DE ASSINATURA:
            </Typography>
            <Typography
              sx={{ color: 'grey.200', fontFamily: 'secondary', fontSize: 12 }}
            >
              {formattedType()}
            </Typography>
          </Stack>
        </Stack>
        {!isDeletingPlan && !isApplyingDiscount && (
          <Stack
            alignItems="flex-end"
            direction="row"
            justifyContent="space-between"
          >
            {hasNextBilling && (
              <Stack>
                <Typography
                  sx={{
                    color: 'grey.300',
                    fontFamily: 'secondary',
                    fontSize: 10,
                    opacity: 0.5,
                  }}
                >
                  PROXIMA COBRANÇA:
                </Typography>
                <Typography
                  sx={{
                    color: 'grey.200',
                    fontFamily: 'secondary',
                    fontSize: 12,
                  }}
                >
                  {nextPlanBillingDate.toLocaleDateString('pt-br')}
                </Typography>
              </Stack>
            )}
            {canReactivatePlan && (
              <Button
                data-testid="reactivateSubscriptionButton"
                onClick={() =>
                  openModalToReEnableSubscription(
                    activeSubscription.id,
                    activeSubscription.userPlanId,
                  )
                }
                sx={{ display: isFreePlan ? 'none' : 'default' }}
              >
                Reabilitar plano
              </Button>
            )}
            <Chip
              data-testid="preCancelledLabel"
              color={getPlanDetails.color}
              label={
                <Typography
                  sx={{
                    color: getPlanDetails.color,
                    fontFamily: 'primary',
                    fontSize: 12,
                  }}
                >
                  {getPlanDetails.statusName}
                </Typography>
              }
              size="small"
              variant="outlined"
              sx={{ borderRadius: 1 }}
            />
          </Stack>
        )}
        {isApplyingDiscount && (
          <Chip
            color="warning"
            label={
              <Typography
                sx={{
                  color: 'warning.main',
                  fontFamily: 'primary',
                  fontSize: 13,
                }}
              >
                Aplicando desconto...
              </Typography>
            }
            size="small"
            variant="outlined"
            sx={{ borderRadius: 1 }}
          />
        )}
        {isDeletingPlan && (
          <Chip
            data-testid="cancellingPlanLabel"
            color="error"
            label={
              <Typography
                sx={{
                  color: 'error.main',
                  fontFamily: 'primary',
                  fontSize: 13,
                }}
              >
                Cancelando plano...
              </Typography>
            }
            size="small"
            variant="outlined"
            sx={{ borderRadius: 1 }}
          />
        )}
      </Stack>
    </Card>
  );
};

export default SubscriptionCard;

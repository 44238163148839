import { ConsumableContent, ContentType } from 'interfaces/Content';
import { getContentType } from './get-content-type';

export const getVideoUrl = (contentData: ConsumableContent) => {
  let stringifiedUrl = '';
  const type = getContentType(contentData!.type);
  const videoId = contentData!.spallaContentId;
  if (contentData?.externalContent || type === ContentType.external) {
    stringifiedUrl = contentData!.externalUrl;
  } else {
    const url = new URL(`https://beyond.spalla.io/player/?${type}=${videoId}`);
    stringifiedUrl = url.toString();
  }
  return stringifiedUrl;
};

/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import PageContainer from './PageContainer';
import LoadingPage from './placeholders/LoadingPage';
import { getPage } from './services';
import { Page } from './typings';

interface CustomPageProps {
  pageId?: string;
}

const CustomPage = ({ pageId = '' }: CustomPageProps) => {
  const [id, setId] = useState<string>();
  const [page, setPage] = useState<Page>();
  const pathMatch = useMatch('/page/:pageId');
  const navigate = useNavigate();
  useEffect(() => {
    if (pathMatch) {
      setId(pathMatch.params.pageId);
    }
    setId(pageId);
  }, [pathMatch, pageId]);
  const {
    isLoading: isLoadingPage,
  } = useQuery(
    'page',
    () => getPage(id!), {
    onSuccess: (data) => {
      const pageToRender = data.data as Page;
      setPage(pageToRender);
    },
    onError: () => {
      navigate('/404');
    },
  });
  if (isLoadingPage) {
    return <LoadingPage height="100vh" />;
  }
  return (
    <PageContainer json={JSON.stringify(page!.blocks)} />
  );
};

export default CustomPage;
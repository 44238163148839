import { Container, Stack } from '@material-ui/core';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from '@material-ui/icons';
import React from 'react';
import logo from '../../../assets/png/logoW.png';

interface PageFooterProps {
  hasVerticalLayout: boolean;
  bgColor: string;
  iconsColor: string;
  twitterLink: string;
  instagramLink: string;
  facebookLink: string;
  youtubeLink: string;
}

const PageFooter = ({
  hasVerticalLayout = true,
  bgColor = '#171717',
  iconsColor = '#FFFFFF',
  twitterLink = '',
  facebookLink = '',
  instagramLink = '',
  youtubeLink = '',
}: PageFooterProps) => (
  <Stack sx={{ bgcolor: bgColor }}>
    <Container maxWidth="lg">
      <Stack
        alignItems="center"
        flexDirection={hasVerticalLayout ? 'column' : 'row'}
        height={hasVerticalLayout ? 92 : 70}
        justifyContent={hasVerticalLayout ? 'space-around' : 'space-between'}
        sx={{
          bgcolor: bgColor,
          px: { xs: 2, lg: 8 },
          mb: { xs: 10, lg: 0 },
          py: { xs: 1, lg: 10 },
        }}
      >
        <img
          style={{ maxHeight: '45px' }}
          src={logo}
          alt="logo"
        />
        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          {
            twitterLink && (
              <a
                href={twitterLink}
                rel="noopener noreferrer"
                style={{ color: iconsColor }}
                target="_blank"
              >
                <TwitterIcon />
              </a>
            )
          }
          {
            instagramLink && (
              <a
                href={instagramLink}
                rel="noopener noreferrer"
                style={{ color: iconsColor }}
                target="_blank"
              >
                <InstagramIcon />
              </a>
            )
          }
          {
            facebookLink && (
              <a
                href={facebookLink}
                rel="noopener noreferrer"
                style={{ color: iconsColor }}
                target="_blank"
              >
                <FacebookIcon />
              </a>
            )
          }
          {
            youtubeLink && (
              <a
                href={youtubeLink}
                rel="noopener noreferrer"
                style={{ color: iconsColor }}
                target="_blank"
              >
                <YouTubeIcon />
              </a>
            )
          }
        </Stack>
      </Stack>
    </Container>
  </Stack>
);

export default PageFooter;

import { Stack, useTheme } from '@material-ui/core';
import { hexToRgba } from 'utils/hexToRgba';

const BannerGradient = () => {
  const { palette } = useTheme();
  const infoMainColor = palette.info.main;
  const infoMainWithoutOpacity = hexToRgba(infoMainColor, 0);
  const infoMainWithOpacity15 = hexToRgba(infoMainColor, 0.15);
  const infoLightColor = palette.info.light;
  const infoLightWithoutOpacity = hexToRgba(infoLightColor, 0);
  const infoLightWithOpacity15 = hexToRgba(infoLightColor, 0.15);
  return (
    <Stack sx={{ height: '100%', width: '100%' }}>
      <Stack
        sx={{
          backgroundImage: {
            xs: `transparent`,
            lg: `linear-gradient(270deg, 
              ${infoLightWithoutOpacity} 0%, 
              ${infoLightWithOpacity15} 43.61%, 
              ${infoLightColor} 84.92%);`,
          },
          backgroundPositionY: 'bottom',
          backgroundSize: '100%',
          height: '100%',
          left: 0,
          opacity: 0.8,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 150,
        }}
      />
      <Stack
        sx={{
          background: `radial-gradient(243.62% 100% at 50.13% 0%, 
            ${infoMainWithoutOpacity} 0%,
            ${infoMainWithOpacity15} 57.1%, 
            ${infoMainColor} 98.67%);`,
          backgroundPositionY: 'center',
          backgroundSize: '100%',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 151,
        }}
      />
    </Stack>
  );
};

export default BannerGradient;

import {
  Box,
  Chip,
  Skeleton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Sensors as LiveIcon } from '@material-ui/icons';
import { SxProps } from '@material-ui/system';
import { memo, useCallback, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { formataEventDate } from '../../../../../utils/time';

interface VideoContentCardProps {
  data: any;
  isGridVersion?: boolean;
  isMultiSection?: boolean;
  thumbnailSx?: SxProps<Theme>;
}

const ThumbnailPlaceholder = () => (
  <Stack spacing={3}>
    <Skeleton variant="rectangular" width={224} height={127} />
    <Skeleton variant="rectangular" width={224} height={16} />
  </Stack>
);

export const VideoContentCard = memo(
  ({
    data,
    isMultiSection,
    thumbnailSx,
    isGridVersion,
  }: VideoContentCardProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const [isLoaded, setIsLoaded] = useState(false);
    // o eventDate que vem na API não é UTC, ele já está com o fuso horário do Brasil
    // antes de alterar qualquer coisa aqui, olhar a função formataEventDate
    const formatedEventDate = data?.eventDate ? new Date(data.eventDate) : null;
    const dateIsInTheFuture =
      !!formatedEventDate && formatedEventDate > new Date();
    const contentIsLive = data?.isLive;
    const contentIsScheduled =
      data?.type === 'live' && !data?.isLive && dateIsInTheFuture;
    const getEventDateLabel = () => {
      if (!data?.type) {
        return '';
      }
      const eventDateLabel = formatedEventDate
        ? formataEventDate(formatedEventDate)
        : '';
      return eventDateLabel;
    };
    const contentImage = isMultiSection
      ? data.thumbnailFeatured
      : data.thumbnail;
    const getContentLink = () => {
      if (isMultiSection) {
        const multiSectionItemIsTypeContent = data.target === 'content';
        if (multiSectionItemIsTypeContent) {
          return navigate(`/content/${data.targetId}`);
        }
        return navigate(`/section-content/${data.targetId}`);
      }
      return navigate(`/content/${data.id}`);
    };
    const getChipLabel = useCallback(() => {
      if (contentIsLive) {
        return (
          <Stack alignItems="center" direction="row" gap={1}>
            <LiveIcon sx={{ width: 15 }} />
            <Typography
              sx={{ fontFamily: 'primary', fontSize: 10, fontWeight: 400 }}
            >
              AO VIVO
            </Typography>
          </Stack>
        );
      }
      return (
        <Typography sx={{ fontFamily: 'primary', fontSize: 10 }}>
          {getEventDateLabel()}
        </Typography>
      );
    }, [contentIsLive]);
    return (
      <>
        {isLoaded ? (
          <Stack
            className="contentCard"
            sx={{
              borderRadius: 1,
              cursor: 'pointer',
              mb: isMultiSection ? 1 : 0,
              mt: 1,
              overflow: 'hidden',
              opacity: 1,
              transition: '.3s',
              width: isMultiSection
                ? { xs: 197, lg: 224 }
                : { xs: 220, lg: 224 },
              '&:hover ': {
                transform: 'scale(1.03)',
                '.thumbnail': {
                  boxShadow: `0px 0px 0px 4px ${palette.grey[100]} inset`,
                },
              },
              ...thumbnailSx,
            }}
            onClick={getContentLink}
          >
            <Box
              className="thumbnail"
              sx={{
                backgroundImage: `url(${contentImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: 2,
                boxSizing: 'border-box',
                height: '100%',
                minHeight: isMultiSection
                  ? { xs: 255, lg: 290 }
                  : {
                      xs: isGridVersion ? 220 : 130,
                      lg: 127,
                      xl: isGridVersion ? 180 : 127,
                    },
                position: 'relative',
                transition: '.3s',
              }}
            >
              {!isMultiSection && (contentIsLive || contentIsScheduled) && (
                <Chip
                  color={contentIsLive ? 'error' : 'secondary'}
                  label={getChipLabel()}
                  sx={{
                    borderRadius: 1,
                    display:
                      !contentIsLive && !data?.eventDate ? 'none' : 'auto',
                    height: 20,
                    fontSize: 16,
                    lineHeight: 16,
                    p: 0,
                    m: 1,
                  }}
                />
              )}
            </Box>
            {!isMultiSection && (
              <Typography
                sx={{
                  color: 'common.white',
                  fontFamily: 'primary',
                  fontSize: 16,
                  mt: 2,
                  width: 220,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  pr: '10px',
                  WebkitLineClamp: 3,
                }}
              >
                {data.title}
              </Typography>
            )}
          </Stack>
        ) : (
          <LazyLoadImage
            alt={data.title}
            height={127}
            src={contentImage}
            width={224}
            threshold={0}
            style={{ marginTop: '4px' }}
            placeholder={<ThumbnailPlaceholder />}
            afterLoad={() => setIsLoaded(true)}
          />
        )}
      </>
    );
  },
);

/* eslint-disable react/jsx-props-no-spreading */
import { Box, Stack, useMediaQuery, useTheme } from '@material-ui/core';
import { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDialog } from 'components/Dialog';
import { Plan, PlanType } from 'interfaces/plan';
import { User } from 'interfaces/User';
import getUserProfile from 'modules/account/pages/Profile/services/getUserProfile';
import Context from '../../../context';
import NewCard from '../../Payment/NewCard';
import { PartnerOrNull } from '../../../typings';
import SubscriptionsContext from '../context';
import CheckUserDataModal from './CheckUserDataModal';
import NewSubscription from './NewSubscription';
import OfferedPlanCard from './OfferedPlanCard';

interface OfferedPlansListProps {
  offers: Plan[];
  partner: PartnerOrNull;
  user: User;
}

const OfferedPlansList = ({ offers, partner, user }: OfferedPlansListProps) => {
  const [params] = useSearchParams();
  const clickOrigin = params.get('promotion');
  const dialog = useDialog();
  const alert = useAlert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { card, setCard } = useContext(Context);
  const { getPartner, setUserPlans, userPlans } =
    useContext(SubscriptionsContext);
  const openNewSubscription = async (offer: Plan) => {
    const planIsFreeOrPartner =
      offer.type === PlanType.free || offer.type === PlanType.partner;
    const hasNoCard = !card && !planIsFreeOrPartner;
    if (hasNoCard) {
      alert.error('Você precisa cadastrar um cartão para efetuar a compra.', {
        timeout: 4000,
      });
      const newCard = await dialog.open({
        element: <NewCard user={user} />,
        sx: {
          bgcolor: 'common.white',
          height: '55vh',
          maxWidth: 375,
          width: '100%',
        },
      });
      if (newCard) {
        setCard(newCard);
      }
      return;
    }
    await dialog.open({
      element: (
        <NewSubscription
          user={user}
          card={card}
          offer={offer}
          userPlans={userPlans}
          setUserPlans={setUserPlans}
          getPartner={getPartner}
          clickOrigin={
            clickOrigin === 'true'
              ? 'utm_source=purchasedByBanner'
              : 'utm_source=normalPurchase'
          }
        />
      ),
      dataTestId: 'newSubscriptionModal',
      sx: {
        bgcolor: 'common.white',
        width: { xs: '100%', lg: 372 },
      },
    });
  };
  const checkUserData = async (offer: Plan) => {
    const { data } = await getUserProfile();
    if (data.usercpf === '00000000000' || data.phone === '(00) 00000-0000') {
      await dialog.open({
        element: (
          <CheckUserDataModal
            userData={data}
            newSubscriptionHandler={() => openNewSubscription(offer)}
          />
        ),
        sx: { bgcolor: 'common.white', width: { xs: '100%', lg: 372 } },
      });
    } else {
      openNewSubscription(offer);
    }
  };
  const hirePlanThroughPromotion = async () => {
    const getPlanViaPromotion = offers.find(
      (plan) => plan.type === PlanType.monthly,
    );
    if (getPlanViaPromotion) {
      await checkUserData(getPlanViaPromotion as Plan);
    }
  };
  useEffect(() => {
    if (clickOrigin) {
      hirePlanThroughPromotion();
    }
  }, []);
  const ChildElement: FunctionComponent<any> = isMobile ? Box : SwiperSlide;
  const offeredSubscriptionList = useMemo(
    () =>
      offers
        ? offers.map((offer) => (
            <ChildElement key={offer.id}>
              <OfferedPlanCard
                partner={partner}
                offer={offer}
                openNewSubscription={checkUserData}
              />
            </ChildElement>
          ))
        : null,
    [offers, isMobile],
  );
  const ContainerElement: FunctionComponent<any> = isMobile ? Stack : Swiper;
  const props: { [key: string]: unknown } = isMobile
    ? { gap: 5 }
    : {
        navigation: true,
        slidesPerView: 'auto',
        spaceBetween: 16,
        style: { width: '100%' },
      };
  return (
    <Stack
      className="all-plans-swiper"
      sx={{ mb: { xs: 10, lg: 0 }, mt: 4, width: '100%' }}
    >
      <ContainerElement {...props}>{offeredSubscriptionList}</ContainerElement>
    </Stack>
  );
};

export default OfferedPlansList;

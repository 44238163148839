/* eslint-disable */
import { CircularProgress, Stack, Switch, TextField, Typography } from '@material-ui/core';
import { Coupon } from 'interfaces/coupon';
import getCoupon from 'modules/auth/pages/Register/services/getCoupon';
import React, { useState } from 'react';

const CoupnInput = ({ form, appliedCoupon, setAppliedCoupon }: any) => {
  const [activeCoupon, setActiveCoupon] = useState(true);
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
  const applyCouponCode = async () => {
    setIsApplyingCoupon(true);
    try {
      const { data: coupon } = await getCoupon(form.values.coupon!, form.values.planId!);
      setAppliedCoupon(coupon);
    } catch (err) {
      form.setFieldError('coupon', 'Cupom inválido');
    } finally {
      setIsApplyingCoupon(false);
    }
  };
  const getDiscountValue = () =>
    appliedCoupon?.valueType === 'percentage'
      ? `${appliedCoupon!.price}%`
      : `R$ ${appliedCoupon!.price.toFixed(2)}`.replace('.', ',');
  const resetAppliedCoupon = () => {
    setAppliedCoupon(undefined);
    form.setFieldValue('coupon', '');
  };
  const getCouponInputEndAdornment = () => {
    if (isApplyingCoupon) {
      return <CircularProgress color="inherit" size={24} />;
    }
    if (form.values.coupon) {
      return (
        <Typography
          data-testid="applyCoupon"
          sx={{
            color: 'secondary.main',
            cursor: 'pointer',
            fontSize: 14,
            fontWeight: 600,
          }}
          onClick={applyCouponCode}
        >
          Aplicar
        </Typography>
      );
    }
  };
  const getCouponMsg = form.values.coupon && (form.errors.coupon || (!appliedCoupon && 'Aplique o seu cupom'));
  return (
    <>
      <Stack sx={{ mt: 5 }}>
        {!appliedCoupon ? (
          <Stack sx={{ mb: 2, width: '100%' }}>
            <Typography
              sx={{
                color: 'grey.400',
                fontSize: 14,
                fontWeight: 'bold',
                mb: 2,
                mr: 3,
              }}
            >
              Tenho um cupom
              <Switch
                name="hasCoupon"
                checked={form.values.hasCoupon}
                color="secondary"
                sx={{ ml: 2 }}
                onClick={() => {
                  setActiveCoupon(!activeCoupon);
                  form.setFieldValue('hasCoupon', activeCoupon);
                }}
              />
            </Typography>
            <TextField
              disabled={activeCoupon}
              data-testid="coupon"
              name="coupon"
              error={!!getCouponMsg}
              helperText={getCouponMsg}
              fullWidth
              value={form.values.coupon?.toUpperCase()}
              sx={{ '.MuiFormHelperText-root': { ml: 0 } }}
              InputProps={{ endAdornment: getCouponInputEndAdornment() }}
              // onBlur={form.handleBlur}
              onChange={form.handleChange}
            />
          </Stack>
        ) : (
          <Stack
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                color: 'secondary.main',
                fontSize: 16,
                fontWeight: 'bold',
                mr: 3,
              }}
            >
              Cupom aplicado: {appliedCoupon.title}
            </Typography>
            <Typography
              sx={{
                color: 'secondary.main',
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              - {getDiscountValue()}
            </Typography>
            <Typography
              sx={{
                color: 'error.main',
                cursor: 'pointer',
                fontSize: 14,
                fontWeight: 'bold',
                mt: 2,
                textAlign: 'center',
                width: '100%',
              }}
              onClick={() => resetAppliedCoupon()}
            >
              Remover cupom
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default CoupnInput;

import { format } from 'date-fns-tz';

export const getDurationFromSecs = (secs: number) => {
  const secsInMillis = secs * 1000;
  const duration = new Date(secsInMillis).toISOString().substr(11, 8);
  return duration;
};

export const formataEventDate = (eventDate: Date) => {
  // o eventDate que vem na API não é UTC, ele já está com o fuso horário do Brasil
  // apesar de vir formatado como UTC, então precisamos fazer um pequeno ajuste
  // para ele ficar certo.
  // Por favor, não mexer nesse código antes de consultar o Bruno ou Felipe
  const eventDateLabel = format(eventDate, "dd/MM '|' HH:mm", {
    timeZone: 'UTC',
  });
  return eventDateLabel;
};

export const formatUtcEventDate = (eventDate: Date) => {
  const localDate = new Date(`${eventDate}`);
  const eventDateLabel = format(localDate, "dd/MM 'às' HH:mm");
  return eventDateLabel;
};

export const convertTimestampToDate = (utcSeconds: number) => {
  const localDateInUtc = new Date(0);
  localDateInUtc.setUTCSeconds(utcSeconds);
  const eventDateLabel = format(localDateInUtc, "dd/MM 'às' HH:mm");
  return eventDateLabel;
};
import { ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { DialogContext, IDialogContext } from './IDialogContext';
import { DEFAULT_DIALOG_OPTIONS, IDialogOptions } from './IDialogOptions';
import { IMessageOptions } from './IMessageOptions';

interface DialogProviderProps {
  children: ReactNode;
  context?: (context: IDialogContext) => void;
}

const DialogProvider = ({ children, context }: DialogProviderProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(DEFAULT_DIALOG_OPTIONS);
  const theme = useTheme();
  const { dataTestId, title, message, element, type, sx } = options;
  const openAsConfirmation = async (newOptions: IDialogOptions) => {
    setOptions({
      ...DEFAULT_DIALOG_OPTIONS,
      ...newOptions,
      type: 'confirmation',
    });
    setOpen(true);
  };
  const openAsDialog = async (newOptions: IMessageOptions) => {
    setOptions({ ...DEFAULT_DIALOG_OPTIONS, ...newOptions, type: 'dialog' });
    setOpen(true);
  };
  const confirmHandler = () => {
    setOpen(false);
    options.callback(true);
  };
  const close = async (result = false) => {
    setOpen(false);
    options?.callback?.(result);
  };
  const setClosable = (closable: boolean) => {
    setOptions((prevState) => ({ ...prevState, closable }));
  };
  const provider: IDialogContext = {
    openAsConfirmation,
    openAsDialog,
    setClosable,
    // @ts-ignore
    close,
  };
  const closeHandler = () => {
    if (options?.alternativeCloseHadler) {
      options.alternativeCloseHadler();
    } else {
      close(false);
    }
  };
  if (context) {
    context(provider);
  }
  return (
    <DialogContext.Provider value={provider}>
      {children}
      <Dialog
        data-testid={dataTestId}
        open={open}
        onBackdropClick={() => {
          if (options.isBlocked) return;
          closeHandler();
        }}
        onClose={(_, reason) => {
          if (!options?.closable) return;
          if (options?.isBlocked && reason === 'backdropClick') return;
          closeHandler();
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            position: { xs: 'fixed', lg: 'unset' },
            bottom: { xs: 0, lg: 'unset' },
            left: { xs: 0, lg: 'unset' },
            borderRadius: { xs: '16px 16px 0px 0px', lg: 'unset' },
            width: 342,
            ...sx,
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ height: 72 }}
          >
            <DialogTitle
              sx={{ color: 'common.white', fontSize: 16, fontWeight: 600 }}
            >
              {title}
            </DialogTitle>
            {options.closable && (
              <IconButton
                data-testid="closeIcon"
                sx={{
                  color: 'grey.300',
                  width: 12,
                  height: 12,
                  mr: 5,
                  zIndex: theme.zIndex.modal,
                }}
                onClick={closeHandler}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
          <DialogContent sx={{ pt: 0 }}>
            {message ? (
              <DialogContentText sx={{ color: 'grey.200', fontSize: 14 }}>
                {message}
              </DialogContentText>
            ) : (
              element
            )}
          </DialogContent>
          {type === 'confirmation' && (
            <Box sx={{ p: 5, pt: 0 }}>
              <Button color="error" onClick={confirmHandler}>
                Confirmar
              </Button>
            </Box>
          )}
        </Box>
      </Dialog>
    </DialogContext.Provider>
  );
};

export default DialogProvider;

import { createContext } from 'react';
import { Plan } from 'interfaces/plan';
import { Partner } from 'interfaces/partner';

export interface NewSubscriptionValues {
  userId: string;
  email: string;
  planId: string;
  partners: string;
  cardHolderName: string;
  creditCard: string;
  expirationDate: string;
  cvv: string;
  cpf: string;
  debitCardHolderName: string;
  debitCard: string;
  debitExpirationDate: string;
  debitCVV: string;
  debitCPF: string;
  hasCoupon: boolean;
  coupon: string | undefined;
  usercpf: string;
  installments: number;
  paymentMethod: 'credit' | 'debit' | 'pix' | 'ame' | '';
}

interface SubscriptionsContextType {
  getPartner: Partner | null | undefined;
  userPlans: Plan[];
  setUserPlans: (userPlans: Plan[]) => void;
  plans: Plan[];
  setPlans: (plans: Plan[]) => void;
}

const SubscriptionsContext = createContext<SubscriptionsContextType>(
  {} as SubscriptionsContextType,
);

export default SubscriptionsContext;

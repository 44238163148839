import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDialog } from 'components/Dialog';
import { Plan } from 'interfaces/plan';
import warning from '../../../assets/png/warning.png';

interface UnrenewedSubscriptionsModalProps {
  userSubscriptions?: any[];
  regularizedSubscriptions?: Plan[];
  irregularSubscriptions?: Plan[];
}

const UnrenewedSubscriptionsModal = ({
  userSubscriptions,
  regularizedSubscriptions,
  irregularSubscriptions,
}: UnrenewedSubscriptionsModalProps) => {
  const dialog = useDialog();
  const findSubscription = (id: string) =>
    userSubscriptions?.find((sub) => sub.id === id);
  return (
    <Stack>
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <img src={warning} alt="" style={{ height: 53, width: 53 }} />
        <Typography
          sx={{ color: 'grey.500', fontSize: 20, fontWeight: 700, mt: 3 }}
        >
          Algo deu errado!
        </Typography>
        <Typography
          sx={{
            color: 'grey.400',
            fontSize: 14,
            fontWeight: 400,
            mt: 2,
            textAlign: 'center',
          }}
        >
          Houve um erro no pagamento de alguns dos seus planos. Você pode tentar
          novamente ou continuar acessando o conteúdos de seus outros planos.
        </Typography>
      </Stack>
      <Accordion
        sx={{ bgcolor: 'white', boxShadow: 'none', border: 'none', mt: 4 }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
            Planos renovados com sucesso
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {regularizedSubscriptions?.map((subscription: any) => (
            <Stack
              key={subscription.id}
              alignItems="center"
              direction="row"
              sx={{ mt: 2, ':first-child': { mt: 0 } }}
            >
              <Box
                sx={{
                  bgcolor: 'grey.400',
                  borderRadius: '50%',
                  height: 5,
                  mr: 2,
                  width: 5,
                }}
              />
              <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
                {findSubscription(subscription.planId).title}
              </Typography>
            </Stack>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ bgcolor: 'white', boxShadow: 'none', border: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
            Planos não renovados
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {irregularSubscriptions?.map((subscription: any) => (
            <Stack
              key={subscription.id}
              alignItems="center"
              direction="row"
              sx={{ mt: 2, ':first-child': { mt: 0 } }}
            >
              <Box
                sx={{
                  bgcolor: 'grey.400',
                  borderRadius: '50%',
                  height: 5,
                  mr: 2,
                  width: 5,
                }}
              />
              <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
                {findSubscription(subscription.planId).title}
              </Typography>
            </Stack>
          ))}
        </AccordionDetails>
      </Accordion>
      <Button
        fullWidth
        sx={{ height: 60, mt: 4 }}
        onClick={() => dialog.close()}
      >
        Tentar novamente
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        sx={{ height: 60, mt: 2, color: 'secondary.main' }}
      >
        Continuar acessando
      </Button>
    </Stack>
  );
};

export default UnrenewedSubscriptionsModal;

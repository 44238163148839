import { Stack, useTheme } from '@material-ui/core';
import { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SwiperSlide } from 'swiper/react';
import Slide from 'components/Slide';
import useIsPortrait from 'modules/auth/pages/Register/hooks/use-is-portrait';
import {
  ContentResponse,
  SubSectionContentDTO,
} from 'services/Section/SectionService.types';
import { hexToRgba } from 'utils/hexToRgba';
import BannerGradient from './BannerGradient';
import HeroSliderContent from './HeroSliderContent';
import './styles.css';

interface HeroProps {
  data: ContentResponse;
}

const sortByPosition = (
  prevValue: SubSectionContentDTO,
  nextValue: SubSectionContentDTO,
) => Math.abs(prevValue.position) - Math.abs(nextValue.position);

const Hero = ({ data }: HeroProps) => {
  const isPortrait = useIsPortrait();
  const { palette } = useTheme();
  const infoMainColor = palette.info.main;
  const infoMainWithoutOpacity = hexToRgba(infoMainColor, 0);
  const featuredContent = useMemo(() => {
    const getLiveContents = data.featuredContent.filter(
      (currentContent) => currentContent.isLive,
    );
    const getContentsThatIsNotLive = data.featuredContent.filter(
      (currentContent) => !currentContent.isLive,
    );
    const sortLiveContentsByPosition = getLiveContents.sort(sortByPosition);
    const sortContentsThatIsNotLiveByPosition =
      getContentsThatIsNotLive.sort(sortByPosition);
    return [
      ...sortLiveContentsByPosition,
      ...sortContentsThatIsNotLiveByPosition,
    ];
  }, []);
  const getResponsiveSlides = (
    landscapeImage: string,
    portraitImage?: string,
  ) => {
    if (isPortrait && portraitImage !== '') {
      return portraitImage;
    }
    return landscapeImage;
  };
  return (
    <>
      <Stack
        sx={{
          width: '100%',
          '.swiper-button-next': { display: 'none' },
          '.swiper-button-prev': { display: 'none' },
        }}
      >
        {!!featuredContent?.length && (
          <Slide
            className="mySwiper"
            effect="fade"
            watchSlidesProgress
            data-testid="section-swiper-content-menu"
            sliderProps={{
              pagination: { clickable: true },
              spaceBetween: 4,
              loop: featuredContent?.length > 1,
              autoplay: { delay: 3000, disableOnInteraction: false },
            }}
          >
            {!featuredContent?.length && <SwiperSlide />}
            {featuredContent?.map((eachContent) => (
              <SwiperSlide
                key={eachContent.id}
                id="swiper-sortableSectionContents"
              >
                <LazyLoadImage
                  alt={getResponsiveSlides(
                    eachContent.thumbnail,
                    eachContent.thumbnailPortrait,
                  )}
                  src={getResponsiveSlides(
                    eachContent.thumbnail,
                    eachContent.thumbnailPortrait,
                  )}
                  style={{ width: '100%' }}
                />
                <HeroSliderContent content={eachContent as any} />
                <BannerGradient />
              </SwiperSlide>
            ))}
          </Slide>
        )}
      </Stack>
      <Stack
        sx={{
          background: `linear-gradient(180deg, ${infoMainColor} 0%, ${infoMainWithoutOpacity} 100%);`,
          backgroundPositionY: 'top',
          backgroundSize: 'cover',
          height: 100,
          width: '100%',
        }}
      />
    </>
  );
};

export default Hero;

const formatResultCountMessage = (
  countResults: number,
  existSectionSelected: boolean,
): string => {
  const message = `Exibindo ${countResults} resultados de pequisa ${
    existSectionSelected ? 'dentro desta seção' : ''
  }`;
  return message;
};

export default formatResultCountMessage;

import LocalStorageService from 'services/local-storage.service';
import { LocalStorageKeys } from 'utils/localStorageKeys';

const localStorageService = new LocalStorageService();

const useRegisterLocalStorage = () => {
  const setUser = (user: string) => localStorageService.set(LocalStorageKeys.REGISTER_USER_STEP, user);

  const setQuestion = (question: string) => localStorageService.set(LocalStorageKeys.REGISTER_QUESTION_STEP, question);

  const setSuccess = () => localStorageService.set(LocalStorageKeys.REGISTER_PAYMENY_STEP, 'true');

  const getUser = () => localStorageService.get(LocalStorageKeys.REGISTER_USER_STEP);

  const getQuestion = () => localStorageService.get(LocalStorageKeys.REGISTER_QUESTION_STEP);

  const getSuccess = () => localStorageService.get(LocalStorageKeys.REGISTER_PAYMENY_STEP);

  const clearRegisterLocalStorage = () => {
    localStorageService.delete(LocalStorageKeys.REGISTER_USER_STEP);
    localStorageService.delete(LocalStorageKeys.REGISTER_QUESTION_STEP);
    localStorageService.delete(LocalStorageKeys.REGISTER_PAYMENY_STEP);
    localStorageService.delete(LocalStorageKeys.LOGGED_IN);
  };

  return {
    setUser,
    setQuestion,
    setSuccess,
    getUser,
    getQuestion,
    getSuccess,
    clearRegisterLocalStorage,
  };
};

export default useRegisterLocalStorage;

import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const AuthTemplate = lazy(() => import('./components/AuthTemplate'));
const Login = lazy(() => import('./pages/Login'));
const NewRegister = lazy(() => import('./pages/NewRegister'));
const RecoveryPassword = lazy(() => import('./pages/RecoveryPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Register = lazy(() => import('./pages/Register'));

const Auth = () => (
  <Suspense fallback={<CircularProgress />}>
    <Routes>
      <Route element={<AuthTemplate />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/recovery-password" element={<RecoveryPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/newRegister/user/*" element={<NewRegister />} />
        <Route path="/newRegister/question/*" element={<NewRegister />} />
        <Route path="/newRegister/payment/*" element={<NewRegister />} />
        <Route path="/newRegister/success/*" element={<NewRegister />} />
      </Route>
    </Routes>
  </Suspense>
);

export default Auth;

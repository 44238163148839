import { Stack } from '@material-ui/core';
import { ConsumableContent } from 'interfaces/Content';
import useIsPortrait from 'modules/auth/pages/Register/hooks/use-is-portrait';
import React from 'react';
import { getVideoUrl } from '../utils/get-video-url';

interface Props {
  contentData: ConsumableContent;
}

const Player = ({ contentData }: Props) => {
  const isPortrait = useIsPortrait();
  const contentIsExternal = contentData.externalContent === true;
  return (
    <Stack
      data-testid="content-wrapper"
      sx={{
        maxWidth: '100%',
        height: isPortrait ? `calc(50vh)` : `calc(75vh)`,
        width: '100%',
      }}
    >
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        id="spalla-player"
        src={getVideoUrl(contentData)}
        scrolling={ contentIsExternal ? "yes" : "no"}
        title={contentData!.title}
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
        }}
      />
    </Stack>
  );
};

export default Player;

import { Stack, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import { SwiperSlide } from 'swiper/react';
import Slide from 'components/Slide';
import { SectionDataDTO } from 'services/Section/SectionService.types';
import { VideoContentCard } from './VideoContentCard';

interface MultiSectionItemsProps {
  sectionData: SectionDataDTO;
}

const MultiSectionItems: ForwardRefRenderFunction<
  HTMLDivElement,
  MultiSectionItemsProps
> = ({ sectionData }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const sortContentsByPosition = (
    a: { position: number },
    b: { position: number },
  ) => a.position - b.position;
  const multiSectionItems = sectionData?.superSectionItems;
  const contentData = useRef<SectionDataDTO['superSectionItems']>(
    multiSectionItems?.sort(sortContentsByPosition),
  );
  const slidePerGroupsCalc = isMobile
    ? (window.innerWidth - 12) / (197 + 8)
    : (window.innerWidth - 95 - 8) / (224 + 16);
  const renderItem = useCallback(
    (eachContent, index) => (
      <SwiperSlide key={`Slide ${eachContent.id}`} virtualIndex={index}>
        <VideoContentCard isMultiSection data={eachContent} />
      </SwiperSlide>
    ),
    [],
  );
  return (
    <Stack
      ref={ref}
      key={sectionData.id}
      sx={{
        mb: 7,
        '.swiper-button-next ': { top: '52%' },
        '.swiper-button-prev': { top: '52%' },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="spaceBetween"
        sx={{
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'primary',
            fontSize: 26,
            color: 'common.white',
            mb: '26px',
            ml: { xs: 0, lg: '95px' },
          }}
        >
          {sectionData.title}
        </Typography>
      </Stack>
      <Slide
        data-testid="sections-menu"
        sliderProps={{
          virtual: true,
          slidesPerGroup: isMobile ? 1 : 4,
          slidesPerView: slidePerGroupsCalc,
          spaceBetween: 6,
          style: { paddingLeft: isMobile ? 0 : '95px' },
        }}
        style={{ width: '100%' }}
        sx={{ height: { xs: 255, lg: 290 } }}
      >
        {contentData.current.map(renderItem)}
      </Slide>
    </Stack>
  );
};

export default forwardRef(MultiSectionItems);

import React, { forwardRef } from "react";
import MaskedInput from "react-text-mask";

export const ExpirationDateInput = forwardRef((props, ref) => {
  const setRef = React.useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={setRef}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
      guide={false}
      placeholderChar={'\u2000'}
    />
  );
}) as any;

export const CvvMask = forwardRef((props, ref) => {
  const setRef = React.useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={setRef}
      mask={[/\d/, /\d/, /\d/]}
      guide={false}
      placeholderChar={'\u2000'}
    />
  );
}) as any;
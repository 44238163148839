/* eslint-disable react/require-default-props */
import { Box, Stack, Typography } from '@material-ui/core';
import { add } from 'date-fns';
import React from 'react';
import retentionSuccess from '../../../assets/jpg/retentionSuccess.jpg';
import { formatDate } from '../../../utils/date';
import PlaceholderNavigationButton from '../components/PlaceholderNavigationButton';
import { SubscriptionPageProps, SubscriptionPeriod } from '../typings';

const RetentionSuccess = ({ subscription }: SubscriptionPageProps) => {
  const getEndDateByPeriod = subscription?.period === SubscriptionPeriod.Monthly
    ? add(new Date(subscription!.createdAt), { months: 2 })
    : add(new Date(subscription!.createdAt), { years: 2 });
  const getEndDate = subscription?.createdAt && subscription?.period
    ? ` ${formatDate(getEndDateByPeriod, "dd/MM/yyyy")}`
    : ` final da vigência da sua assinatura`;
  return (
    <Stack sx={{ flexDirection: { xs: 'column', lg: 'row' }, gap: 5 }}>
      <Stack
        sx={{
          flex: 1,
          justifyContent: 'center',
          px: 3,
          textAlign: 'left',
        }}
      >
        <Typography sx={{ fontSize: { xs: 24, lg: 32 }, fontWeight: 600 }}>
          Ficamos felizes que você resolveu ficar...
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, lg: 20 }, mt: { xs: 3, lg: 6 } }}>
          A renovação do seu plano foi concluída com sucesso.
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, lg: 20 }, mt: { xs: 3, lg: 6 } }}>
          Você desfrutou de 30% OFF no plano atual, com duração até{getEndDate}. Após este período, a cobrança do valor integral do plano será realizada automaticamente no seu meio de pagamento registrado.
        </Typography>
        <PlaceholderNavigationButton
          destination="/home"
          label="Voltar para a plataforma"
        />
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <Box
          title={subscription?.title && subscription.title}
          sx={{
            backgroundImage: `url(${retentionSuccess})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            height: { xs: 200, lg: 350 },
            width: { xs: '100%', lg: 609 },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default RetentionSuccess;

export enum NotificationType {
  contentStartingSoon = 'contentStartingSoon',
  planRenewal = 'planRenewal',
  recordingReady = 'recordingReady',
}

export interface Notification {
  id: string;
  type: NotificationType;
  createdAt: Date;
}

import qs from 'qs';
import axiosHelper from 'helpers/axios';
import { UserProfile } from 'modules/account/typings';

interface UserAvatarPayload {
  avatar: string;
}

const saveUserAvatar = (input: UserAvatarPayload) =>
  axiosHelper.patch<UserAvatarPayload, UserProfile>(
    '/user/profile',
    qs.stringify(input),
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );

export default saveUserAvatar;

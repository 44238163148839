import { Container, Stack } from '@material-ui/core';
import React from 'react';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';

interface MainContainerProps {
  children: React.ReactNode;
  bgColor: string;
  headerHasButton: boolean;
  headerBgColor: string;
  headerButtonColor: string;
  headerTextColor: string;
  headerButtonText: string;
  headerButtonLink: string;
  footerHasVerticalLayout: boolean;
  footerBgColor: string;
  footerIconsColor: string;
  footerTwitterLink: string;
  footerInstagramLink: string;
  footerFacebookLink: string;
  footerYoutubeLink: string;
}

const MainContainer = ({
  bgColor = 'grey.600',
  children = null,
  headerHasButton = false,
  headerBgColor = '#171717',
  headerButtonColor = '#DE2A2F',
  headerTextColor = '#FFFFFF',
  headerButtonText = 'Assine agora!',
  headerButtonLink = '',
  footerHasVerticalLayout = true,
  footerBgColor = '#171717',
  footerIconsColor = '#FFFFFF',
  footerTwitterLink = 'www.twitter.com',
  footerFacebookLink = 'www.facebook.com',
  footerInstagramLink = 'www.instagram.com',
  footerYoutubeLink = 'www.youtube.com',
}: MainContainerProps) => (
  <Stack sx={{ bgcolor: footerBgColor, minHeight: '100vh' }}>
    <PageHeader
      bgColor={headerBgColor}
      buttonColor={headerButtonColor}
      hasButton={headerHasButton}
      textColor={headerTextColor}
      buttonText={headerButtonText}
      buttonLink={headerButtonLink}
    />
    <Stack
      sx={{
        bgcolor: bgColor,
        gap: { xs: 5, lg: 10 },
        p: { xs: 5, lg: 10 },
        mb: 'auto',
      }}
    >
      <Container maxWidth="lg">{children}</Container>
    </Stack>
    <PageFooter
      bgColor={footerBgColor}
      iconsColor={footerIconsColor}
      hasVerticalLayout={footerHasVerticalLayout}
      twitterLink={footerTwitterLink}
      instagramLink={footerInstagramLink}
      facebookLink={footerFacebookLink}
      youtubeLink={footerYoutubeLink}
    />
  </Stack>
);

export default MainContainer;

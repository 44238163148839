import { Button, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

interface ActionButtonsProps {
  allowedToSubmit: boolean;
  isEditing: boolean;
  onClick: () => void;
  saveChanges: () => void;
}

const ActionButtons = ({
  allowedToSubmit,
  isEditing,
  saveChanges,
  onClick,
}: ActionButtonsProps) => (
  <Stack
    sx={{
      alignItems: 'center',
      flexDirection: { xs: 'column', lg: 'row' },
      justifyContent: 'space-between',
      mb: { xs: 11, lg: 0 },
      width: '100%',
    }}
  >
    <LoadingButton
      variant="contained"
      color="secondary"
      data-testid="submitButton"
      type="submit"
      loading={isEditing}
      disabled={allowedToSubmit}
      size="large"
      sx={{
        fontSize: { xs: 14, lg: 16 },
        mb: { xs: 4, lg: 0 },
        mt: { xs: 8, lg: 0 },
        minWidth: { xs: '100%', lg: 179 },
      }}
      onClick={saveChanges}
    >
      Salvar alterações
    </LoadingButton>
    <Button
      variant="contained"
      color="error"
      data-testid="accountCancellationButton"
      size="large"
      sx={{
        bgcolor: 'grey.500',
        fontSize: { xs: 14, lg: 16 },
        width: { xs: '100%', lg: 179 },
        '&:hover': {
          bgcolor: 'error.main',
        },
      }}
      onClick={onClick}
    >
      Encerrar conta
    </Button>
  </Stack>
);

export default ActionButtons;

import { FormikProps } from 'formik';
import { createContext, Dispatch, SetStateAction } from 'react';
import { Coupon } from '../../../../interfaces/coupon';
import { Plan } from '../../../../interfaces/plan';
import { SignupInput } from './typings';

type ContextType = {
  appliedCoupon: Coupon | undefined;
  setAppliedCoupon: Dispatch<SetStateAction<Coupon | undefined>>;
  currentStep: number;
  form: FormikProps<SignupInput>;
  isSubmitting: boolean;
  plans: Plan[];
  paymentStatusMsg: string | undefined;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  loggedIn: boolean;
  checkStatus: boolean;
  signinTryouts: number;
  responseMessage: string | undefined;
  hasBackFromPix: boolean,
  setHasBackFromPix: Dispatch<SetStateAction<boolean>>;
  setSelectedPlanPrice: Dispatch<SetStateAction<number>>;
  hasRequired: boolean | undefined;
  setPaymentStatusMsg: (msg: string | undefined) => void;
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;

import qs from 'qs';
import axiosHelper from 'helpers/axios';

export interface CheckData {
  id?: string;
  name: string;
  usercpf: string;
  oldcpf?: string;
  phone: string;
}

const updateUserData = ({ id, usercpf, oldcpf, phone, name }: CheckData) =>
  axiosHelper.patch(
    '/user/profile/update-deprecated',
    qs.stringify({ id, usercpf, oldcpf, phone, name }),
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );

export default updateUserData;

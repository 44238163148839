import { Stack, useMediaQuery, useTheme } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import OfferedPlanCardPlaceholder from './OfferedPlanCardPlaceholder';

const OfferedPlansListPlaceholder = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Stack
      className="all-plans-swiper"
      sx={{ mb: { xs: 10, lg: 0 }, mt: 4, width: '100%' }}
    >
      <Swiper
        navigation
        slidesPerView="auto"
        spaceBetween={isMobile ? 8 : 16}
        style={{ width: '100%' }}
      >
        {[...Array(3)].map(() => (
          <SwiperSlide>
            <OfferedPlanCardPlaceholder />
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  );
};

export default OfferedPlansListPlaceholder;

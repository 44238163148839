import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  Select,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import creditCardValidator from 'card-validator';
import { CardNumberVerification } from 'card-validator/dist/card-number';
import React, {
  forwardRef,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import MaskedInput from 'react-text-mask';
import { useSearchParams } from 'react-router-dom';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import MaskedCreditCardNumberInput from '../../../../account/pages/Payment/MaskedCreditCardNumberInput';
import { withEnter } from '../../../../../utils/form';
import Context from '../Context';
import cardsImgMapper from '../providers/cards';
import getCoupon from '../services/getCoupon';
import { CardType } from '../typings';
import PlanAccordion from './PlanAccordion';
import { preventFloatingNumbers } from '../../../../../utils/number';
import { PlanType } from '../../../../../interfaces/plan';
import '../style/remove-arrow.css';

export const ExpirationDateInput = forwardRef((props, ref) => {
  const { form } = useContext(Context);
  const { values } = form;
  const expirationDate = values?.expirationDate?.toString() || '';
  const setRef = React.useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={setRef}
      mask={
        expirationDate[0] === '0'
          ? [/[01]/, /\d/, '/', /\d/, /\d/]
          : [/[01]/, /[012]/, '/', /\d/, /\d/]
      }
      placeholderChar={'\u2000'}
    />
  );
}) as any;

export const CpfMaskInput = forwardRef((props, ref) => {
  const setRef = React.useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={setRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      guide={false}
      placeholderChar={'\u2000'}
    />
  );
}) as any;

export const CvvMask = forwardRef((props, ref) => {
  const setRef = React.useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    },
    [ref],
  );

  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={setRef}
      mask={[/\d/, /\d/, /\d/, /\d/]}
      guide={false}
      placeholderChar={'\u2000'}
    />
  );
}) as any;

export const getInstallments = (quantity?: number) =>
  [...Array(quantity)].map((_, index) => (
    <MenuItem
      value={index + 1}
      sx={{
        bgcolor: 'common.white',
        '&.Mui-selected': {
          ':hover': { bgcolor: 'common.white' },
          bgcolor: 'common.white',
        },
        ':hover': { bgcolor: 'common.white' },
      }}
    >
      {index + 1}
    </MenuItem>
  ));

const CreditCardStep = () => {
  const sendDataToGTM = useGTMDispatch();
  const { palette } = useTheme();
  const [activeCoupon, setActiveCoupon] = useState(true);
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [params] = useSearchParams();
  const partnerCode = params.get('code') as string;
  const {
    currentStep,
    form,
    plans,
    appliedCoupon,
    setAppliedCoupon,
    setCurrentStep,
    setPaymentStatusMsg,
  } = useContext(Context);
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
  const [cardDetails, setCardDetails] = useState<
    CardNumberVerification | undefined
  >();
  const haveInstallments =
    form.values?.plan?.type === 'season' ||
    (form.values?.plan?.installments && form.values.plan.installments > 1);
  const handleNextStep = async () => {
    form.setTouched({
      cardHolderName: true,
      creditCard: true,
      expirationDate: true,
      cvv: true,
      cpf: true,
      debitCardHolderName: true,
      debitCard: true,
      debitExpirationDate: true,
      debitCVV: true,
      debitCPF: true,
      coupon: true,
      paymentMethod: true,
    });
    const errors = await form.validateForm(form.values);
    const currentStepContainsErrors =
      errors.paymentMethod ||
      errors.cardHolderName ||
      errors.creditCard ||
      errors.expirationDate ||
      errors.cvv ||
      errors.cpf ||
      errors.debitCardHolderName ||
      errors.debitCard ||
      errors.debitExpirationDate ||
      errors.debitCVV ||
      errors.debitCPF ||
      (!!form.values.coupon && !appliedCoupon);
    if (currentStepContainsErrors) {
      setHasFormErrors(true);
      return;
    }
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    sendDataToGTM({ event: 'CreditCardStep', coupon: form.values.coupon });
  };
  const applyCouponCode = async () => {
    setIsApplyingCoupon(true);
    try {
      const { data: coupon } = await getCoupon(
        form.values.coupon!,
        form.values.plan?.id!,
      );
      setAppliedCoupon(coupon);
    } catch (err) {
      form.setFieldError('coupon', 'Cupom inválido');
    } finally {
      setIsApplyingCoupon(false);
    }
  };
  useEffect(() => {
    if (form.values.paymentMethod === 'free') {
      const paymentMethods = ['credit', 'debit', 'pix', 'ame', 'free'];
      const defaultPaymentMethod = paymentMethods.find(
        (method) => !!form.values.plan!.paymentType.includes(method),
      );
      form.setFieldValue('paymentMethod', defaultPaymentMethod);
    }
  }, []);
  const getCouponInputEndAdornment = () => {
    if (isApplyingCoupon) {
      return <CircularProgress color="inherit" size={24} />;
    }
    if (form.values.coupon) {
      return (
        <Typography
          data-testid="applyCoupon"
          sx={{
            color: 'tertiary.main',
            cursor: 'pointer',
            fontFamily: 'secondary',
            fontSize: 14,
          }}
          onClick={applyCouponCode}
        >
          Aplicar
        </Typography>
      );
    }
    return (
      <Typography
        sx={{
          color: form.values.coupon ? 'secondary.main' : 'secondary.light',
          fontFamily: 'secondary',
          fontSize: 14,
        }}
      >
        Aplicar
      </Typography>
    );
  };
  const resetAppliedCoupon = () => {
    setAppliedCoupon(undefined);
    form.setFieldValue('coupon', '');
  };
  const paymentInfoRef: any = useRef(null);
  useEffect(() => {
    if (partnerCode) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
    }
  }, [currentStep]);
  useEffect(() => {
    if (paymentInfoRef) {
      paymentInfoRef.current.scrollIntoView();
    }
  }, [paymentInfoRef]);
  useEffect(() => {
    if (appliedCoupon) {
      if (appliedCoupon.planId !== form.values.plan?.id) {
        resetAppliedCoupon();
      }
    }
  }, [appliedCoupon, form.values.plan]);
  const getDiscountValue = () =>
    appliedCoupon?.valueType === 'percentage'
      ? `${appliedCoupon!.price}%`
      : `R$ ${appliedCoupon!.price.toFixed(2)}`.replace('.', ',');
  const getCouponMsg =
    form.values.coupon &&
    (form.errors.coupon || (!appliedCoupon && 'Aplique o seu cupom'));
  const isFreeOrPartnerPlan = [PlanType.free, PlanType.partner].includes(
    form.values.plan!.type,
  );
  const [expanded, setExpanded] = useState<string | false>(false);
  const setExpandedHandler =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const isPlanSupportCreditCard =
    form.values.plan?.paymentType?.includes('credit');
  const isPlanSupportDebitCard =
    form.values.plan?.paymentType?.includes('debit');
  const isPlanSupportPix = form.values.plan?.paymentType?.includes('pix');
  const isPlanSupportAme = form.values.plan?.paymentType?.includes('ame');
  useEffect(() => {
    setPaymentStatusMsg(undefined);
  }, [currentStep]);
  const accordionSwitch = (field: string, painel: string) => {
    if (expanded === painel) {
      setExpanded(false);
    } else {
      form.setFieldValue('paymentMethod', field);
      setExpanded(painel);
    }
  };
  const paymentMethodIsSelected = (field: string) =>
    field === form.values.paymentMethod;
  return (
    <Box
      style={{
        marginBottom: 100,
      }}
    >
      <Typography
        ref={paymentInfoRef}
        sx={{
          color: 'grey.200',
          fontFamily: 'tertiary',
          fontSize: 16,
          mt: 5,
          textTransform: 'uppercase',
        }}
      >
        Dados de pagamento
      </Typography>
      <Typography
        sx={{
          color: 'grey.200',
          fontFamily: 'primary',
          fontSize: 14,
          mb: 3,
          mt: 5,
        }}
      >
        Plano escolhido
      </Typography>
      {plans.map((plan) => (
        <PlanAccordion
          key={plan.id}
          plan={plan}
          selected={plan?.id === form.values?.plan?.id}
        />
      ))}
      {isFreeOrPartnerPlan ? null : (
        <Stack sx={{ mt: 5 }}>
          {!appliedCoupon ? (
            <Stack sx={{ mb: 2, width: '100%' }}>
              <Typography
                sx={{
                  color: 'grey.200',
                  fontFamily: 'primary',
                  fontSize: 14,
                  mb: 2,
                  mr: 3,
                }}
              >
                Tenho um cupom
                <Switch
                  name="hasCoupon"
                  checked={form.values.hasCoupon}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: `${palette.tertiary.main} !important`,
                    },
                    ml: 2,
                  }}
                  onClick={() => {
                    setActiveCoupon(!activeCoupon);
                    form.setFieldValue('hasCoupon', activeCoupon);
                  }}
                />
              </Typography>
              {form.values.hasCoupon && (
                <TextField
                  variant="filled"
                  disabled={activeCoupon}
                  data-testid="coupon"
                  name="coupon"
                  label="Insira o cupom"
                  error={!!getCouponMsg}
                  helperText={getCouponMsg}
                  fullWidth
                  value={form.values.coupon?.toUpperCase()}
                  sx={{
                    '.MuiOutlinedInput-input': {
                      color: 'grey.200',
                    },
                    '.MuiFormHelperText-root': { ml: 0 },
                    '.MuiOutlinedInput-root': {
                      backgroundColor: 'rgba(243, 243, 243, .2)',
                    },
                  }}
                  InputProps={{ endAdornment: getCouponInputEndAdornment() }}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                />
              )}
            </Stack>
          ) : (
            <Stack
              sx={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  color: 'secondary.main',
                  fontFamily: 'secondary',
                  fontSize: 16,
                  mr: 3,
                }}
              >
                Cupom aplicado: {appliedCoupon.title.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  color: 'secondary.main',
                  fontFamily: 'primary',
                  fontSize: 16,
                }}
              >
                - {getDiscountValue()}
              </Typography>
              <Typography
                sx={{
                  color: 'error.main',
                  cursor: 'pointer',
                  fontFamily: 'primary',
                  fontSize: 14,
                  mt: 2,
                  textAlign: 'center',
                  width: '100%',
                }}
                onClick={() => resetAppliedCoupon()}
              >
                Remover cupom
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      <Typography
        sx={{
          color: 'grey.200',
          fontFamily: 'primary',
          fontSize: 14,
          mb: 2,
          mt: 5,
        }}
      >
        Formas de Pagamentos
      </Typography>
      {isFreeOrPartnerPlan
        ? null
        : isPlanSupportCreditCard && (
            <Accordion
              data-testid="creditCardAccordion"
              expanded={expanded === 'painel1'}
              sx={{
                bgcolor: 'transparent',
                mb: 2,
                '.MuiSvgIcon-root': {
                  color: 'grey.200',
                },
              }}
              onChange={() => accordionSwitch('credit', 'painel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  bgcolor: 'rgba(243, 243, 243, .2)',
                  border: '1px solid',
                  borderColor: paymentMethodIsSelected('credit')
                    ? 'tertiary.main'
                    : 'primary.light',
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Radio
                    checked={form.values.paymentMethod === 'credit'}
                    value="credit"
                    onChange={(e) =>
                      form.setFieldValue('paymentMethod', e.target.value)
                    }
                    sx={{
                      '.MuiSvgIcon-root': {
                        color: 'rgba(182, 182, 182, 0.25)',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        color: 'tertiary.main',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: 'grey.200',
                      fontFamily: 'primary',
                      fontSize: 14,
                    }}
                  >
                    Cartão de Crédito
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: 'transparent' }}>
                <Box>
                  <TextField
                    variant="filled"
                    fullWidth
                    name="cardHolderName"
                    label="Nome do Titular"
                    value={form.values.cardHolderName}
                    error={
                      form.touched.cardHolderName &&
                      !!form.errors.cardHolderName
                    }
                    helperText={
                      form.touched.cardHolderName && form.errors.cardHolderName
                    }
                    inputProps={{ 'data-testid': 'cardholderName' }}
                    sx={{
                      mt: 5,
                      '.MuiFormHelperText-root': { ml: 0 },
                    }}
                    onBlur={form.handleBlur}
                    onKeyPress={withEnter(handleNextStep)}
                    onChange={form.handleChange}
                  />
                  <TextField
                    variant="filled"
                    fullWidth
                    name="creditCard"
                    label="Número do cartão"
                    type="tel"
                    value={form.values.creditCard}
                    placeholder="0000-0000-0000-0000"
                    error={form.touched.creditCard && !!form.errors.creditCard}
                    helperText={
                      form.touched.creditCard && form.errors.creditCard
                    }
                    InputProps={{
                      endAdornment: cardDetails?.card && (
                        <SvgIcon
                          component={
                            cardsImgMapper[cardDetails!.card!.type as CardType]
                          }
                          viewBox="0 0 780 500"
                          sx={{ height: 19, width: 29 }}
                        />
                      ),
                      inputComponent: MaskedCreditCardNumberInput,
                      inputProps: { 'data-testid': 'creditCardNumber' },
                    }}
                    sx={{
                      mt: 5,
                      '.MuiFormHelperText-root': { ml: 0 },
                    }}
                    onBlur={form.handleBlur}
                    onKeyPress={withEnter(handleNextStep)}
                    onChange={(evt) => {
                      form.setFieldValue('cardBrand', cardDetails?.card?.type);
                      form.handleChange(evt);
                      setCardDetails(
                        creditCardValidator.number(form.values.creditCard),
                      );
                    }}
                  />
                </Box>
                <Grid container spacing={4} sx={{ mt: 4 }}>
                  <Grid item xs={3}>
                    <TextField
                      variant="filled"
                      fullWidth
                      name="cvv"
                      label="CVV"
                      type="tel"
                      value={form.values.cvv}
                      placeholder="000"
                      error={form.touched.cvv && !!form.errors.cvv}
                      helperText={form.touched.cvv && form.errors.cvv}
                      InputProps={{
                        inputComponent: CvvMask,
                        inputProps: { 'data-testid': 'cvvInput' },
                      }}
                      sx={{
                        mt: 2,
                        '.MuiFormHelperText-root': { ml: 0 },
                      }}
                      onBlur={form.handleBlur}
                      onKeyPress={withEnter(handleNextStep)}
                      onChange={form.handleChange}
                      onKeyDown={(e) =>
                        preventFloatingNumbers(e, [
                          'E',
                          'e',
                          '-',
                          '+',
                          ',',
                          '.',
                        ])
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="filled"
                      fullWidth
                      name="expirationDate"
                      label="Validade"
                      type="tel"
                      value={form.values.expirationDate}
                      placeholder="MM/AA"
                      error={
                        form.touched.expirationDate &&
                        !!form.errors.expirationDate
                      }
                      helperText={
                        form.touched.expirationDate &&
                        form.errors.expirationDate
                      }
                      InputProps={{
                        inputComponent: ExpirationDateInput,
                        inputProps: { 'data-testid': 'expirationDate' },
                      }}
                      sx={{
                        mt: 2,
                        '.MuiFormHelperText-root': { ml: 0 },
                      }}
                      onBlur={form.handleBlur}
                      onKeyPress={withEnter(handleNextStep)}
                      onChange={form.handleChange}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      variant="filled"
                      fullWidth
                      name="cpf"
                      type="tel"
                      label="CPF do titular"
                      value={form.values.cpf}
                      placeholder="000.000.000-00"
                      error={form.touched.cpf && !!form.errors.cpf}
                      helperText={form.touched.cpf && form.errors.cpf}
                      InputProps={{
                        inputComponent: CpfMaskInput,
                        inputProps: { 'data-testid': 'cpfInput' },
                      }}
                      sx={{
                        mt: 2,
                        '.MuiFormHelperText-root': { ml: 0 },
                      }}
                      onBlur={form.handleBlur}
                      onKeyPress={withEnter(handleNextStep)}
                      onChange={form.handleChange}
                    />
                  </Grid>
                  {haveInstallments && (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: 'grey.200',
                          fontFamily: 'primary',
                          fontSize: 14,
                        }}
                      >
                        Parcelas:
                      </Typography>
                      <Select
                        displayEmpty
                        data-testid="installmentsInput"
                        error={
                          form.touched.installments &&
                          !!form.errors.installments
                        }
                        name="installments"
                        renderValue={() => form.values.installments}
                        type="number"
                        value={form.values.installments}
                        sx={{
                          mt: 2,
                          bgcolor: 'rgba(243, 243, 243, .2)',
                          color: 'grey.100',
                          width: '100%',
                        }}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                      >
                        {getInstallments(form.values?.plan?.installments)}
                      </Select>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
      {isFreeOrPartnerPlan
        ? null
        : isPlanSupportDebitCard && (
            <Accordion
              expanded={expanded === 'painel2'}
              sx={{
                bgcolor: 'transparent',
                mb: 2,
                '.MuiSvgIcon-root': {
                  color: 'grey.200',
                },
              }}
              onChange={() => accordionSwitch('debit', 'painel2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  bgcolor: 'rgba(243, 243, 243, .2)',
                  border: '1px solid',
                  borderColor: paymentMethodIsSelected('debit')
                    ? 'tertiary.main'
                    : 'primary.light',
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Radio
                    checked={form.values.paymentMethod === 'debit'}
                    value="debit"
                    onChange={(e) =>
                      form.setFieldValue('paymentMethod', e.target.value)
                    }
                    sx={{
                      '.MuiSvgIcon-root': {
                        color: 'rgba(182, 182, 182, 0.25)',
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        color: 'tertiary.main',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: 'grey.200',
                      fontFamily: 'primary',
                      fontSize: 14,
                    }}
                  >
                    Cartão de Débito
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: 'transparent' }}>
                <Box>
                  <TextField
                    variant="filled"
                    fullWidth
                    name="debitCardHolderName"
                    label="Nome do Titular"
                    value={form.values.debitCardHolderName}
                    error={
                      form.touched.debitCardHolderName &&
                      !!form.errors.debitCardHolderName
                    }
                    helperText={
                      form.touched.debitCardHolderName &&
                      form.errors.debitCardHolderName
                    }
                    inputProps={{ 'data-testid': 'debitCardHolderName' }}
                    sx={{
                      mt: 5,
                      '.MuiFormHelperText-root': { ml: 0 },
                    }}
                    onBlur={form.handleBlur}
                    onKeyPress={withEnter(handleNextStep)}
                    onChange={form.handleChange}
                  />
                  <TextField
                    variant="filled"
                    fullWidth
                    name="debitCard"
                    label="Número do cartão"
                    type="tel"
                    value={form.values.debitCard}
                    error={form.touched.debitCard && !!form.errors.debitCard}
                    helperText={form.touched.debitCard && form.errors.debitCard}
                    placeholder="0000-0000-0000-0000"
                    InputProps={{
                      endAdornment: cardDetails?.card && (
                        <SvgIcon
                          component={
                            cardsImgMapper[cardDetails!.card!.type as CardType]
                          }
                          viewBox="0 0 780 500"
                          sx={{ height: 19, width: 29 }}
                        />
                      ),
                      inputComponent: MaskedCreditCardNumberInput,
                      inputProps: { 'data-testid': 'debitCardInput' },
                    }}
                    sx={{
                      mt: 5,
                      '.MuiFormHelperText-root': { ml: 0 },
                    }}
                    onBlur={form.handleBlur}
                    onKeyPress={withEnter(handleNextStep)}
                    onChange={(evt) => {
                      form.setFieldValue('cardBrand', cardDetails?.card?.type);
                      form.handleChange(evt);
                      setCardDetails(
                        creditCardValidator.number(form.values.debitCard),
                      );
                    }}
                  />
                </Box>
                <Grid container spacing={4} sx={{ mt: 4 }}>
                  <Grid item xs={3}>
                    <TextField
                      variant="filled"
                      fullWidth
                      name="debitCVV"
                      label="CVV"
                      type="tel"
                      value={form.values.debitCVV}
                      placeholder="000"
                      error={form.touched.debitCVV && !!form.errors.debitCVV}
                      helperText={form.touched.debitCVV && form.errors.debitCVV}
                      InputProps={{
                        inputComponent: CvvMask,
                        inputProps: { 'data-testid': 'cvvInput' },
                      }}
                      sx={{
                        mt: 2,
                        '.MuiFormHelperText-root': { ml: 0 },
                      }}
                      onBlur={form.handleBlur}
                      onKeyPress={withEnter(handleNextStep)}
                      onChange={form.handleChange}
                      onKeyDown={(e) =>
                        preventFloatingNumbers(e, [
                          'E',
                          'e',
                          '-',
                          '+',
                          ',',
                          '.',
                        ])
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="filled"
                      fullWidth
                      name="debitExpirationDate"
                      type="tel"
                      label="Validade"
                      value={form.values.debitExpirationDate}
                      placeholder="MM/AA"
                      error={
                        form.touched.debitExpirationDate &&
                        !!form.errors.debitExpirationDate
                      }
                      helperText={
                        form.touched.debitExpirationDate &&
                        form.errors.debitExpirationDate
                      }
                      InputProps={{
                        inputComponent: ExpirationDateInput,
                        inputProps: { 'data-testid': 'expirationDateInput' },
                      }}
                      sx={{
                        mt: 2,
                        '.MuiFormHelperText-root': { ml: 0 },
                      }}
                      onBlur={form.handleBlur}
                      onKeyPress={withEnter(handleNextStep)}
                      onChange={form.handleChange}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      variant="filled"
                      fullWidth
                      name="debitCPF"
                      type="tel"
                      label="CPF do titular"
                      value={form.values.debitCPF}
                      placeholder="000.000.000-00"
                      error={form.touched.debitCPF && !!form.errors.debitCPF}
                      helperText={form.touched.debitCPF && form.errors.debitCPF}
                      InputProps={{
                        inputComponent: CpfMaskInput,
                        inputProps: { 'data-testid': 'cpfInput' },
                      }}
                      sx={{
                        mt: 2,
                        '.MuiFormHelperText-root': { ml: 0 },
                      }}
                      onBlur={form.handleBlur}
                      onKeyPress={withEnter(handleNextStep)}
                      onChange={form.handleChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
      {isFreeOrPartnerPlan
        ? null
        : isPlanSupportPix && (
            <Stack
              alignItems="center"
              direction="row"
              sx={{
                bgcolor: 'rgba(182, 182, 182, 0.25)',
                border: '1px solid',
                borderColor: paymentMethodIsSelected('pix')
                  ? 'tertiary.main'
                  : 'primary.light',
                borderRadius: 1,
                boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.2)',
                height: 66,
                mb: 2,
                pl: 2,
              }}
            >
              <Radio
                checked={form.values.paymentMethod === 'pix'}
                value="pix"
                onChange={(e) =>
                  form.setFieldValue('paymentMethod', e.target.value)
                }
                sx={{
                  '.MuiSvgIcon-root': {
                    color: 'rgba(182, 182, 182, 0.25)',
                  },
                  '&.Mui-checked .MuiSvgIcon-root': {
                    color: 'tertiary.main',
                  },
                }}
              />
              <Typography
                sx={{
                  color: 'grey.200',
                  fontFamily: 'primary',
                  fontSize: 14,
                }}
              >
                PIX
              </Typography>
            </Stack>
          )}
      {isFreeOrPartnerPlan
        ? null
        : isPlanSupportAme && (
            <Accordion
              expanded={expanded === 'painel3'}
              sx={{
                bgcolor: 'common.white',
                border: '1px solid',
                borderColor: 'primary.light',
                borderRadius: 1,
              }}
              onChange={setExpandedHandler('painel3')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Radio
                    checked={form.values.paymentMethod === 'ame'}
                    value="ame"
                    onChange={(e) =>
                      form.setFieldValue('paymentMethod', e.target.value)
                    }
                  />
                  <Typography
                    sx={{
                      color: 'grey.400',
                      fontFamily: 'primary',
                      fontSize: 14,
                    }}
                  >
                    AME
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>ame</AccordionDetails>
            </Accordion>
          )}
      <FormHelperText error>
        {form.touched.paymentMethod && form.errors.paymentMethod}
      </FormHelperText>
      <FormHelperText error>
        {form.touched.paymentMethod && hasFormErrors
          ? 'Preencha todos os dados corretamente antes de prosseguir'
          : ''}
      </FormHelperText>
      <Button
        data-testid="sendPaymentButton"
        color="tertiary"
        size="large"
        disabled={isApplyingCoupon}
        fullWidth
        sx={{ mt: 6 }}
        onClick={handleNextStep}
      >
        Continuar
      </Button>
    </Box>
  );
};

export default CreditCardStep;

import { Skeleton } from '@material-ui/core';
import RetentionDiscountCardBase from './RetentionDiscountCardBase';

const RetentionDiscountCardPlaceholder = () => (
  <RetentionDiscountCardBase
    header={
      <>
        <Skeleton sx={{ height: 24, maxWidth: 200, width: '60%' }} />
        <Skeleton sx={{ height: 18, mt: 2, maxWidth: 295, width: '90%' }} />
      </>
    }
    footer={<Skeleton sx={{ height: 36, maxWidth: 200, width: '50%' }} />}
  />
);

export default RetentionDiscountCardPlaceholder;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext } from 'react';
import { IAlertOptions } from './IAlertOptions';
import { IDialogOptions } from './IDialogOptions';
import { IMessageOptions } from './IMessageOptions';

export interface IDialogContext {
  openAsConfirmation: (options: IDialogOptions) => Promise<unknown>;
  openAsDialog: (options: IMessageOptions) => Promise<unknown>;
  close: (result?: unknown) => void;
  setClosable: (closable: boolean) => void;
}

const DEFAULT_CONTEXT: IDialogContext = {
  openAsConfirmation: async (options: IDialogOptions) => {},
  openAsDialog: async (options: IAlertOptions) => {},
  close: (result?: unknown) => {},
  setClosable: (closable: boolean) => {}
};

export const DialogContext = createContext(DEFAULT_CONTEXT);

export default {
  ApiBaseUrl: process.env.REACT_APP_API_ENDPOINT ?? '',
  ApiBaseUrlV2: process.env.REACT_APP_API_ENDPOINT_V2 ?? '',
  ApiTimeout: Number(process.env.REACT_APP_API_TIMEOUT) || 10000,
  AssetsEndpoint: process.env.REACT_APP_ASSETS_ENDPOINT ?? '',
  AppSlug: process.env.REACT_APP_SLUG,

  BuildTag: process.env.REACT_APP_BUILD_TAG ?? '',
  BuildHash: process.env.REACT_APP_BUILD_HASH ?? '',
  BuildDate: process.env.REACT_APP_BUILD_DATE ?? '',

  MobiAccess: {
    domain: process.env.REACT_APP_MOBIACCESS_URL ?? '',
    clientId: process.env.REACT_APP_MOBIACCESS_CLIENT_ID ?? '',
    redirectUri: process.env.REACT_APP_MOBIACCESS_REDIRECT_URI ?? '',
  },

  ReactQuery: {
    staleTime: Number(process.env.REACT_APP_REACT_QUERY_STALE_TIME) || 5000,
    refetchInterval: Number(process.env.REACT_APP_REACT_QUERY_REFETCH_INTERVAL) || 60000,
    retry: Number(process.env.REACT_APP_REACT_QUERY_RETRY) || 3,
    retryDelay: Number(process.env.REACT_APP_REACT_QUERY_RETRY_DELAY) || 1000,
  },

  GoogleGTM: {
    id: process.env.REACT_APP_GOOGLE_GTM_ID ?? 'GTM-WTS3D7J',
  },
}

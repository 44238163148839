import { Plan } from '../../../../interfaces/plan';

export enum PaymentStatus {
  AccountError = 'AccountError',
  Awaiting = 'Awaiting',
  BadRequest = 'BadRequest',
  CardDeclined = 'CardDeclined',
  CouponDeclined = 'CouponDeclined',
  GenericError = 'GenericError',
  InsuficientBalance = 'InsuficientBalance',
  Success = 'Success',
  SigninError = 'SigninError',
  UserCardError = 'UserCardError',
  CPFOrEmailExists = 'CPFOrEmailExists',
  FreeOrPartnerAwaiting = 'FreeOrPartnerAwaiting',
  PurchaseInProgress = 'PurchaseInProgress',
}

export enum CardType {
  'american-express' = 'american-express',
  'diners-club' = 'diners-club',
  discover = 'discover',
  jcb = 'jcb',
  mastercard = 'mastercard',
  unionpay = 'unionpay',
  visa = 'visa',
}

export interface Pitches {
  ID_LANCE: number;
  ID_JOGO: number;
  TIMESTAMP: number;
  EXCLUIDO: boolean;
  VAR: string;
  TIPO: string;
  SUB_TIPO: string;
  PERIODO: string;
  MINUTO: number;
  SEGUNDO: number;
  ID_CLUBE: number;
  TITULO: string;
  COMENTARIO: string;
}

export interface ScoreBoardRequistionType {
  JOGOS: [
    {
      ID_CAMPEONATO: number;
      FASE: string;
      RODADA: number;
      NRO_JOGO: number;
      ID_JOGO: number;
      DATA_HORA: string;
      DATA_HORA_EPOCH: number;
      LOCAL: string;
      CIDADE_UF: string;
      STATUS_JOGO: string;
      ARBITRO: string;
      ASSISTENTES: string;
      ID_MANDANTE: number;
      MANDANTE: string;
      SIGLA_MANDANTE: string;
      LINK_BRASAO_MANDANTE: string;
      QTD_GOLS_MANDANTE: string;
      ID_VISITANTE: number;
      VISITANTE: string;
      SIGLA_VISITANTE: string;
      LINK_BRASAO_VISITANTE: string;
      QTD_GOLS_VISITANTE: string;
      OBSERVACAO: string;
      TEM_PENALTI: boolean;
      LANCES: Pitches[];
    },
  ];
}

export interface ScoreBoardType {
  ID_CAMPEONATO: number;
  FASE: string;
  RODADA: number;
  NRO_JOGO: number;
  ID_JOGO: number;
  DATA_HORA: string;
  DATA_HORA_EPOCH: number;
  LOCAL: string;
  CIDADE_UF: string;
  STATUS_JOGO: string;
  ARBITRO: string;
  ASSISTENTES: string;
  ID_MANDANTE: number;
  MANDANTE: string;
  SIGLA_MANDANTE: string;
  LINK_BRASAO_MANDANTE: string;
  QTD_GOLS_MANDANTE: string;
  ID_VISITANTE: number;
  VISITANTE: string;
  SIGLA_VISITANTE: string;
  LINK_BRASAO_VISITANTE: string;
  QTD_GOLS_VISITANTE: string;
  OBSERVACAO: string;
  TEM_PENALTI: boolean;
  LANCES: Pitches[];
}

export interface SignupInput {
  idp?: string;
  name: string;
  email: string;
  confirmEmail: string;
  cardBrand?: string;
  phone: string;
  answer?: string;
  teamId: string;
  password?: string;
  confirmPassword: string;
  plan: Plan | null;
  cardHolderName?: string;
  creditCard?: string;
  expirationDate?: string;
  cvv?: string;
  cpf?: string;
  debitCardHolderName?: string;
  debitCard?: string;
  debitExpirationDate?: string;
  debitCVV?: string;
  debitCPF?: string;
  hasCoupon?: boolean;
  coupon?: string | undefined;
  agreeWithData: boolean;
  agreeWithTerms: boolean;
  usercpf: string;
  installments?: number;
  paymentMethod?: 'credit' | 'debit' | 'pix' | 'ame' | 'free';
}
export interface SignupRequest
  extends Omit<
    SignupInput,
    | 'confirmEmail'
    | 'confirmPassword'
    | 'plan'
    | 'agreeWithData'
    | 'agreeWithTerms'
    | 'hasCoupon'
  > {
  idp?: string;
  planId: string;
}

export interface Tag {
  id: string;
  name: string;
}

export interface Question {
  id: string;
  title: string;
  description: string;
  required: boolean;
  tags: Tag[];
}

export interface SigninRequest
  extends Pick<SignupInput, 'email' | 'password'> {}

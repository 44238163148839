import { Box, Stack, useMediaQuery } from '@material-ui/core';
import { SwiperSlide } from 'swiper/react';
import { useTheme } from '@material-ui/system';
import { useRef, useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import getUserPlans from 'modules/account/pages/Subscriptions/services/getUserPlans';
import getScoreBoard from 'modules/auth/pages/Register/services/getScoreBoard';
import { ScoreBoardType } from 'modules/auth/pages/Register/typings';
import { getSectionsV2 } from 'services/Section/SectionService';
import Slide from 'components/Slide';
import { useMatch } from 'react-router-dom';
import SectionContentEmptyState from 'components/SectionContent/components/SectionContentEmptyState';
import Footer from '../../../components/Footer/Footer';
import Hero from './components/Hero';
import ScoreBoard from './components/ScoreBoard';
import ScoreVisible from './components/ScoreVisible';
import SectionList from './components/SectionList';
import { HomePlaceholder } from './components/HomePlaceholder';
import { getCotentsByTeam } from './services';
import Context from '../../../Context';

const Home = () => {
  const { profile } = useContext(Context);
  const userTreeHash = profile!.treeHash;
  const match = useMatch('/section-content/:item');
  const locationParams = match?.params.item;
  const pathIsTeam = locationParams === 'team';
  const teamId = profile?.favoriteTeamId;
  const { isLoading, data } = useQuery([pathIsTeam], () =>
    pathIsTeam
      ? getCotentsByTeam(userTreeHash, teamId)
      : getSectionsV2(userTreeHash),
  );
  const { isLoading: isLoadingUser, data: userPlans } = useQuery(
    'userPlans',
    getUserPlans,
  );
  const hasNoFeaturedContents = data?.data.featuredContent.length === 0;
  const [scoreIsVisibile, setScoreIsVisible] = useState(false);
  const [scoreBoards, setScoreBoards] = useState<ScoreBoardType[]>();
  useQuery('scoreBoard', getScoreBoard, {
    onSuccess: ({ data: scoreBoardsQuery }) =>
      setScoreBoards(
        scoreBoardsQuery.JOGOS
          ? scoreBoardsQuery.JOGOS.sort((scoreBoard): number => {
              const gameIsLive = scoreBoard.STATUS_JOGO === 'ao-vivo';
              const gameIsEnded = scoreBoard.STATUS_JOGO === 'finalizado';
              if (gameIsLive) {
                return -1;
              }
              if (gameIsEnded) {
                return 1;
              }
              return 0;
            })
          : undefined,
      ),
    refetchInterval: 10000,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
  useEffect(() => {
    if (!data) return;
    itemsRef.current = itemsRef.current.slice(
      0,
      data?.data.section.length || 0,
    );
  }, [data, userPlans]);
  if (isLoading || isLoadingUser) {
    return <HomePlaceholder />;
  }
  if (!data?.data || !userPlans?.data) return <></>;
  const updateItemsRef = (element: HTMLDivElement | null, index: number) => {
    itemsRef.current[index] = element;
  };
  const hasNoFavoriteTeamContents =
    (pathIsTeam && data.data.section.lenth === 0) ||
    (pathIsTeam && !data.data.section);
  if (hasNoFavoriteTeamContents)
    return <SectionContentEmptyState sectionIsLive={false} />;
  return (
    <>
      <Stack
        sx={{
          width: '100%',
          maxWidth: 1920,
          height: 'auto',
          mx: 'auto',
        }}
      >
        {!hasNoFeaturedContents && <Hero data={data.data} />}
        <Stack
          sx={{
            width: '100%',
            mt: {
              xs: hasNoFeaturedContents ? '72px' : '-100px',
              lg: hasNoFeaturedContents ? '72px' : '-200px',
            },
            px: { xs: 2, lg: 0 },
            zIndex: 1000,
          }}
        >
          {scoreBoards && (
            <Box sx={{ mb: 7, pl: { xs: 0, lg: 11 } }}>
              <ScoreVisible
                setScoreVisible={setScoreIsVisible}
                scoreIsVisibile={scoreIsVisibile}
              />
              <Slide
                sliderProps={{
                  slidesPerGroup: isMobile ? 1 : 4,
                  slidesPerView: 'auto',
                  spaceBetween: 32,
                  className: 'section-slider__wrapper',
                }}
              >
                {scoreBoards?.map((scoreBoard: ScoreBoardType) => (
                  <SwiperSlide key={scoreBoard.ID_JOGO}>
                    <ScoreBoard
                      scoreIsVisible={scoreIsVisibile}
                      scoreBoard={scoreBoard}
                    />
                  </SwiperSlide>
                ))}
              </Slide>
            </Box>
          )}
          <SectionList data={data?.data} setRef={updateItemsRef} />
        </Stack>
      </Stack>
      <Footer />
    </>
  );
};

export default Home;

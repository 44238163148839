import {
  Card,
  CardContent,
  List,
  ListItemIcon,
  ListSubheader,
} from '@material-ui/core';
import { ArrowBackIos as ArrowBackIcon } from '@material-ui/icons';
import { SxProps, Theme } from '@material-ui/system';
import React from 'react';
import { NotificationType } from '../../interfaces/notification';
import NotificationListItem from './NotificationListItem';

// TODO: fetch from graphql
const newestNotifications = [
  {
    id: '1',
    type: NotificationType.contentStartingSoon,
    createdAt: new Date(),
  },
  {
    id: '2',
    type: NotificationType.planRenewal,
    createdAt: new Date(),
  },
  {
    id: '3',
    type: NotificationType.recordingReady,
    createdAt: new Date(),
  },
];
// TODO: fetch from graphql
const oldestNotifications = [
  {
    id: '4',
    type: NotificationType.contentStartingSoon,
    createdAt: new Date(),
  },
  {
    id: '5',
    type: NotificationType.planRenewal,
    createdAt: new Date(),
  },
  {
    id: '6',
    type: NotificationType.recordingReady,
    createdAt: new Date(),
  },
];

const Notifications = () => {
  const getSubheaderStyles = (styles?: SxProps<Theme>) => ({
    bgcolor: { xs: 'common.black', lg: 'grey.600' },
    borderColor: { xs: 'grey.600', lg: 'grey.500' },
    borderStyle: 'solid',
    borderWidth: 1,
    ...styles,
  });
  return (
    <Card
      sx={{
        bgcolor: { xs: 'common.black', lg: 'grey.600' },
        color: 'common.white',
        maxHeight: { lg: 500 },
        maxWidth: { lg: 445 },
        width: '100%',
      }}
    >
      <CardContent sx={{ p: 0 }}>
        <List
          subheader={
            <ListSubheader
              component="div"
              sx={getSubheaderStyles({
                color: 'common.white',
                display: { xs: 'none', lg: 'block' },
                fontSize: { xs: 14, lg: 16 },
                height: { xs: 64, lg: 56 },
                lineHeight: { xs: '64px', lg: '64px' },
                textAlign: { xs: 'center', lg: 'left' },
              })}
            >
              <ListItemIcon
                sx={{
                  display: { lg: 'none' },
                  left: 24,
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <ArrowBackIcon />
              </ListItemIcon>
              Notificações
            </ListSubheader>
          }
        >
          {newestNotifications.map((notification) => (
            <NotificationListItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </List>
        <List
          subheader={
            <ListSubheader
              component="div"
              sx={getSubheaderStyles({
                color: 'grey.400',
                fontSize: { xs: 10, lg: 14 },
                height: 50,
                lineHeight: '50px',
                textTransform: 'uppercase',
              })}
            >
              Notificações anteriores
            </ListSubheader>
          }
        >
          {oldestNotifications.map((notification) => (
            <NotificationListItem
              key={notification.id}
              notification={notification}
              secondaryTextColor="grey.300"
              IconProps={{ sx: { color: 'grey.300' } }}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Notifications;

import { Button, Divider, Stack, Typography } from '@material-ui/core';
import { useDialog } from 'components/Dialog';
import { Plan } from 'interfaces/plan';
import { useMemo } from 'react';

interface ShowPurchaseDetailsProps {
  filteredSupendedPlans: Plan[];
}

const ShowPurchaseDetails = ({
  filteredSupendedPlans,
}: ShowPurchaseDetailsProps) => {
  const dialog = useDialog();
  const confirmChangePaymentMethod = () => dialog.close(true);
  const totalChargeAmount = useMemo(
    () =>
      filteredSupendedPlans.reduce(
        (acc: number, plan: Plan) => acc + plan.netValue,
        0,
      ),
    [filteredSupendedPlans],
  );
  return (
    <Stack>
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'primary',
          left: '50%',
          position: 'absolute',
          textAlign: 'center',
          transform: 'translateX(-50%)',
          top: 25,
          width: '100%',
        }}
      >
        Informações da cobrança
      </Typography>
      <Divider sx={{ bgcolor: 'grey.200', mb: 4 }} />
      {filteredSupendedPlans.map((plan: any) => (
        <Stack
          key={plan.id}
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography
              sx={{ color: 'grey.400', fontFamily: 'secondary', fontSize: 12 }}
            >
              Plano:
            </Typography>
            <Typography
              sx={{
                color: 'grey.400',
                fontFamily: 'secondary',
                fontSize: 12,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 130,
                whiteSpace: 'nowrap',
              }}
            >
              {plan.title}
            </Typography>
          </Stack>
          <Typography
            sx={{ color: 'grey.400', fontFamily: 'secondary', fontSize: 12 }}
          >
            -
            {plan.netValue.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Stack>
      ))}
      <Divider sx={{ bgcolor: 'grey.200', mb: 4 }} />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography
          sx={{ color: 'grey.400', fontFamily: 'primary', fontSize: 14 }}
        >
          Total agora:
        </Typography>
        <Typography
          sx={{
            color: 'secondary.main',
            fontFamily: 'primary',
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          {totalChargeAmount.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Stack>
      <Divider sx={{ bgcolor: 'grey.200', my: 4 }} />
      <Button
        sx={{ bgcolor: 'secondary.main' }}
        onClick={confirmChangePaymentMethod}
      >
        Continuar
      </Button>
    </Stack>
  );
};

export default ShowPurchaseDetails;

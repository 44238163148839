import { ContentType } from '../../../interfaces/Content';

const getColorByContentType = (type?: ContentType): string =>
  type
    ? {
      [ContentType.external]: 'info.main',
      [ContentType.live]: 'error.main',
      [ContentType.video]: 'secondary.main',
      [ContentType.vod]: 'secondary.main',
      'scheduled': 'secondary.main',
    }[type]
    : 'secondary.main';

export default getColorByContentType;

import {
  Person as UserDataIcon,
  Info as InfoIcon,
  Lock as SecurityIcon,
  StarRounded as StarIcon,
} from '@material-ui/icons';
import BillingHistory from '../pages/BillingHistory';
import Profile from '../pages/Profile';
import Security from '../pages/Security';
import Subscriptions from '../pages/Subscriptions';

const iconProps = { fontSize: 20 };

export const sections = [
  {
    icon: <UserDataIcon data-testid="profileLink" sx={iconProps} />,
    name: 'myData',
    label: 'Meus dados',
    pathname: '/account/profile',
    component: <Profile />,
  },
  {
    icon: <SecurityIcon data-testid="securityLink" sx={iconProps} />,
    name: 'security',
    label: 'Segurança',
    pathname: '/account/security',
    component: <Security />,
  },
  {
    icon: <StarIcon data-testid="subscriptionsLink" sx={iconProps} />,
    name: 'subscriptions',
    label: 'Minhas assinaturas',
    pathname: '/account/subscriptions',
    component: <Subscriptions />,
  },
  {
    icon: <InfoIcon data-testid="billingHistoryLink" sx={iconProps} />,
    name: 'billingHistory',
    label: 'Histórico Financeiro',
    pathname: '/account/billing-history',
    component: <BillingHistory />,
  },
];

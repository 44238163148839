import { Stack, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

interface DynamicVideosProps {
  thumbnail: string;
  videoId: string;
}

const DynamicVideo = ({ thumbnail, videoId }: DynamicVideosProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));
  const getVideoUrl = `https://beyond.spalla.io/player/?video=${videoId}&autoplay=0`;
  return (
    <Stack sx={{ objectFit: 'contain', position: 'relative' }}>
      <Stack sx={{ backgroundImage: `url('${thumbnail}')`, width: '100%' }}>
        <iframe
          id="spalla-player"
          src={getVideoUrl}
          title="Spalla video player"
          height={isMobile ? 200 : 697}
          frameBorder="0"
          scrolling="no"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope"
          allowFullScreen
        />
      </Stack>
    </Stack>
  );
};

export default DynamicVideo;

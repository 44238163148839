import { PlanStatus } from "interfaces/plan";

const PlanStatusName = {
  [PlanStatus.active]: 'Ativo',
  [PlanStatus.cancelled]: 'Cancelado',
  [PlanStatus.suspended]: 'Suspenso',
  [PlanStatus.preCancelled]: 'Pré-cancelado',
};

interface GetStatusFriendlyNameReturn {
  statusName: string;
  color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

export const getStatusFriendlyName = (status: PlanStatus): GetStatusFriendlyNameReturn => {
  const planStatus = PlanStatusName[status];
  switch (status) {
    case PlanStatus.active:
      return {
        statusName: planStatus,
        color: 'success',
      };
    case PlanStatus.cancelled:
      return {
        statusName: planStatus,
        color: 'error',
      };
    case PlanStatus.suspended:
      return {
        statusName: planStatus,
        color: 'error',
      };
    default:
      return {
        statusName: planStatus,
        color: 'warning',
      };
  }
};

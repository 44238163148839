import React from 'react';
import { Stack, Typography } from '@material-ui/core';
import { Plan, PlanType } from '../../../../../interfaces/plan';

const PlanPriceComponent = ({ plan }: { plan: Plan }) => {
  const isFreePlan = [PlanType.free]?.includes(plan!?.type);
  const isPartnerPlan = [PlanType.partner]?.includes(plan?.type);
  if (isPartnerPlan) {
    return null;
  }
  const getPriceWithDiscounts = () => {
    let finalValue = 0;
    if (plan.collectiveDiscount.valueType === 'percentage') {
      const discountValue = plan.price * (plan.collectiveDiscount.price / 100);
      finalValue = plan.price - discountValue;
    } else {
      finalValue = plan.price - plan.collectiveDiscount.price;
    }
    const finalValueIsLessThanZero = finalValue < 0;
    if (finalValueIsLessThanZero) {
      return 0;
    }
    return finalValue;
  };
  return isFreePlan ? (
    <Typography
      sx={{
        color: 'tertiary.main',
        fontFamily: 'tertiary',
        fontSize: { xs: 16, lg: 20 },
      }}
    >
      GRÁTIS
    </Typography>
  ) : (
    <Stack>
      {plan.collectiveDiscount && (
        <Typography
          sx={{
            color: 'grey.300',
            fontFamily: 'tertiary',
            fontSize: 13,
            textDecoration: 'line-through',
          }}
        >
          R${plan.price.toFixed(2)}
          <Typography
            component="span"
            sx={{ fontFamily: 'tertiary', fontSize: 12 }}
          >
            {plan.type === 'monthly' ? '/mês' : '/ano'}
          </Typography>
        </Typography>
      )}
      <Typography
        sx={{
          color: 'tertiary.main',
          fontFamily: 'tertiary',
          fontSize: { xs: 20, lg: 24 },
          whiteSpace: 'nowrap',
        }}
      >
        {plan.collectiveDiscount
          ? `R$ ${getPriceWithDiscounts().toFixed(2)}`
          : `R$ ${plan.price.toFixed(2)}`}
        <Typography
          component="span"
          sx={{
            fontFamily: 'tertiary',
            fontSize: { xs: 14, lg: 16 },
            textTransform: 'uppercase',
          }}
        >
          {plan.type === 'monthly' ? '/mês' : '/ano'}
        </Typography>
      </Typography>
      <Typography
        sx={{ color: 'grey.300', fontFamily: 'tertiary', fontSize: 12 }}
      >
        {plan.type === 'season' && plan.installments > 1
          ? `até ${plan.installments}x sem juros`
          : ''}
      </Typography>
    </Stack>
  );
};

export default PlanPriceComponent;

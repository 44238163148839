export const normalizeString = (str: string): string =>
  str
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();

export const compareTerms = (term: string, termToCompare: string): boolean => {
  const normalizedTerm = normalizeString(termToCompare.toLowerCase());
  const normalizedTermToCompare = normalizeString(term.toLowerCase());
  const didMatch = normalizedTermToCompare.includes(normalizedTerm);
  return didMatch;
};

import { createContext } from 'react';
import { Section } from '../../interfaces/section';

type SectionOrNull = Section | null;

type ContextType = {
  sections: Section[];
  filteredSections: Section[];
  selectedSection: SectionOrNull;
  setSelectedSection: (selectedSection: SectionOrNull) => void;
  term: string;
  setTerm: (term: string) => void;
};

const Context = createContext<ContextType>({} as ContextType);

export default Context;

import americanexpress from '../assets/american-express.svg';
import discover from '../assets/discover.svg';
import dinersclub from '../assets/diners-club.svg';
import jcb from '../assets/jcb.svg';
import mastercard from '../assets/mastercard.svg';
import unionpay from '../assets/unionpay.svg';
import visa from '../assets/visa.svg';
import { CardType } from '../typings';

const cardsImgMapper: Record<CardType, any> = {
  'american-express': americanexpress,
  'diners-club': dinersclub,
  discover,
  jcb,
  mastercard,
  unionpay,
  visa,
};

export default cardsImgMapper;

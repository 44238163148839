/* eslint-disable react/require-default-props */
import { Typography } from '@material-ui/core';
import React from 'react';
import useLoggedIn from 'utils/hooks/use-logged-in';
import PlaceholderNavigationButton from '../components/PlaceholderNavigationButton';

const SubscriptionSuccess = () => {
  const { set } = useLoggedIn();
  set('1');

  return (
    <>
      <Typography sx={{ fontSize: { xs: 24, lg: 32 }, fontWeight: 600 }}>
      Parabéns! Agora você já está pronto para curtir o Paulistão!
      </Typography>
      <Typography sx={{ fontSize: { xs: 14, lg: 20 }, mt: { xs: 3, lg: 6 } }}>
        Para saber mais detalhes sobre o seu plano, acesse a sua conta com o
        e-mail cadastrado.
      </Typography>
      <PlaceholderNavigationButton
        destination="/home"
        label="Acessar o Paulistão Play"
      />
    </>
  );
};

export default SubscriptionSuccess;

export { default as heroDesktop } from '../../../assets/png/publicHomeHero.png';
export { default as heroMobile } from '../../../assets/png/publicHomeHeroMobile.png';
export { default as logo } from '../../../assets/png/logo.png';
export { default as blueLogo } from '../../../assets/png/blueLogo.png';
export { default as logoVertical } from '../../../assets/png/logoVertical.png';
export { default as signupHero } from '../../../assets/png/publicSignupHero.png';
export { default as paulistao } from '../../../assets/png/paulistao.png';
export { default as paulistao2024 } from '../../../assets/png/paulistao2024.png';
export { default as paulistaoA2 } from '../../../assets/png/paulistaoA2.png';
export { default as paulistaoA3 } from '../../../assets/png/paulistaoA3.png';
export { default as copaPaulista } from '../../../assets/png/copaPaulista.png';
export { default as paulistaoFeminino } from '../../../assets/png/feminino.png';
export { default as copaPaulistaFem } from '../../../assets/png/copaPaulistaFem.png';
export { default as copaSub20 } from '../../../assets/png/copaSub20.png';
export { default as copaSub23 } from '../../../assets/png/copaSub23.png';
export { default as copinha } from '../../../assets/png/copinha.png';
export { default as copinha2024 } from '../../../assets/png/copinha2024.png';
export { default as mobile } from '../../../assets/png/mobile.png';
export { default as tablet } from '../../../assets/png/tablet-2.png';
export { default as smartv } from '../../../assets/png/smartv.png';
export { default as desktop } from '../../../assets/png/desktop-2.png';
export { default as smartphone } from '../../../assets/png/celular-2.png';
export { default as devices } from '../../../assets/png/devices.png';
export { default as trofeu } from '../../../assets/png/trofeu.png';
export { default as bola } from '../../../assets/png/bola.png';
export { default as enjoyItNowImage } from '../../../assets/png/EnjoyItNowImage.png';
export { default as newBanner } from '../../../assets/jpg/newBanner.jpg';
export { default as background } from '../../../assets/jpg/paulistaobastidores.jpg';

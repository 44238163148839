import { Plan, PlanType } from 'interfaces/plan';

export const getFeedbackDependingOnPlan = (
  userPlans: Plan[],
  planBeingCanceled: Plan,
) => {
  const userHasMoreThanOnePlan = userPlans.length > 1;
  const haveOnlyOnePlan = userPlans.length === 1;
  const planIsMonthlyOrSeason =
    planBeingCanceled.type === PlanType.monthly ||
    planBeingCanceled.type === PlanType.season;
  const aMonthlyOrSeasonPlan = haveOnlyOnePlan && planIsMonthlyOrSeason;
  const onlyAFreePlan =
    haveOnlyOnePlan && planBeingCanceled.type === PlanType.free;
  const onlyAPartnerPlan =
    haveOnlyOnePlan && planBeingCanceled.type === PlanType.partner;
  const planIsFree =
    userHasMoreThanOnePlan && planBeingCanceled.type === PlanType.free;
  const planIsPartner =
    userHasMoreThanOnePlan && planBeingCanceled.type === PlanType.partner;
  const moreThanOnePlanAndMonthlyOrSeason =
    userHasMoreThanOnePlan && planIsMonthlyOrSeason;
  if (aMonthlyOrSeasonPlan) {
    return 'Tem certeza que deseja cancelar? Ao cancelar seu plano, você perderá o acesso aos conteúdos pagos após a data do próximo vencimento. Logo em seguida você perderá acesso a plataforma.';
  }
  if (moreThanOnePlanAndMonthlyOrSeason) {
    return 'Tem certeza que deseja cancelar? Ao cancelar seu plano, você perderá o acesso aos conteúdos pagos após a data do próximo vencimento.';
  }
  if (planIsFree) {
    return 'Tem certeza que deseja cancelar?';
  }
  if (onlyAFreePlan) {
    return 'Tem certeza que deseja cancelar? Após o cancelamento você perderá acesso a plataforma.';
  }
  if (onlyAPartnerPlan) {
    return 'Tem certeza que deseja cancelar? Ao cancelar seu plano, você perderá o acesso aos conteúdos pagos após a data do próximo vencimento junto a operadora. Logo em seguida você perderá acesso a plataforma.';
  }
  if (planIsPartner) {
    return 'Tem certeza que deseja cancelar? Ao cancelar seu plano, você perderá o acesso aos conteúdos pagos após a data do próximo vencimento junto a operadora.';
  }
  return 'Com o cancelamento você irá perder acesso a plataforma. Tem certeza que deseja cancelar?';
};

import { Page } from 'modules/pages/typings';
import { Content } from './Content';
import { Partner } from './partner';
import { Section } from './section';

export enum PlanStatus {
  active = 'active',
  cancelled = 'cancelled',
  suspended = 'suspended',
  preCancelled = 'pre-cancelled',
}

export enum PlanType {
  monthly = 'monthly',
  partner = 'partner',
  season = 'season',
  free = 'free',
}

export enum DiscountType {
  value = 'value',
  percentage = 'percentage',
}

export enum PaymentRenewal {
  purchaseDate = 'purchaseDate',
  specificDay = 'specificDay',
}

export enum Month {
  january = 'january',
  february = 'february',
  march = 'march',
  april = 'april',
  may = 'may',
  june = 'june',
  july = 'july',
  august = 'august',
  september = 'september',
  october = 'october',
  november = 'november',
  december = 'december',
}

const MonthName = {
  [Month.january]: 'Janeiro',
  [Month.february]: 'Fevereiro',
  [Month.march]: 'Março',
  [Month.april]: 'Abril',
  [Month.may]: 'Maio',
  [Month.june]: 'Junho',
  [Month.july]: 'Julho',
  [Month.august]: 'Agosto',
  [Month.september]: 'Setembro',
  [Month.october]: 'Outubro',
  [Month.november]: 'Novembro',
  [Month.december]: 'Dezembro',
};

export const getFriendlyNameTheMonth = (month: Month): string =>
  MonthName[month];

const PlanTypeName = {
  [PlanType.monthly]: 'Mensal',
  [PlanType.partner]: 'Parceiros',
  [PlanType.season]: 'Temporada',
  [PlanType.free]: 'Gratuito',
};

export const getFriendlyNameByPlanType = (type: PlanType): string =>
  PlanTypeName[type];
export interface Plan {
  id: string;
  title: string;
  description: string;
  published: boolean;
  price: number;
  period?: string;
  type: PlanType;
  startMonth: Month;
  finishMonth: Month;
  monthlyChargeEnabled: boolean;
  monthlyRecurrence?: number;
  monthlyRecurrenceEnabled: boolean;
  membershipsCount: number;
  unlimitedMemberships: boolean;
  paymentType: string;
  paymentRenewal: PaymentRenewal;
  paymentRenewalDay?: number;
  partners: Partner[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  status?: PlanStatus;
  sections: Section[];
  pages: Page[];
  highlightedContents: Content[];
  netValue: number;
  installments: number;
  collectiveDiscount: {
    id: string;
    price: number;
    recurrence: number;
    title: string;
    type: string;
    valueType: string;
    recurrenceUnlimited: boolean;
  };
  recurrenceDay: number;
  nextSeasonDate: string;
  userPlanId: string;
}

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { Typography, Box } from '@material-ui/core';
import { useState, FunctionComponent, ReactElement } from 'react';
import {
  Section,
  SortableSubSectionContent,
  SubSection,
} from '../interfaces/section';

type ComponentProps = Record<string, unknown>;

interface FilterableTagsProps {
  title: Section['title'];
  items: Section['sortableSectionContents'];
  tags: SubSection['title'][];
  renderItem: (item: SortableSubSectionContent) => ReactElement;
  renderTag: (
    tag: SubSection['title'],
    tagProps: ComponentProps,
  ) => ReactElement;
  ContentWrapper?: FunctionComponent;
  ContentWrapperProps?: ComponentProps;
  SelectedTagProps?: ComponentProps;
  TagsWrapper?: FunctionComponent;
  TagsWrapperProps?: ComponentProps;
  TitleSx?: ComponentProps;
  onSelectTag: (tag: string, index: number) => void;
}

const FilterableTags = ({
  title,
  items,
  tags,
  renderItem,
  renderTag,
  ContentWrapper,
  ContentWrapperProps,
  SelectedTagProps,
  TagsWrapper,
  TagsWrapperProps,
  TitleSx,
  onSelectTag,
}: FilterableTagsProps) => {
  const [selectedTagIndex, setSelectedTagIndex] = useState(-1);
  const selectTag = (tag: string, index: number) => () => {
    setSelectedTagIndex(index);
    onSelectTag(tag, index);
  };
  const isTagSelected = (index: number) => index === selectedTagIndex;
  const getTagProps = (index: number) =>
    isTagSelected(index) ? SelectedTagProps : {};
  const renderTags = () =>
    tags.map((tag, index) => {
      const tagProps: ComponentProps = {
        onClick: selectTag(tag, index),
        ...getTagProps(index),
      };
      return renderTag(tag, tagProps);
    });
  const renderContents = () => items.map((item) => renderItem(item));
  return (
    <>
      <Typography
        component="h2"
        data-testid="section-list-title"
        sx={{
          color: 'common.white',
          fontSize: {
            xs: 24,
            lg: 20,
          },
          ml: {
            xs: 4,
            lg: 11,
          },
          fontWeight: 600,
          mb: 6,
          ...TitleSx,
        }}
      >
        {title}
      </Typography>
      {TagsWrapper ? (
        <Box
          sx={{
            ml: {
              xs: 4,
              lg: 11,
            },
          }}
        >
          <TagsWrapper {...TagsWrapperProps}>{renderTags()}</TagsWrapper>
        </Box>
      ) : (
        renderTags()
      )}
      {ContentWrapper ? (
        <ContentWrapper {...ContentWrapperProps}>
          {renderContents()}
        </ContentWrapper>
      ) : (
        renderContents()
      )}
    </>
  );
};

export default FilterableTags;

/* eslint-disable react/require-default-props */
import { Stack } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import logo from '../../../assets/png/logoW.png'

interface LoadingPageProps {
  height?: number | string;
}

const LoadingPage = ({ height = '100%' }: LoadingPageProps) => {
  const useStyles = makeStyles(() => ({
    loadingAnimation: {
      animation: '$loading 1s linear infinite alternate',
    },
    "@keyframes loading": {
      "0%": { opacity: 0 },
      "100%": { opacity: 1 }
    }
  }));
  return (
    <Stack
      className={useStyles().loadingAnimation}
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        gap: 6,
        height,
        justifyContent: 'center',
      }}
    >
      <img
        alt="Paulistão Play"
        src={logo}
      />
    </Stack>
  );
};

export default LoadingPage;
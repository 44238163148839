import { Stack, Typography } from "@material-ui/core";
import { Search as SearchIcon } from '@material-ui/icons';

interface SearchEmptyStateProps {
  searchKey: string;
}

const SearchEmptyState = ({searchKey}: SearchEmptyStateProps) =>(
  <Stack
    alignItems="center"
    justifyContent="center"
    gap={7}
    sx={{ height: '100vh' }}
  >
    <SearchIcon sx={{ color: 'secondary.main', fontSize: 40 }} />
    <Typography
      sx={{ color: 'grey.200', fontSize: 16, textAlign: 'center' }}
    >
      Não foram encontrados
      <br /> resultados para <b className="text-bold">“{searchKey}”</b>
    </Typography>
  </Stack>
);

export default SearchEmptyState;
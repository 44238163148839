import { Plan } from "interfaces/plan";

export enum PageType {
  subscriptionSuccess = 'subscriptionSuccess',
  subscriptionError = 'subscriptionError',
  subscriptionRenewalSuccess = 'subscriptionRenewalSuccess',
  subscriptionRenewalError = 'subscriptionRenewalError',
  cancellationHold = 'cancellationHold',
  retentionSuccess = 'retentionSuccess',
  retentionError = 'retentionError',
  subscriptionCancellation = 'subscriptionCancellation',
}

export interface Page {
  id: string;
  title: string;
  blocks: JSON;
  type: PageType;
  seoTitle: string
  seoDescription: string
  seoKeywords: string[];
  scripts: string[];
}

export enum SubscriptionPeriod {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export interface Subscription {
  id: string;
  description: string;
  period: SubscriptionPeriod;
  price: number;
  pages: Page[];
  resume: string;
  title: string;
  type: string;
  createdAt: Date;
  recurrenceDay: number;
}

export interface SubscriptionPageProps {
  subscription?: Plan;
}

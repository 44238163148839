import { Stack, Typography } from '@material-ui/core';
import {
  FavoriteBorder as FavoriteTeamIcon,
  Sensors as LiveIcon,
} from '@material-ui/icons';

interface SectionContentEmptyStateProps {
  sectionIsLive: boolean;
}

const SectionContentEmptyState = ({
  sectionIsLive,
}: SectionContentEmptyStateProps) => (
  <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
    {sectionIsLive ? (
      <LiveIcon sx={{ color: 'secondary.main', fontSize: 50, mb: 8 }} />
    ) : (
      <FavoriteTeamIcon sx={{ color: 'secondary.main', fontSize: 50, mb: 8 }} />
    )}
    <Typography
      sx={{
        color: 'grey.200',
        fontFamily: 'secondary',
        fontSize: 16,
        textAlign: 'center',
        width: { xs: 176, lg: 215 },
      }}
    >
      {sectionIsLive
        ? 'Não há eventos ao vivo acontecendo agora.'
        : 'Ops, parece vazio aqui. Mas não se preocupe, tem muito a vir por aqui!'}
    </Typography>
  </Stack>
);

export default SectionContentEmptyState;

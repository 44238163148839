import React from 'react';
import { Element } from '@craftjs/core';
import { Stack } from '@material-ui/core';
import HalfContainer from './HalfContainer';

interface DoubleContainerProps {
  bgColor: string;
}

const DoubleContainer = ({ bgColor = 'grey.600' }: DoubleContainerProps) => (
  <Stack
    position="relative"
    sx={{
      alignItems: 'center',
      bgcolor: bgColor,
      flexDirection: { xs: 'column', lg: 'row' },
      gap: { xs: 2, lg: 5 },
      justifyContent: 'space-between',
      minHeight: { xs: 200, lg: 400 },
      p: { xs: 5, lg: 10 },
      position: 'relative',
      mx: { xs: -5, lg: -10 },
    }}
  >
    <Element canvas is={HalfContainer} />
    <Element canvas is={HalfContainer} />
  </Stack>
);

export default DoubleContainer;

import { Box } from '@material-ui/core';
import React from 'react';
import getAssetUri from '../../../utils/getAssetUri';

interface DynamicImageProps {
  url: string;
}

const DynamicImage = ({
  url = 'http://placeimg.com/640/480/tech',
}: DynamicImageProps) => (
  <Box
    sx={{
      backgroundImage: `url('${getAssetUri(url)}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: { xs: 200, lg: 697 },
      position: 'relative',
      width: '100%',
    }}
  />
);

export default DynamicImage;

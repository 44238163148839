import { Skeleton, Stack } from '@material-ui/core';

const AvatarPlaceholder = () => (
  <Stack alignItems="center">
    <Skeleton
      variant="circular"
      width={50}
      height={50}
      sx={{ bgcolor: 'grey.400' }}
    />
    <Skeleton
      variant="text"
      width={115}
      height={24}
      sx={{ bgcolor: 'grey.400', mt: 3 }}
    />
  </Stack>
);

export default AvatarPlaceholder;

import { Switch, Typography } from '@material-ui/core';

interface ScoreVisibleProps {
  setScoreVisible: (visible: boolean) => void;
  scoreIsVisibile: boolean;
}

const ScoreVisible = ({
  setScoreVisible,
  scoreIsVisibile,
}: ScoreVisibleProps) => (
  <Typography
    sx={{
      fontFamily: 'secondary',
      fontSize: 16,
      color: '#E7E7E7',
      mb: 6,
    }}
  >
    <Switch
      name="scoreVisible"
      color="secondary"
      checked={scoreIsVisibile}
      sx={{ mr: 2 }}
      onClick={() => setScoreVisible(!scoreIsVisibile)}
    />
    Exibir placar
  </Typography>
);

export default ScoreVisible;

import { Button, Stack, Typography } from '@material-ui/core';
import { useDialog } from 'components/Dialog';
import React from 'react';

interface FeedbackModalProps {
  title: string;
  title2: string;
  icon: string;
  description: string;
  buttonText: string;
  buttonColor: string;
}

const FeedbackModal = ({
  title,
  title2,
  icon,
  description,
  buttonText,
  buttonColor,
}: FeedbackModalProps) => {
  const dialog = useDialog();
  return (
    <Stack alignItems="center" gap={4}>
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'primary',
          fontSize: 20,
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      <img src={icon} alt="Cartão vermelho" style={{ height: 82, width: 76 }} />
      <Typography
        sx={{
          color: 'grey.400',
          fontFamily: 'primary',
          fontSize: 20,
          textAlign: 'center',
        }}
      >
        {title2}
      </Typography>
      <Typography
        sx={{
          color: 'grey.500',
          fontFamily: 'secondary',
          fontSize: 12,
          textAlign: 'center',
        }}
      >
        {description}
      </Typography>
      <Button
        data-testid="feedbackModalButton"
        sx={{ bgcolor: buttonColor, height: 60, width: '100%' }}
        onClick={() => dialog.close()}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

export default FeedbackModal;

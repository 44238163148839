import { Button, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDialog } from 'components/Dialog';
import { PlanStatus } from 'interfaces/plan';
import SubscriptionsContext from '../context';
import reactivateSubscription from '../services/reactivateSubscription';
import DeleteSubscription from './DeleteSubscription';
import SubscriptionCard from './SubscriptionCard';

const SubscriptionsList = () => {
  const alert = useAlert();
  const { userPlans, setUserPlans } = useContext(SubscriptionsContext);
  const dialog = useDialog();
  const [isReactivatingSubscription, setIsReactivatingSubscription] =
    useState(false);
  const reactivateSubscriptionHandler = async (planId: string, userPlanId: string) => {
    try {
      setIsReactivatingSubscription(true);
      await reactivateSubscription(planId, userPlanId);
      const newPlans = userPlans.map((userPlan) =>
        userPlan.id === planId
          ? { ...userPlan, status: PlanStatus.active }
          : userPlan,
      );
      setUserPlans(newPlans);
      alert.success('Plano reabilitado com sucesso');
      dialog.close();
    } catch (err) {
      // TODO: do something here
    } finally {
      setIsReactivatingSubscription(false);
    }
  };
  const openModalToReEnableSubscription = async (id: string, userPlanId: string) => {
    await dialog.open({
      element: (
        <DeleteSubscription
          title="Deseja reabilitar sua assinatura?"
          description="Você terá acesso aos conteúdos até dia 02/01/2022, a partir desse período seu plano será CANCELADO.
          Se reabilitar o plano ele voltará a ficar ATIVO e a cobrança será feita normalmente. "
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ width: 420 }}
          >
            <LoadingButton
              data-testid="reactivateSubscription"
              loading={isReactivatingSubscription}
              sx={{ fontSize: 14, height: 37, width: 200 }}
              onClick={() => reactivateSubscriptionHandler(id, userPlanId)}
            >
              Reabilitar plano
            </LoadingButton>
            <Button
              data-testid="keepSubscriptionPreCancelledButton"
              sx={{
                border: '2px solid',
                borderColor: 'secondary.main',
                bgcolor: 'common.white',
                color: 'secondary.main',
                fontSize: 14,
                height: 37,
                width: 200,
                '&:hover': { bgcolor: 'common.white' },
              }}
              onClick={() => dialog.close()}
            >
              Manter cancelamento
            </Button>
          </Stack>
        </DeleteSubscription>
      ),
      sx: {
        bgcolor: 'common.white',
        height: 391,
        maxWidth: 470,
        width: '100%',
      },
    });
  };
  const subscriptionsListList = userPlans
    ? userPlans.map((plan) => (
        <SwiperSlide key={plan.id}>
          <SubscriptionCard
            activeSubscription={plan}
            openModalToReEnableSubscription={openModalToReEnableSubscription}
          />
        </SwiperSlide>
      ))
    : null;
  return (
    <Stack sx={{ mt: 5, width: '100%' }}>
      <Swiper
        navigation
        slidesPerView="auto"
        spaceBetween={16}
        style={{ width: '100%' }}
      >
        {subscriptionsListList}
      </Swiper>
    </Stack>
  );
};

export default SubscriptionsList;

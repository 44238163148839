import { Grid, Stack, Typography } from '@material-ui/core';

interface HeadItemProps {
  title: string;
}

const HeadItem = ({ title }: HeadItemProps) => (
  <Grid item xs={1}>
    <Stack alignItems="center" direction="row" justifyContent="center" sx={{height: '100%'}}>
      <Typography sx={{ color: 'white', fontSize: 14, fontWeight: 600 }}>
        {title}
      </Typography>
    </Stack>
  </Grid>
);

export default HeadItem;

import { Card, Stack, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import filterSectionLinksByPermissions from 'utils/filterSectionLinksByPermissions';
import Context from '../context';
import { getUserCards } from '../services';
import MyAccountMenu, { MyAccountMenuItem } from './MyAccountMenu';

export const getSectionNestedPathname = (section: MyAccountMenuItem) =>
  section.pathname.replace(/^\/account/, '');

const MyAccountSection = () => {
  const [card, setCard] = useState(0);
  useQuery('cards', getUserCards, { onSuccess: ({ data }) => setCard(data) });
  const navigate = useNavigate();
  const permissions = JSON.parse(process.env.REACT_APP_PERMISSIONS as string);
  const newSections = filterSectionLinksByPermissions(permissions);
  return (
    <Context.Provider value={{ card, setCard }}>
      <Card
        sx={{
          bgcolor: { xs: 'transparent', lg: 'grey.500' },
          display: 'flex',
          flexDirection: 'row',
          maxWidth: 1200,
          minHeight: { lg: 570 },
          mt: { xs: 4, lg: 6 },
          mx: { xs: 2, lg: `calc((100vw - 1200px)/2)` },
        }}
      >
        <Stack
          sx={{
            bgcolor: 'grey.600',
            display: { xs: 'none', lg: 'flex' },
            width: 320,
          }}
        >
          <Stack alignItems="center" direction="row" gap={6} sx={{ px: 4 }}>
            <ArrowBackIcon
              sx={{
                color: 'common.white',
                cursor: 'pointer',
                height: 20,
                left: 0,
                ml: { xs: 0 },
                mr: 2,
                width: 20,
              }}
              onClick={() => navigate('/home')}
            />
            <Typography
              sx={{
                color: 'grey.100',
                fontFamily: 'primary',
                fontSize: 20,
                m: 5,
              }}
            >
              Minha conta
            </Typography>
          </Stack>
          <MyAccountMenu />
        </Stack>
        <Stack
          sx={{
            bgcolor: { lg: '#171717' },
            flex: 1,
            maxWidth: { xs: '100%', lg: 880 },
            position: 'relative',
            px: { xs: 2, lg: 5 },
            py: { xs: 0, lg: 6 },
            width: '100%',
          }}
        >
          <Routes>
            {newSections.map((section) => (
              <Route
                key={section.label}
                path={getSectionNestedPathname(section)}
                element={section.component}
              />
            ))}
          </Routes>
        </Stack>
      </Card>
    </Context.Provider>
  );
};

export default MyAccountSection;
